import { Flex, Grid, GridItem, TabPanel } from '@chakra-ui/react';
import EmployeeSearchTab from 'features/Tabs/EmployeeSearch/EmployeeSearchBody';
import SearchWithEmbedButton from 'components/common/SearchWithEmbedButton';
import Pagination from 'features/Tabs/EmployeeSearch/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, setSearchKeyword } from 'stores/employeeSearch/employeeSearchSlice';
import EmployeeSearchFilters from 'features/Tabs/EmployeeSearch/Filters/EmployeeSearchFilters';

/**
 * Component representing the Employee Search panel.
 * @returns {JSX.Element} - Employee search panel component.
 */
const EmployeeSearchPanel = () => {
  const activeFilters = useSelector((state) => state.employeeSearch.filters);
  const employeeData = useSelector((state) => state.employeeSearch.employeeData);
  const dispatch = useDispatch();

  /**
   * Handles the search input change event.
   * @param {Event} e - The input change event.
   */
  const handleSearch = (e) => {
    dispatch(setSearchKeyword(e.target.value.toLowerCase()));
  };

  /**
   * Handles the form submission event.
   */
  const handleSubmit = () => {
    dispatch(setFilters({...activeFilters, PAGE_NUMBER: 1}));
  };

  /**
   * Handles the page change event.
   * @param {number} newPageNumber - The new page number.
   */
  const handlePageChange = (newPageNumber) => {
    dispatch(setFilters({...activeFilters, PAGE_NUMBER: newPageNumber}));
  };

  // Render the Employee Search panel
  return (
    <TabPanel p={0} h='100%'>
      <Grid
        templateAreas={`"filters search" "filters main"`}
        gridTemplateRows={'50px 3fr'}
        gridTemplateColumns={'1fr 4fr'}
        h='100%'
      >
        {/* Render Employee Search filters */}
        <EmployeeSearchFilters/>
        <GridItem pt={2} area={'search'}>
          {/* Render search input and pagination */}
          <Flex w='100%' justify='space-around' align='center'>
            <SearchWithEmbedButton
              onChange={handleSearch}
              onSubmit={handleSubmit}
            />
            <Pagination 
              currentPage={activeFilters.PAGE_NUMBER} 
              onPageChange={handlePageChange}
              disabledForward={employeeData.length === 50 ? false : true}
            />
          </Flex>
        </GridItem>
        <GridItem area={'main'} overflowY='auto'>
          {/* Render Employee Search tab */}
          <EmployeeSearchTab/>
        </GridItem>
      </Grid>
    </TabPanel>
  );
};

export default EmployeeSearchPanel;
