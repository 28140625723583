import React, { useState } from 'react';
import { Flex, Stack, Text, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, IconButton, useToast } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setModalIsOpen } from 'stores/admin/adminSlice';
import ModalWrapper from 'components/common/ModalWrapper';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';
import AllocationForm from 'features/Admin/Forms/Activity/AllocationForm';

/**
 * GroupSeatAllocation Component
 * @param {Object} props - Component props
 * @param {Array} props.allocationGroups - Array of allocation groups
 * @param {Array} props.allocations - Array of seat allocations
 * @param {Function} props.onUpdateAllocations - Function to update allocations
 * @returns {JSX.Element} GroupSeatAllocation component
 */
const GroupSeatAllocation = ({ allocationGroups, allocations, onUpdateAllocations }) => {
  const [newAllocation, setNewAllocation] = useState({ groupId: '', seats: 1 });
  const [selectedAllocation, setSelectedAllocation] = useState(null);
  const modalIsOpen = useSelector((state) => state.admin.modalIsOpen);
  const toast = useToast();
  const dispatch = useDispatch();
  const { axios } = useAxiosInterceptor();

  /**
   * Handle adding a new allocation
   * If the allocation group already exists, increase the seat count,
   * otherwise add a new allocation group.
   */
  const handleAddAllocation = () => {
    const existingAllocationIndex = allocations.findIndex(allocation => allocation.ALLOCATION_GROUP_ID === newAllocation.groupId);
    if (existingAllocationIndex !== -1) {
      const updatedAllocations = [...allocations];
      updatedAllocations[existingAllocationIndex].SEAT_ALLOCATION += parseInt(newAllocation.seats);
      onUpdateAllocations(updatedAllocations);
    } else {
      const group = allocationGroups.find(group => group._id === newAllocation.groupId);
      onUpdateAllocations([...allocations, {
        ALLOCATION_GROUP_ID: newAllocation.groupId,
        GROUP_NM: group.GROUP_NM,
        SEAT_ALLOCATION: parseInt(newAllocation.seats),
      }]);
    }
    setNewAllocation({ groupId: '', seats: 1 });
  };

  /**
   * Handle change in allocation group ID
   * @param {Object} e - Event object
   */
  const handleGroupIdChange = (e) => {
    setNewAllocation({ ...newAllocation, groupId: e.target.value });
  };

  /**
   * Handle deletion of an allocation
   * @param {Object} allocation - Allocation to be deleted
   */
  const handleDeleteAllocation = (allocation) => {
    setSelectedAllocation(allocation);
    handleOpenModal();
  };

  /**
   * Handle confirmation of deletion
   * @param {Object} allocation - Allocation to be deleted
   */
  const handleConfirmDelete = async (allocation) => {
    try {
      handleCloseModal();
      const updatedAllocations = allocations.filter(item => item.GROUP_NM !== allocation.GROUP_NM);
      
      if(allocation._id) {
        await axios.delete(`${process.env.REACT_APP_INVOKE_URL}/allocation`, { data: {_id: allocation._id} });
      }

      onUpdateAllocations(updatedAllocations);
    } catch(error) {
      toast({
        title: `Unable to delete allocation.`,
        description: error.response?.data?.error || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }
  
  /**
   * Open modal
   */
  const handleOpenModal = () => {
    dispatch(setModalIsOpen(true));
  };
  
  /**
   * Close modal
   */
  const handleCloseModal = () => {
    dispatch(setModalIsOpen(false));
  };
  

  return (
    <Stack spacing={4} p={5} border='1px solid' borderColor='gray.200' borderRadius={8}>
      <ModalWrapper
        isConfirm
        isOpen={modalIsOpen}
        header="Delete this allocation?"
        body="Deleting this allocation will result in all status records associated with this allocation being permanently removed. This will take place immediately."
        onClose={handleCloseModal}
        onConfirm={() => handleConfirmDelete(selectedAllocation)}
      />
      <AllocationForm 
        allocationGroups={allocationGroups} 
        newAllocation={newAllocation} 
        onAddAllocation={handleAddAllocation} 
        onGroupIdChange={handleGroupIdChange} 
      />
      {allocations.map((allocation) => (
        <Flex key={allocation._id} alignItems='center' justify='space-between'>
          <Text flex={2}>{allocation.GROUP_NM}</Text>
          <NumberInput
            flex={1}
            mr={5}
            defaultValue={allocation.SEAT_ALLOCATION}
            min={1}
            onChange={(valueString) => {
              const value = parseInt(valueString);
              if (!isNaN(value)) {
                const updatedAllocations = allocations.map((item) =>
                  item.ALLOCATION_GROUP_ID === allocation.ALLOCATION_GROUP_ID
                    ? { ...item, SEAT_ALLOCATION: value }
                    : item
                );
                onUpdateAllocations(updatedAllocations);
              }
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <IconButton
            colorScheme='red'
            onClick={() => handleDeleteAllocation(allocation)}
            aria-label='Remove Allocation'
            icon={<DeleteIcon />}
          />
        </Flex>
      ))}
    </Stack>
  );
};

export default GroupSeatAllocation;