const columnOptions = [
  "PERSON_ID",
  "PRI_DISPLAY_NM",
  "PRI_FIRST_NM",
  "PRI_LAST_NM",
  "BUSINESS_EMAIL",
  "FIRST_HIRE_DT",
  "POSITION_TYPE_CD",
  "JOB_TITLE",
  "JOB_ENTRY_DT",
  "MANAGER_LEVEL_CD",
  "MANAGER_LEVEL_DESC",
  "CF_CAREER_LEVEL_CD",
  "GENDER_CD",
  "ETHNICITY",
  "SUPERVISOR_PRI_DISPLAY_NM",
  "SUPERVISOR_PRI_FIRST_NM",
  "SUPERVISOR_PRI_LAST_NM",
  "DEPARTMENT_GROUP_NM",
  "DEPARTMENT_BUSINESS_UNIT_NM",
  "DEPARTMENT_SUB_BUSINESS_UNIT",
  "DEPARTMENT_FUNCTION_NM",
  "DEPARTMENT_SUB_FUCTION",
  "DEPARTMENT_BRAND_NM",
  "LVL03_PRI_DISPLAY_NM",
  "LVL04_PRI_DISPLAY_NM",
  "LVL05_PRI_DISPLAY_NM",
  "LVL06_PRI_DISPLAY_NM",
  "LVL07_PRI_DISPLAY_NM",
  "LVL08_PRI_DISPLAY_NM",
  "HRBP1_NAME",
  "HRBP2_NAME",
  "HRBP1_EMAIL",
  "HRBP2_EMAIL",
];

const comments = [
  "Employee ID in the format 00######",
  "Employee Name",
  "Employee First Name only",
  "Employee Last Name only",
  "Employee Email in the format FirstName.LastName@coxautoinc.com",
  "Initial hire date in the format MM/DD/YY",
  "Employee Job Code",
  "Employee Job Title",
  "Entry date for the employee's current role in the format MM/DD/YY",
  "Employee Manager Level Code (e.g. 90)",
  "Label for the Manager Level Code (e.g. Individual Contributor)",
  "Employee CF code (e.g. P2)",
  "Employee Gender (M F or blank",
  "Employee Ethnicity (White POC or blank",
  "Direct supervisor full name",
  "Direct supervisor first name only",
  "Direct supervisor last name only",
  "Department group name (e.g. Inventory Solutions; Retail Solutions; etc.)",
  "",
  "",
  "",
  "",
  "",
  "Level 03 leader (CEO > CAI President > LVL_03). Should be in the format: FirstName LastName",
  "Level 04 leader. Should be in the format: FirstName LastName",
  "Level 05 leader. Should be in the format: FirstName LastName",
  "Level 06 leader. Should be in the format: FirstName LastName",
  "Level 07 leader. Should be in the format: FirstName LastName",
  "Level 08 leader. Should be in the format: FirstName LastName",
  "HRBP 1 Name. Should be in the format: FirstName LastName",
  "HRBP 2 Name. Should be in the format: FirstName LastName",
  "HRBP 1 Email. Should be in the format: firstname.lastname@coxautoinc.com",
  "HRBP 2 Email. Should be in the format: firstname.lastname@coxautoinc.com"
];

const example = [
  "00152363",
  "Briane Thomas",
  "Briane",
  "Thomas",
  "Briane.Thomas@coxautoinc.com",
  "8/18/99",
  "S1245",
  "Sr Client Solutions Executive Manheim - CAI",
  "10/1/20",
  "90",
  "Individual Contributor",
  "S3",
  "F",
  "White",
  "Zachary Drewes",
  "Zachary",
  "Drewes",
  "Inventory Solutions",
  "Manheim Corporate",
  "Manheim Sales",
  "Sales",
  "Sales - Inventory Dealer",
  "Manheim",
  "Tim McKinley",
  "Peter Grupposo",
  "Thaddeus Sykes",
  "Sarah Bannister",
  "Zach Drewes",
  "Briane Thomas",
  "Stephanie Fisher",
  "Maria Sadiku",
  "Stephanie.Fisher@coxautoinc.com",
  "Maria.Sadiku@coxautoinc.com"
]

/**
 * @description Utility function to generate a template that the user can download and utilize for the employee bulk upload process. 
 */
export const generateTemplate = () => {
  const csvHeaders = columnOptions;

  // Create a CSV string with the headers
  let csvContent = csvHeaders.join(',') + '\n' + comments.join(',') + '\n' + example.join(',') + '\n';
  
  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a URL for the Blob and create a download link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'nominate-now_employeeUploadTemplate.csv';

  a.click();

  window.URL.revokeObjectURL(url);
}