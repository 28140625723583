import Select from 'react-select';

/**
 * MultiSelect component renders a multi-select dropdown using the react-select library.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.options - Options to be displayed in the dropdown.
 * @param {Array} props.selectedValue - Currently selected values.
 * @param {Function} props.onValueChange - Callback function triggered when selection changes.
 * @returns {JSX.Element} - MultiSelect component JSX.
 */
const MultiSelect = ({ options, selectedValue, onValueChange }) => {
  // Function to handle selection change
  const handleChange = (selectedOption) => {
    onValueChange(selectedOption);
  };

  return (
    <div className='multi-select-shared'>
      {/* React-select component for rendering multi-select dropdown */}
      <Select
        isMulti
        value={selectedValue}
        options={options}
        classNamePrefix='react-select'
        onChange={handleChange}
      />
    </div>
  );
};

export default MultiSelect;
