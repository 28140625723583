import Axios from 'axios';
import { API_URL } from 'config/api/config';
import { useOktaAuth } from '@okta/okta-react';
import useOktaLogout from 'hooks/useOktaLogout';
import React from 'react';

export const axios = Axios.create({
  baseURL: API_URL,
});

export const useAxiosInterceptor = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const logoutUser = useOktaLogout();

  // Define request/response and error interceptors
  const authRequestInterceptor = (config) => {
    const token = authState?.accessToken?.accessToken;

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
  }

  const reqErrorInterceptor = async (error) => Promise.reject(error);

  const resResInterceptor = async (response) => {
    // Handle successful responses as needed
    return response;
  }

  const resErrInterceptor = async (error) => {
    if (error.response?.status === 401 && error.response.data == 'Jwt is expired') {
      logoutUser();
    }

    // For other errors, log the message and reject the promise
    const message = error.response?.data?.message || error.message;
    console.error(message);
    return Promise.reject(error);
  }

  // Set up the interceptors with useEffect
  React.useEffect(() => {
    const reqInterceptor = axios.interceptors.request.use(
      authRequestInterceptor, 
      reqErrorInterceptor
    );

    const resInterceptor = axios.interceptors.response.use(
      resResInterceptor,
      resErrInterceptor,
    );

    // Cleanup function
    return () => {

   axios.interceptors.request.eject(reqInterceptor);

   axios.interceptors.response.eject(resInterceptor);
    }
  }, [oktaAuth, logoutUser, resErrInterceptor]);

  return { axios };
}