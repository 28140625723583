import { 
  Checkbox,
  HStack
} from '@chakra-ui/react';

/**
 * TableFilters component renders checkboxes for filtering table data.
 * @param {Object} props - The component props.
 * @param {function} props.onChange - Function to handle checkbox changes.
 * @returns {JSX.Element} TableFilters component.
 */
const TableFilters = ({ onChange }) => {
  return (
    <HStack spacing={5} direction='row'>
      <Checkbox onChange={onChange} value='STATUS_NOMINATED' colorScheme='blue' defaultChecked>
        Nominations
      </Checkbox>
      <Checkbox onChange={onChange} value='STATUS_APPROVED' colorScheme='blue' defaultChecked>
        Approvals
      </Checkbox>
    </HStack>
  );
};

export default TableFilters;
