import { useDispatch } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { setActivity } from 'stores/reporting/reportingSlice';

const ActivityChart = ({ data }) => {
  const dispatch = useDispatch();
  const tickFormatter = (value) => {
    if (value.length > 10) {
      return value.substring(0, 7) + "..." + value.slice(-6);
    }
    return value;
  };

  const handleBarClick = (barData) => {
    dispatch(setActivity(barData?.payload?.ACTIVITY_ID));
  };
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} overflow={'visible'}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ACTIVITY_NAME" xAxisId={1} hide/>
        <XAxis 
          dataKey="ACTIVITY_NAME" 
          angle={-45} 
          tick={{ fontSize: 10, dy: 22, dx: -10 }} 
          tickFormatter={tickFormatter}
          margin={{ top: 10, bottom: 20 }}
        />
        <YAxis />
        <Tooltip />
        <Bar dataKey="CAPACITY" xAxisId={1} fill="rgba(220, 220, 220, 1)"/>
        <Bar onClick={handleBarClick} dataKey="ENROLLMENT" fill="rgba(75, 192, 192, 1)"/>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ActivityChart;
