import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { useAxiosInterceptor } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState, setFormComponent, setFormData, setFormFlag, setRoleData } from "stores/admin/adminSlice";
import AdminTableCheckbox from "./AdminTableCheckbox";

/**
 * AdminRoleList component renders a table of roles.
 * It utilizes Chakra UI components for styling.
 * The table includes columns for ID, Active status, Title, and Description of each role.
 * It also provides a checkbox for each row and allows opening a drawer for editing a role.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.searchTerm - Search term for filtering roles by title.
 * @param {boolean} props.hideInactive - Flag to hide inactive roles.
 * @returns {JSX.Element} - AdminRoleList component JSX.
 */
const AdminRoleList = ({ searchTerm, hideInactive }) => {
  // State variables for managing loading state, role data, and filtered data
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { axios } = useAxiosInterceptor();

  // Redux hooks for accessing submission trigger and dispatching actions
  const submissionTrigger = useSelector((state) => state.admin.submissionTrigger);
  const dispatch = useDispatch();

  // useEffect to fetch role data and set form component
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/role/roles`);
        setLoading(false);
        setData(data?.data);
        setFilteredData(data?.data);
        dispatch(setRoleData(data?.data));
        dispatch(setFormComponent("RoleForm"));
      } catch (error) {
        console.log(error);
        return <span>Error</span>;
      }
    };

    fetchData();
  }, [axios, submissionTrigger, dispatch]);

  // useEffect to filter data based on search term
  useEffect(() => {
    const filtered = data?.filter(record => {
      return (
        (record?.ACTIVE || record?.ACTIVE === hideInactive)
        &&
        (
          record?.TITLE?.toLowerCase().includes(searchTerm)
        )
      );
    });

    setFilteredData(filtered);
  }, [searchTerm, hideInactive, data]);

  // Function to handle opening drawer for editing a role
  const handleDrawerOpen = (e) => {
    dispatch(setDrawerState(true));
    dispatch(setFormFlag('update'));
    const _id = e.target.dataset?.value;

    if (_id) {
      const targetRecord = data.find((record) => {
        return record._id === _id;
      });
      dispatch(setFormData(targetRecord));
    }
  };

  // Render loading spinner if data is loading
  if (loading) {
    return <Loading />;
  }

  return (
    <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
      <Table variant='striped' size='sm'>
        <Thead bgColor='white'>
          <Tr>
            <Th></Th>
            <Th>ID</Th>
            <Th>Active</Th>
            <Th>Title</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapping through filtered data to render table rows */}
          {
            filteredData.map((row) => (
              <Tr key={row._id}>
                <Td>
                  {/* Checkbox component for selecting the row */}
                  <AdminTableCheckbox value={row} />
                </Td>
                {/* Cell for displaying ID */}
                <Td data-value={row._id} cursor='pointer' onClick={handleDrawerOpen} style={{textDecoration: 'underline'}} _hover={{color: 'blue.500'}}>{row._id}</Td>
                {/* Cell for displaying Active status */}
                <Td>{row.ACTIVE ? 'Active' : 'Inactive'}</Td>
                {/* Cell for displaying Title */}
                <Td>{row.TITLE}</Td>
                {/* Cell for displaying Description */}
                <Td>{row.DESC}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AdminRoleList;
