import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from 'routes/SecureRoute';
import Loading from 'components/common/Loading';
import ActivityPage from 'features/Activities/ActivityPage';
import AdminPage from 'features/Admin/AdminPage';
import ReportingPage from 'features/Reporting/ReportingPage';
import UploadPage from 'features/Upload/UploadPage';

/**
 * @description This component is used to determine where to redirect the user as they navigate the page. All routes require 
 * @returns {JSX.Element} AppRoutes
 */
const AppRoutes = () => {
  return (
    <Routes>
      <Route path='implicit/callback' element={<LoginCallback loadingElement={<Loading />} />} />
      <Route path='/' element={<RequiredAuth />}>
        <Route path='' element={<ActivityPage />} />
        <Route path='admin' element={<AdminPage />} />
        <Route path='reporting' element={<ReportingPage />} />
        <Route path='upload' element={<UploadPage />} />
        <Route path='*' element={<Navigate replace to='' />}/>          
      </Route>
    </Routes>
  );
};

export default AppRoutes;