import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";

const ModalWrapper = ({ isConfirm, isOpen, header, body, onClose, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal size='xl' closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {body}
        </ModalBody>
        <ModalFooter>
          {
            isConfirm &&
            <Button onClick={handleConfirm} colorScheme='blue' mr={3}>
              Continue
            </Button>
          }
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default ModalWrapper;
