import { useState } from 'react';
import { Box, Button, Flex, Heading, Stack, Text, useToast } from '@chakra-ui/react';
import Nav from 'components/common/Nav';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';
import { generateTemplate } from 'utils/downloadTemplate';

/**
 * Component for uploading employee records in bulk.
 * Allows users to upload a CSV file containing employee data and trigger the upload process.
 * Also provides an option to download a template CSV file for guidance.
 * The CSV file has to include the required fields, and cannot include fields outside those found in the generateTemplate util file.  
 */
const UploadPage = () => {
  const [loading, setLoading] = useState(false);
  const { axios } = useAxiosInterceptor();
  const toast = useToast();

  /**
   * Handles the form submission, which triggers the file upload process.
   * @param {Event} event - Form submission event
   */
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    const formData = new FormData(event.target);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/employee/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log('File uploaded successfully:', response.data);
      toast({
        title: 'File processed',
        description: 'The file has been uploaded and processed',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: 'Internal Server Error',
        description: error?.response?.data || 'An error occurred while updating this data.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    finally {
      setLoading(false);
    }
  };

  /**
   * Handles the download template button click event.
   * Downloads a CSV template file for bulk upload.
   */
  const handleTemplateDownload = () => {
    generateTemplate();
  }

  return (
    <Stack display='flex' justify='center'>
      <Nav title='NominateNow'/>
      <Box p={5} display='flex' flexDir='column'>
        <Heading size='md'>Bulk Upload Employee Records</Heading>
        <Text as='i' fontSize='sm'>Note: This bulk upload process will UPDATE an employee record if the PERSON_ID field matches an existing record. If no match is found, a new record will be created. </Text>
        <Text as='i' fontSize='sm'>Note: "PERSON_ID", "PRI_DISPLAY_NM", and "LVL03_PRI_DISPLAY_NM" are required fields.</Text>
        <Text as='i' fontSize='sm'>Note: The PERSON_ID field must match the format "00######".</Text>
        <Text as='i' fontSize='sm'>Note: If additional fields not found in the template file are added to the uploaded document, the upload will fail.</Text>
        <Text as='i' fontSize='sm'>Note: After a successful file upload, you will need to manually refresh active allocation groups in the Admin Dashboard.</Text>
      </Box>
      <Flex  
        border='1px solid' 
        borderColor='gray.400'
        borderRadius={8}
        ml='auto' mr='auto' 
        p={10} 
        flexDir='column' 
        justifyContent='center' 
        alignItems='center'
      >
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <input type="file" name="file" accept=".csv" />
          <Flex mt={10} justifyContent='space-between'>
            <Button isLoading={loading} colorScheme='blue' type='submit'>Upload</Button>
            <Button variant='outline' colorScheme='blue' onClick={handleTemplateDownload}>Download Template</Button>
          </Flex>
        </form>
      </Flex>
    </Stack>
  );
};

export default UploadPage;
