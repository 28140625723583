import { Flex, IconButton, TabPanel } from '@chakra-ui/react';
import Search from 'components/common/Search';
import RosterTable from 'features/Tabs/Roster/RosterTable';
import { useEffect, useState } from 'react';
import TableFilters from 'features/Tabs/Roster/TableFilters';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeDrawer from 'features/Employee/EmployeeDrawer';
import { setCurrentEmployee } from 'stores/employeeSearch/employeeSearchSlice';
import { setEnrollment, setNominations, setSearchTerm } from 'stores/roster/rosterSlice';
import utils from 'utils/utils';
import { DownloadIcon } from '@chakra-ui/icons';
import { generateExcelFile } from 'utils/export';


/**
 * RosterPanel component displays the roster table along with search and filters functionality.
 * @returns {JSX.Element} RosterPanel component.
 */
const RosterPanel = () => {
  const rosterData = useSelector((state) => state.roster.rosterData);
  const [filteredRosterData, setFilteredRosterData] = useState([]);
  const [filters, setFilters] = useState({STATUS_NOMINATED: true, STATUS_APPROVED: true});
  const currentEmployee = useSelector((state) => state.employeeSearch.currentEmployee);
  const searchTerm = useSelector((state) => state.roster.rosterSearchTerm);
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const currentAllocation = useSelector((state) => state.roster.currentAllocation);
  const dispatch = useDispatch();

  // Whenever rosterData changes, update the filteredRosterData
  useEffect(() => {
    setFilteredRosterData(rosterData);
  }, [rosterData]);

  // Refilter the roster data
  useEffect(() => {
    let filtered = rosterData?.filter(record => {
      return (
        (
          (filters.STATUS_APPROVED && record?.ACTIVE_STATUS?.STATUS_APPROVED) ||
          (filters.STATUS_NOMINATED && record?.ACTIVE_STATUS?.STATUS_NOMINATED)
        ) &&
        (
          // Check searchTerm criteria only if either STATUS_APPROVED or STATUS_NOMINATED is true
          (!filters.STATUS_APPROVED && !filters.STATUS_NOMINATED) || // If both are false, don't check searchTerm
          (
            record?.PRI_DISPLAY_NM?.toLowerCase().includes(searchTerm) ||
            record?.PERSON_ID?.toLowerCase().includes(searchTerm) ||
            record?.DEPARTMENT_GROUP_NM?.toLowerCase().includes(searchTerm) ||
            record?.JOB_TITLE?.toLowerCase().includes(searchTerm) ||
            record?.DEPARTMENT_FUNCTION_NM?.toLowerCase().includes(searchTerm)
          )
        ) &&
        (
          currentAllocation?.EMPLOYEE_IDS?.includes(record._id)
        )
      );
    });

    setFilteredRosterData(filtered);
  }, [searchTerm, filters, rosterData, currentAllocation]);

  // Set the ACTIVE_STATUS to the status found in the Employee data that is for the current activity. This is to make data access easier later. 
  useEffect(() => {
    const activeStatuses = filteredRosterData.map((record) => {
      return record.ACTIVE_STATUS; 
    });

    const allocationNominations = utils.countNominations(activeStatuses);
    const allocationsEnrollment = utils.countEnrollment(activeStatuses); 

    dispatch(setNominations(allocationNominations));
    dispatch(setEnrollment(allocationsEnrollment));
  }, [filteredRosterData])

  /**
   * Handles the search input change event.
   * @param {Object} e - Event object.
   */
  const handleSearch = (e) => {
    dispatch(setSearchTerm(e.target.value.toLowerCase()))
  }

  /**
   * Handles the checkbox change event.
   * @param {Object} e - Event object.
   */
  const handleCheckBoxChange = (e) => {
    const checked = e.target.checked
    const status = e.target.value;

    switch(status) {
      case 'STATUS_NOMINATED':
        setFilters({...filters, STATUS_NOMINATED: checked})
        break;
      case 'STATUS_APPROVED':
        setFilters({...filters, STATUS_APPROVED: checked})
        break;
      default:
        break;
    }
  };

  /**
   * Handles the closing of the employee drawer.
   */
  const handleCloseDrawer = () => {
    dispatch(setCurrentEmployee(null));
  };

  const handleExport = (data) => {
    const output = [];
    data.forEach((row) => {
      const statuses = utils.captureStatusAndSubStatus(row.ACTIVE_STATUS);
      output.push({ 
        ...row, 
        ACTIVITY_NAME: currentActivity.ACTIVITY_NAME, 
        STATUS: statuses.status,
        SUB_STATUS: statuses.subStatus
      });
    });

    generateExcelFile(output);
  };

  return (
    <TabPanel h="100%" p={4} pb='55px'>
      <Flex justify='space-between' p={1}>
        <IconButton 
          colorScheme='blue'
          variant='ghost' 
          aria-label='Download data' 
          icon={<DownloadIcon />} 
          onClick={() => { handleExport(filteredRosterData) }}
        />
        <Search onChange={handleSearch} defaultValue={searchTerm}/>
        <TableFilters onChange={handleCheckBoxChange}/>
      </Flex>
      <RosterTable filteredRosterData={filteredRosterData}/>
      <EmployeeDrawer
        isOpen={currentEmployee !== null} 
        onClose={handleCloseDrawer}
      />
    </TabPanel>
  );
};

export default RosterPanel;
