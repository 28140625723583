import {
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentActivity } from 'stores/activity/activitySlice';
import { axios } from 'hooks/useAxiosInterceptor';
import utils from 'utils/utils';

/**
 * SeatAllocationPanel component renders a single section of the AllocationManager. This section is used to control the free seats for that allocation.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.allocation - The allocation that will be managed in the AllocationManager
 * @returns {JSX.Element} - SeatAllocationPanel component JSX.
 */
const SeatAllocationPanel = ({ allocation }) => {
  const [loading, setLoading] = useState(false);
  const [capacity, setCapacity] = useState(allocation.SEAT_ALLOCATION);
  const [enrollment, setEnrollment] = useState(0);
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    setEnrollment(utils.countEnrollment(allocation.STATUSES));
  }, [allocation]);

  // Free up a seat from an allocation
  const handleFreeUpSeat = () => {
    try {
      setLoading(true);
      const fetchData = async () => {
        const { EMPLOYEE_IDS, OWNERS, STATUSES, ALLOCATION_GROUP_NAME, ...allocationWithoutEmployeeIds } = allocation;
        allocationWithoutEmployeeIds.SEAT_ALLOCATION = capacity - 1;

        await axios.post(`${process.env.REACT_APP_INVOKE_URL}/allocation/allocationManager`, {data: { spareSeats: currentActivity?.SPARE_SEATS + 1, allocationObj: allocationWithoutEmployeeIds }});
        setLoading(false);
        setCapacity(capacity - 1);
        dispatch(setCurrentActivity({...currentActivity, SPARE_SEATS: currentActivity.SPARE_SEATS + 1}));
      }
        
      fetchData();
    } catch(error) {
      console.log(error);
      toast({
        title: 'Unable to fetch data',
        description: error.response?.data?.error || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  }

  // Claim a free seat from the activity's available free seats
  const handleClaimFreeSeat = () => {
    try {
      setLoading(true);
      const fetchData = async () => {
        const { EMPLOYEE_IDS, OWNERS, STATUSES, ALLOCATION_GROUP_NAME, ...allocationWithoutEmployeeIds } = allocation;
        allocationWithoutEmployeeIds.SEAT_ALLOCATION = capacity + 1;

        await axios.post(`${process.env.REACT_APP_INVOKE_URL}/allocation/allocationManager`, {data: { spareSeats: currentActivity?.SPARE_SEATS - 1, allocationObj: allocationWithoutEmployeeIds }});
        setLoading(false);
        setCapacity(capacity + 1);
        dispatch(setCurrentActivity({...currentActivity, SPARE_SEATS: currentActivity?.SPARE_SEATS - 1}));
      }
        
      fetchData();
    } catch(error) {
      console.log(error);
      toast({
        title: 'Unable to fetch data',
        description: error.response?.data?.error || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  }

  return (
    <Flex flexDir='column'>
      <Heading size='lg'>{allocation?.ALLOCATION_GROUP_NAME}</Heading>
      <Flex alignItems='center' mb={10}>
        <Flex>
          <VStack p={5}>
            <Heading size='lg'>{enrollment}</Heading>
            <Heading size='xs'>ENROLLMENT</Heading>
          </VStack>
          <VStack p={5}>
            <Heading size='lg'>{capacity}</Heading>
            <Heading size='xs'>CAPACITY</Heading>
          </VStack>
        </Flex>
        <Flex ml={5} flexDir='column'>
          <Button 
            isDisabled={enrollment >= capacity}
            isLoading={loading} 
            loadingText='Moving seat'
            colorScheme='blue' 
            variant='outline' 
            rightIcon={<ArrowRightIcon/>} 
            onClick={handleFreeUpSeat}
            mb={2}
          >
            Free up seat
          </Button>
          <Button 
            isDisabled={currentActivity?.SPARE_SEATS <= 0}
            isLoading={loading} 
            loadingText='Moving seat'
            colorScheme='blue' 
            rightIcon={<ArrowLeftIcon/>} 
            onClick={handleClaimFreeSeat}
          >
            Claim spare seat
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SeatAllocationPanel;