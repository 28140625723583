const utils = {
  /**
   * Get the initials of the logged in user from their email address to display in the Profile button.
   * @returns Initials of the logged in user or a '+' if an issue occurs. 
   */
  getInitials: () => {
    try {
      let email = JSON.parse(window.localStorage.getItem(`nominate_now_email`));
      let parts = email.split('.');
      const firstLetter = parts[0][0].toUpperCase();
      const secondLetter = parts[1][0].toUpperCase();

      const initials = firstLetter + secondLetter;
    
      return initials;
    } catch(error) {
      console.error('Issue getting email address.')
      return '+';
    }
  },
  /**
   * Format a date string to be in a more readable format.
   * @param {Date} date - The unformatted date string
   * @returns A formatted date string in the format 'YYYY-MM-DD'
   */
  formatDate: (date) => {
    try {
      if(date) {
        const formattedDate = date.split('T');
        return formattedDate[0];
      } else {
        return ''
      }
      
    } catch(error) {
      console.error('Issue formatting this date')
      return '';
    }
  },
  /**
   * Convert a string with line breaks into an array. The string is split on the line breaks.
   * @param {String} str - A long string with mulitple line breaks
   * @returns 
   */
  convertStringToArray: (str) => {
    try {
      const strToArray = str.split('\n');
      const output = strToArray.filter((item) => { return item.length > 0 });

      return output;
    } catch(error) {
      console.error('Issue converting string');
      return [];
    }
  },
  /**
   * Convert a string with line breaks into an array of strings, split on the new lines.
   * @param {String} str - The input string
   * @returns An array of strings with all lowercase characters
   */
  convertStringToArrayWithLowerCaseValues: (str) => {
    try {
      const strToArray = str.split('\n');
      const filteredArray = strToArray.filter((item) => { return item.length > 0 });

      const output = filteredArray.map((item) => {
        return item.toLowerCase();
      });
      
      return output;
    } catch(error) {
      console.error('Issue converting string', error);
      return [];
    }
  },
  /**
   * Takes as input an array of strings and converts it into an array of objects that can be rendered in a Select component from react-select
   * @param {Array} data 
   * @param {String} valueKey 
   * @param {String} labelKey 
   * @returns A reformatted array that can be used in the react-select Select component
   */
  transformDataForMultiSelect: (data, valueKey, labelKey) => {
    try {
      const output = [];

      data.forEach((item) => {
        output.push({ value: item[valueKey], label: item[labelKey]})
      })
  
      return output;
    } catch(error) {
      console.error('Issue transforming data.');
      return [];
    }
  },
  /**
   * Get the status and sub status labels and colors to be used in Tags
   * @param {Object} statusRecord - the status object to be analyzed
   * @returns An object that represents the Status, SubStatus, and colors to be used in tags
   */
  captureStatusAndSubStatus: (statusRecord) => {
    try {
      const output = {};

      if(statusRecord.STATUS_NOMINATED) {
        output.status = 'Nominated';
        output.statusColor = 'yellow'
      } else if(statusRecord.STATUS_APPROVED) {
        output.status = 'Approved';
        output.statusColor = 'green'
      }
      
      if(statusRecord.SUB_STATUS_DECLINED) {
        output.subStatus = 'Declined';
        output.subStatusColor = 'purple'
      } else if(statusRecord.SUB_STATUS_WITHDRAWN) {
        output.subStatus = 'Withdrawn';
        output.subStatusColor = 'orange';
      } else if(statusRecord.SUB_STATUS_COMPLETED) {
        output.subStatus = 'Completed';
        output.subStatusColor = 'blue';
      } else if(statusRecord.SUB_STATUS_REMOVED) {
        output.subStatus = 'Removed';
        output.subStatusColor = 'red';
      } else {
        output.subStatus = null
      }

      return output;
    } catch(error) {
      console.error('Issue transforming data.');
      return {
        status: 'Unknown',
        subStatus: 'Unknown',
        statusColor: 'gray',
        subStatusColor: 'gray'
      };
    }
  },
  /**
   * Capture the sub status as a string for a given status object. 
   * @param {Object} status - The status object to be analyzed
   * @returns The string that represents the sub status of the status object
   */
  captureSubStatus: (status) => {
    if(status.SUB_STATUS_COMPLETED) {
      return 'Completed';
    }
    if(status.SUB_STATUS_WITHDRAWN) {
      return 'Withdrawn';
    }
    if(status.SUB_STATUS_DECLINED) {
      return 'Declined';
    }
    if(status.SUB_STATUS_REMOVED) {
      return 'Removed';
    }
  }, 
  /**
   * Return the number of nominations from a list of status objects
   * @param {Array} statuses - The array of statuses
   * @returns the number of statuses in the statuses array where STATUS_NOMINATED is true
   */
  countNominations: (statuses) => {
    let allocationNominations = 0;
    statuses?.forEach((status) => {
      if (status.STATUS_NOMINATED) {
        allocationNominations += 1;
      }
    });
    return allocationNominations;
  },
  /**
   * Return the number of enrolled employees from a list of status objects
   * @param {Array} statuses - The array of statuses
   * @returns the number of enrolled employees
   */
  countEnrollment: (statuses) => {
    let allocationsEnrollment = 0;
    statuses?.forEach((status) => {
      if (status.STATUS_APPROVED && !status.SUB_STATUS_REMOVED && !status.SUB_STATUS_WITHDRAWN && !status.SUB_STATUS_DECLINED) {
        allocationsEnrollment += 1;
      }
    });
    return allocationsEnrollment;
  },
  /**
   * Check if a user has the given permission
   * @param {Object} user - the user information
   * @param {String} permissionSlug - The permission that is checked
   * @returns Whether the user has the permission
   */
  hasPermission: (user, permissionSlug) => {
    return user?.permissions?.some(permission => permission.PERMISSION_SLUG === permissionSlug);
  },
  /**
   * Get the list of OWNERS that match the allocation group's owner and the user's ALLOCATION_GROUP_OWNER
   * @param {Array} allocations - Array of allocation objects that contain allocation information
   * @param {Object} user - the user information
   * @returns The array of owners filtered to those listed in the user's ALLOCATION_GROUP_OWNER field
   */
  filterAllocationsByOwner: (allocations, user) => {
    // Check if user.allocationGroupOwner exists and is an array
    if (!user.allocationGroupOwner || !Array.isArray(user.allocationGroupOwner)) {
      return [];
    }
    
    // Filter allocations based on matching owners
    return allocations.filter(allocation => {
      if (allocation.OWNERS && Array.isArray(allocation.OWNERS)) {
        // Check if any of the OWNERS match the values in user.allocationGroupOwner
        return allocation.OWNERS.some(owner => user.allocationGroupOwner.includes(owner));
      }
      return false; // If OWNERS is not an array or is null/undefined
    });
  },
  /**
   * @description Transform the list of status records to include the ACTIVE_STATUS. That is, the status related to the current activity. 
   * @param {Array} records - Array of status records
   * @param {Object} activity - Activity data
   * @returns the same records object with an additional ACTIVE_STATUS field
   */
  transformStatusRecords: (records, activity) => {
    const output = [];
    records.forEach((record) => {
      const activeStatus = record.STATUSES?.find((status) => { return status.ACTIVITY_ID === activity._id });
      output.push({ACTIVE_STATUS: activeStatus, ...record});
    });
    
    return output;
  },
  /**
   * Get the activity information for the activities listed in the FULFILLED_BY field of tha activity passed into this function
   * @param {Array} activityList - The list of activities
   * @param {Object} activity - The activity to be analyzed
   * @returns A list of activities found in the FULFILLED_BY field of the activity passed in
   */
  getFulfillmentsList: (activityList, activity) => {
    const output = activity?.FULFILLED_BY.map((fulfillment) => {
      const matchedActivity = activityList.find((act) => ( act._id === fulfillment));
      if(matchedActivity) {
        return {
          ACTIVITY_NAME: matchedActivity.ACTIVITY_NAME,
          ACTIVITY_ID: matchedActivity._id
        }
      }
    });

    if(output) {
      return output.filter((result) => result !== undefined);
    }

    return [];
  }
};

export default utils;