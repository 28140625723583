import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rosterData: [],
  rosterSearchTerm: '',
  currentAllocation: null,
  allocations: [],
  nominations: 0,
  enrollment: 0,
  warningBody: null
};

const rosterSlice = createSlice({
  name: 'roster',
  initialState,
  reducers: {
    setRosterData(state, action) {
      state.rosterData = action.payload
    },
    setSearchTerm(state, action) {
      state.rosterSearchTerm = action.payload
    },
    setCurrentAllocation(state, action) {
      state.currentAllocation = action.payload
    },
    setAllocations(state, action) {
      state.allocations = action.payload
    },
    setNominations(state, action) {
      state.nominations = action.payload
    },
    setEnrollment(state, action) {
      state.enrollment = action.payload
    },
    setWarningBody(state, action) {
      state.warningBody = action.payload
    }
  },
});

export const { setRosterData, setSearchTerm, setCurrentAllocation, setAllocations, setNominations, setEnrollment, setWarningBody } = rosterSlice.actions;

export default rosterSlice.reducer;
