import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { useAxiosInterceptor } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState, setFormComponent, setFormData, setActivityData, setFormFlag } from "stores/admin/adminSlice";
import utils from "utils/utils";
import AdminTableCheckbox from "./AdminTableCheckbox";

/**
 * AdminActivityList component renders a table of activities.
 * It utilizes Chakra UI components for styling.
 * The table includes columns for ID, Active status, Name, Cycle, Start Date, and End Date.
 * It also provides a checkbox for each row and allows opening a drawer for editing an activity.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.searchTerm - Search term for filtering activities by name.
 * @param {boolean} props.hideInactive - Flag to hide inactive activities.
 * @returns {JSX.Element} - AdminActivityList component JSX.
 */
const AdminActivityList = ({ searchTerm, hideInactive }) => {
  // State variables for managing loading state and filtered data
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  // Redux hooks for accessing activity data and dispatching actions
  const data = useSelector((state) => state.admin.activityData);
  const submissionTrigger = useSelector((state) => state.admin.submissionTrigger);
  const dispatch = useDispatch();

  // Axios instance from useAxiosInterceptor custom hook
  const { axios } = useAxiosInterceptor();

  // useEffect to fetch activity data and set form component
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/activity/allActivities`);
        setLoading(false);
        setFilteredData(data?.data);
        dispatch(setActivityData(data?.data));
        dispatch(setFormComponent("ActivityForm"));
      } catch (error) {
        console.log(error);
        return <span>Error</span>;
      }
    };

    fetchData();
  }, [axios, submissionTrigger, dispatch]);

  // useEffect to filter data based on search term and active status
  useEffect(() => {
    const filtered = data?.filter(record => {
      return (
        (record?.ACTIVE || record?.ACTIVE === hideInactive) &&
        (record?.ACTIVITY_NAME?.toLowerCase().includes(searchTerm))
      );
    });

    setFilteredData(filtered);
  }, [searchTerm, hideInactive, data]);

  // Function to handle opening drawer for editing an activity
  const handleDrawerOpen = (e) => {
    dispatch(setDrawerState(true));
    dispatch(setFormFlag('update'));
    const _id = e.target.dataset?.value;

    if (_id) {
      const targetRecord = data.find((record) => {
        return record._id === _id;
      });

      dispatch(setFormData(targetRecord));
    }
  };

  // Render loading spinner if data is loading
  if (loading) {
    return <Loading />;
  }

  return (
    <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
      <Table variant='striped' size='sm'>
        <Thead bgColor='white'>
          <Tr>
            <Th></Th>
            <Th>ID</Th>
            <Th>Active</Th>
            <Th>Name</Th>
            <Th>Cycle</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapping through filtered data to render table rows */}
          {
            filteredData.map((row) => (
              <Tr key={row._id}>
                <Td>
                  {/* Checkbox component for selecting the row */}
                  <AdminTableCheckbox value={row} />
                </Td>
                {/* Cell for displaying ID */}
                <Td data-value={row._id} cursor='pointer' onClick={handleDrawerOpen} style={{textDecoration: 'underline'}} _hover={{color: 'blue.500'}}>{row._id}</Td>
                {/* Cell for displaying Active status */}
                <Td>{row.ACTIVE ? 'Active' : 'Inactive'}</Td>
                {/* Cell for displaying Name */}
                <Td>{row.ACTIVITY_NAME}</Td>
                {/* Cell for displaying Cycle */}
                <Td>{row.ACTIVITY_NOMINATION_CYCLE}</Td>
                {/* Cell for displaying Start Date */}
                <Td>{utils.formatDate(row.ACTIVITY_START_DT)}</Td>
                {/* Cell for displaying End Date */}
                <Td>{utils.formatDate(row.ACTIVITY_END_DT)}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AdminActivityList;
