import React, { useState, useEffect } from 'react';
import './css/gaugeChart.css';

const GaugeChart = ({ percentage }) => {
  const [radius, setRadius] = useState(150); // Initial radius value
  const [strokeWidth, setStrokeWidth] = useState(50); // Initial strokeWidth value
  const [fontSize, setFontSize] = useState(64);
  const center = radius + strokeWidth; // Center of the circle

  const arcAngle = (percentage / 100) * Math.PI;
  const startX = center + radius * Math.cos(Math.PI);
  const startY = center + radius * Math.sin(Math.PI);
  const endX = center + radius * Math.cos(Math.PI + arcAngle);
  const endY = center + radius * Math.sin(Math.PI + arcAngle);

  const handleResize = () => {
    const viewportWidth = window.innerWidth;
    const parentContainerWidth = viewportWidth * 0.32; // 33% of the window width
    const newRadius = Math.min(parentContainerWidth * 0.4, 150); // Adjust the scale factor as needed
    const newStrokeWidth = newRadius * 0.3; // Adjust the scale factor as needed
    const newFontSize = newRadius * 0.5;

    setRadius(newRadius);
    setStrokeWidth(newStrokeWidth);
    setFontSize(newFontSize);
  };

  useEffect(() => {
    handleResize(); // Set initial values
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
    };
  }, []);

  return (
    <div className='gague-chart-container'>
      <svg width={2 * center} height={center} viewBox={`0 0 ${2 * center} ${center}`}>
        {/* Background circle */}
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke="#ddd"
          strokeWidth={strokeWidth}
        />

        {/* Percentage arc */}
        <path
          d={`M${startX},${startY} A${radius},${radius} 0 ${arcAngle > Math.PI ? "1" : "0"} 1 ${endX},${endY}`}
          fill="none"
          stroke="#4E6FAE"
          strokeWidth={strokeWidth}
        />

        {/* Percentage label */}
        <text x={center} y={center - 10} textAnchor="middle" fontSize={fontSize} fontWeight='700'>
          {`${percentage}%`}
        </text>
      </svg>
    </div>
  );
};

export default GaugeChart;
