import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentCycle: null,
  activity: null,
  group: null,
};

const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    setCurrentCycle(state, action) {     
      state.currentCycle = Number(action.payload);
    },
    setActivity(state, action) {     
      state.activity = action.payload;
    },
    setGroup(state, action) {     
      state.group = action.payload;
    }
  },
});

export const { setCurrentCycle, setActivity, setGroup } = reportingSlice.actions;

export default reportingSlice.reducer;
