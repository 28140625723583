import { useDispatch } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { setGroup } from 'stores/reporting/reportingSlice';

const colors = ['#FF0000', '#4EAE69', '#D94E00', '#8F4EAE', '#FFA800', '#FF4BA2', '#00C2FF'];

const GroupChart = ({ data }) => {
  const dispatch = useDispatch();

  const tickFormatter = (value) => {
    if (value.length > 10) {
      return value.substring(0, 4) + "..." + value.slice(-6);
    }
    return value;
  };

  const handleBarClick = (barData) => {
    dispatch(setGroup(barData?.payload?.ALLOCATION_GROUP_ID));
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart barGap={-56} data={data} overflow={'visible'}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ALLOCATION_GROUP_NAME" xAxisId={1} hide/>
        <XAxis 
          dataKey="ALLOCATION_GROUP_NAME" 
          angle={-45} 
          tick={{ fontSize: 10, dy: 16, dx: -10 }} 
          tickFormatter={tickFormatter}
          margin={{ top: 10, bottom: 20 }}
        />
        <YAxis />
        <Tooltip />
        <Bar dataKey="CAPACITY" xAxisId={1} fill="rgba(220, 220, 220, 1)"/>
        <Bar onClick={handleBarClick} dataKey="ENROLLMENT" fill="rgba(75, 192, 192, 1)">
        {
          data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))
        }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GroupChart;
