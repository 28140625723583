import React, { useState } from 'react';
import { RxCaretUp, RxCaretDown} from 'react-icons/rx';
import './css/flexibleTable.css';
import './css/flexibleTableWithDropdown.css'; 

const FlexibleTableWithDropdown = ({ data, columns, columnToFieldMapping, status }) => {
  const [expandedRows, setExpandedRows] = useState([]); // To track expanded rows

  if (!data || data.length === 0 || !columns || columns.length === 0) {
    return <div>No data to display.</div>;
  }

  // Function to toggle the visibility of sub-rows
  const toggleSubRows = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((index) => index !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  // Function to render the caret icon
  const renderCaretIcon = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      return <RxCaretUp className="caret-icon" onClick={() => toggleSubRows(rowIndex)} size={24}/>; // Upward-pointing caret when expanded
    } else {
      return <RxCaretDown className="caret-icon" onClick={() => toggleSubRows(rowIndex)} size={24}/>; // Downward-pointing caret when collapsed
    }
  };

  return (
    <div className='flexible-table-container'>
      <div className="flexible-table-scroll-container">
        <table className="flexible-table">
          <thead>
            <tr>
              <th></th>
              {columns.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr>
                  <td
                    onClick={() => toggleSubRows(rowIndex)}
                  >
                    {renderCaretIcon(rowIndex)}
                  </td>
                  {columns.map((column, colIndex) => (
                    <td key={colIndex}>
                      {columnToFieldMapping[column] === 'STATUS' ? (
                        <></>
                      ) : (
                        row[columnToFieldMapping[column]]
                      )}
                    </td>
                  ))}
                </tr>
                {expandedRows.includes(rowIndex) &&
                  row.ACTIVITIES.map((activity, activityIndex) => (
                    <tr
                      className="subrow"
                      key={`${rowIndex}_sub_${activityIndex}`}
                    >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{activity.ACTIVITY_NAME}</td>
                      <td>
                        <span className={`status-cell ${activity.status ? activity.status.toLowerCase() + "-cell" : status.toLowerCase() + "-cell"}`}>
                          {activity.status ? activity.status : status}
                        </span>
                      </td>
                      <td>
                      <span className={`status-cell ${activity.subStatus?.toLowerCase() + "-cell"}`}>
                          {activity.subStatus}
                        </span>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* Add additional columns for sub-rows here if needed */}
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FlexibleTableWithDropdown;
