import { Flex, Select, IconButton } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

/**
 * @description This component renders the Allocation Form for creating and updating allocations.
 * @function AllocationForm
 * @returns {React.Component} The component that renders a the form for allocation creation.
 */

const AllocationForm = ({ allocationGroups, newAllocation, onAddAllocation, onGroupIdChange }) => {
  return (
    <Flex alignItems='center'>
      <Select
        placeholder='Select Group'
        value={newAllocation.groupId}
        onChange={onGroupIdChange}
      >
        {/* Map through each allocation group and get the GROUP_NM for the label of the option */}
        {allocationGroups.map(group => (
          <option key={group._id} value={group._id}>{group.GROUP_NM}</option>
        ))}
      </Select>
      <IconButton 
        ml={5}
        colorScheme='blue' 
        variant='outline' 
        isDisabled={newAllocation.groupId ? false : true} 
        onClick={onAddAllocation} 
        aria-label='Add Allocation' 
        icon={<AddIcon />} 
      />
    </Flex>
  );
};

export default AllocationForm;
