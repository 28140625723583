import { useEffect, useState } from 'react';
import { getUnfilledSeats, getMultipleNominations, getBreakdownByProgram, getBreakdownByGroup, getEnrollmentPercentage, transformHistoricalParticipation, getAllData } from 'utils/reportingUtils'; 
import { generateExcelFile } from 'utils/export';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';
import { Box, Flex, HStack, IconButton, Select, Stack, Stat, StatGroup, StatLabel, StatNumber, Text, VStack } from "@chakra-ui/react";
import Nav from "components/common/Nav";
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/common/Loading';
import { DownloadIcon } from '@chakra-ui/icons';
import { setActivity, setCurrentCycle, setGroup } from 'stores/reporting/reportingSlice';
import ActivityChart from './ActivityChart';
import GroupChart from './GroupChart';
import GaugeChart from './GaugeChart';
import InfoIcon from './InfoIcon';
import FlexibleTableWithDropdown from './FlexibleTableWithDropdown';
import FlexibleTable from './FlexibleTable';

const columns = [
  "Employee ID", "Employee Name", "Program Name", "Status", "Sub Status", "Hire Date", "Job Title", "Career Level Code",
  "Gender", "Ethnicity", "Manager Name", "Solution Group", "Department Function",
  "Brand", "Level 03 Manager", "Level 04 Manager", "Level 05 Manager",
  "Level 06 Manager", "Level 07 Manager", "Level 08 Manager", "HRBP 1", "HRBP 2"
];

const columnToFieldMapping = {
  "Employee ID": "PERSON_ID",
  "Employee Name": "PRI_DISPLAY_NM", 
  "Hire Date": "FIRST_HIRE_DT", 
  "Job Title": "JOB_TITLE", 
  "Career Level Code": "CF_CAREER_LEVEL_CD",
  "Gender": "GENDER_CD", 
  "Ethnicity": "ETHNICITY", 
  "Manager Name": "SUPERVISOR_PRI_DISPLAY_NM", 
  "Solution Group": "DEPARTMENT_GROUP_NM", 
  "Department Function": "DEPARTMENT_FUNCTION_NM",
  "Brand": "DEPARTMENT_BRAND_NM", 
  "Level 03 Manager": "LVL03_PRI_DISPLAY_NM", 
  "Level 04 Manager": "LVL04_PRI_DISPLAY_NM", 
  "Level 05 Manager": "LVL05_PRI_DISPLAY_NM",
  "Level 06 Manager": "LVL06_PRI_DISPLAY_NM", 
  "Level 07 Manager": "LVL07_PRI_DISPLAY_NM", 
  "Level 08 Manager": "LVL08_PRI_DISPLAY_NM",
  "HRBP 1": "HRBP1_NAME",
  "HRBP 2": "HRBP2_NAME",
  "Program Name": "ACTIVITY_NAME",
  "Status": "STATUS",
  "Sub Status": "SUB_STATUS",
};

const ReportingPage = () => {
  const user = useSelector((state) => state.auth.user);
  const currentCycle = useSelector((state) => state.reporting.currentCycle);
  const program = useSelector((state) => state.reporting.activity);
  const group = useSelector((state) => state.reporting.group);  
  const [cycles, setCycles] = useState([]);
  const [activityData, setActivityData] = useState([])
  const [allocations, setAllocations] = useState([]); 
  const [allocationGroups, setAllocationGroups] = useState([]);
  const [nominations, setNominations] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [unfilledSeats, setUnfilledSeats] = useState(0);
  const [multipleNominations, setMultipleNominations] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [enrollmentPercentage, setEnrollmentPercentage] = useState(0);
  const [historicalProgramParticipationPercentage, setHistoricalProgramParticipationPercentage] = useState(0);
  const [historicalProgramParticipation, setHistoricalProgramParticipation] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { axios } = useAxiosInterceptor();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCycles = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/nominationCycle/nominationCycles`);
        setCycles(data.data);
        dispatch(setCurrentCycle(data.data?.length));
      
      } catch(error) {
        console.log(error);
        return <span>Error</span>
      }
    }
    
    fetchCycles();
  }, [axios]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        const activities = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/reporting/reportingActivityData`, { cycle: currentCycle, program });
        setActivityData(activities.data);

        const allocationData = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/reporting/reportingAllocationData`, { cycle: currentCycle, group, program });
        setAllocations(allocationData.data);

        const allocationGroupData = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/reporting/reportingAllocationGroupData`, { group });
        setAllocationGroups(allocationGroupData.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    if(currentCycle) {
      fetchData();
    }
  }, [currentCycle, program, group]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const nominationData = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/reporting/reportingNominations`, { cycle: currentCycle, program });
        const approvalData = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/reporting/reportingApprovals`, { cycle: currentCycle, group, program });
      
        nominationData.data.forEach((obj) => {
          obj.ALLOCATION_GROUPS = [];
    
          allocationGroups.forEach((allocationGroup) => {
            if(allocationGroup.EMPLOYEE_IDS.includes(obj.PERSON_ID)) {
              obj.ALLOCATION_GROUPS.push(allocationGroup)
            }
          })
        });
    
        const output = nominationData.data.filter((data) => {
          return data.ALLOCATION_GROUPS.length > 0
        })
  
        setNominations(output);
        setApprovals(approvalData.data);
        setLoading(false);
      } catch(error) {
        console.log(error);
        return <span>Error</span>
      }
    }

    if(currentCycle && allocationGroups.length) {
      fetchData();
    }
  }, [currentCycle, program, group, allocationGroups]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUnfilledSeats(allocations, approvals, setUnfilledSeats);

        const userIds = new Set();
        approvals.forEach((approval) => {
          userIds.add(approval.PERSON_ID)
        })

        const userIdsArray = Array.from(userIds);

        const historicalParticipationData = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/reporting/reportingHistoricalParticipation`, { userIds: userIdsArray });

        transformHistoricalParticipation(historicalParticipationData, approvals, setHistoricalProgramParticipation, setHistoricalProgramParticipationPercentage);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    if(currentCycle && approvals.length) {
      fetchData();
    }
  }, [currentCycle, program, group, allocations, approvals]);

  useEffect(() => {
    const fetchData = async () => {
      if(activityData.length & !program) {
        getBreakdownByProgram(allocations, approvals, activityData, setProgramData);
      }
    }

    fetchData();
  }, [activityData, allocations, approvals, currentCycle, program, group])

  useEffect(() => {
    getAllData(approvals, nominations, setAllData)
  }, [nominations, approvals, currentCycle, program, group])

  useEffect(() => {
    const fetchData = async () => {
      if(allocationGroups.length && !group) {
        getBreakdownByGroup(allocations, approvals, allocationGroups, setGroupData);
      }
    }

    fetchData();
  }, [allocationGroups, allocations, approvals, currentCycle, program, group])

  useEffect(() => {
    const fetchData = async () => {
      if(!program && activityData.length) {
        getMultipleNominations(nominations, activityData, setMultipleNominations);
      }
    }

    fetchData();
  }, [nominations, activityData, currentCycle, program, group])

  useEffect(() => {
    getEnrollmentPercentage(approvals.length, unfilledSeats, setEnrollmentPercentage);
  }, [approvals, unfilledSeats, currentCycle, program, group])

  const getProgramName = (() => {
    if(program && activityData.length) {
      const activity = activityData.find((activity) => { return activity._id === program })
      if(activity) {
        return activity.ACTIVITY_NAME.toUpperCase();
      }
    } 

    return 'ALL PROGRAMS';
  });

  const getGroupName = (() => {
    if(group && allocationGroups.length) {
      const allocationGroup = allocationGroups.find((allocationGroup) => { return allocationGroup._id === group })
      if(allocationGroup) {
        return allocationGroup.GROUP_NM.toUpperCase();
      }
    } 

    return 'ALL GROUPS';
  });

  const handleCycleChange = (e) => {
    dispatch(setCurrentCycle(e.target.value));
  }

  if(loading) {
    return (
      <Stack display='flex' justify='start'>
        <Nav title='NominateNow'/>
        <Loading/>
      </Stack>
    )
  }

  return (
    <Stack bgColor='gray.50' display='flex' justify='start'>
      <Nav title='NominateNow'/>
      <Flex justify='space-between'>
        <HStack flex={3} p={2}>
          {
            program
            &&
            <Text 
              onClick={() => (dispatch(setActivity(null)))} 
              fontSize='sm' 
              color='gray.500'
              cursor='pointer'
              _hover={{textDecor: 'underline'}}
            >
              ALL PROGRAMS / 
            </Text>
          }

          <Text fontSize='sm'>
            {getProgramName()} | 
          </Text>
          {
            group
            &&
            <Text 
              onClick={() => (dispatch(setGroup(null)))} 
              fontSize='sm' 
              color='gray.500'
              cursor='pointer'
              _hover={{textDecor: 'underline'}}
            >
              ALL GROUPS / 
            </Text>
          }
          <Text fontSize='sm'>
            {getGroupName()}
          </Text>
        </HStack>
        {
          !program
          &&
          <Select bgColor='white' flex={1} defaultValue={currentCycle} onChange={handleCycleChange}>
            {
              cycles.map((cycle) => {
                return (
                  cycle.CYCLE_NUMBER !== 1
                  &&
                  <option key={cycle._id} value={cycle.CYCLE_NUMBER}>{`${cycle.CYCLE_NUMBER} - ${cycle.CYCLE_LABEL}`}</option>
                )
              })
            }
          </Select>
        }
      </Flex>
      <StatGroup p={5} bgColor='white'>
        <Stat>
          <StatLabel>NOMINATIONS</StatLabel>
          <StatNumber fontSize={40}>{nominations.length}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>APPROVALS</StatLabel>
          <StatNumber fontSize={40}>{approvals.length}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>UNFILLED SEATS</StatLabel>
          <StatNumber fontSize={40}>{unfilledSeats}</StatNumber>
        </Stat>
        {
          program === null 
          &&
          <Stat>
            <StatLabel>MULTIPLE NOMINATIONS</StatLabel>
            <StatNumber fontSize={40}>{multipleNominations.length}</StatNumber>
          </Stat>
        }
      </StatGroup>
      {
        program == null
        &&
        <Box p={5} h={420} mb='80px'>
          <HStack>
            <Text>BREAKDOWN BY PROGRAM</Text>
            <InfoIcon popupData='This chart shows the number of seats that have been “filled” for each program.'/>
          </HStack>
          <ActivityChart params={{ cycle: currentCycle, program, group }} data={programData}/>
        </Box>
      }
      <Flex>
        {
          group == null
          &&
          <Box p={5} w='50%' h={560}>
            <HStack>
              <Text>BREAKDOWN BY GROUP</Text>
              <InfoIcon popupData='This chart shows the number of seats that have been “filled” for each group out of their total number of seats across all programs.'/>
            </HStack>
            <GroupChart params={{ cycle: currentCycle, program, group }} data={groupData}/>
          </Box>
        }
        <VStack>
          <Box p={5} borderRadius='1px solid gray.100'>
            <HStack>
              <Text>SEATS FILLED</Text>
              <InfoIcon popupData='The percentage of the total seats filled given the current filters.'/>
            </HStack>
            <GaugeChart
              percentage={enrollmentPercentage}
            />
          </Box>
          <Box p={5}>
            <HStack>
              <Text>HISTORICAL PROGRAM PARTICIPATION</Text>
              <InfoIcon popupData='The percentage of nominated employees that have taken part in any nomination program in the past.'/>
            </HStack>
            <GaugeChart
              percentage={historicalProgramParticipationPercentage}
            />
          </Box>
        </VStack>
      </Flex>
        {
          program == null && multipleNominations.length
          &&
          <Box p={5}>
            <Flex justifyContent='space-between'>
              <HStack>
                <Text>MULTIPLE NOMINATIONS</Text>
                <InfoIcon popupData='The list of employees that have been nominated for more than one program during this cycle.'/>
              </HStack>
              <IconButton 
                colorScheme='blue'
                variant='ghost' 
                aria-label='Download data' 
                icon={<DownloadIcon />} 
                onClick={() => {generateExcelFile(multipleNominations)}}
              />
            </Flex>
            <FlexibleTableWithDropdown status='Nominated' data={multipleNominations} columns={columns} columnToFieldMapping={columnToFieldMapping}/>
          </Box>
        }
        {
          historicalProgramParticipation.length
          &&
          <Box p={5}>
            <Flex justifyContent='space-between'>
              <HStack>
                <Text>HISTORICAL PROGRAM PARTICIPATION</Text>
                <InfoIcon popupData='The list of nominated employees that have taken part in any nomination program in the past.'/>
              </HStack>
              <IconButton 
                colorScheme='blue'
                variant='ghost' 
                aria-label='Download data' 
                icon={<DownloadIcon />} 
                onClick={() => {generateExcelFile(historicalProgramParticipation)}}
              />
            </Flex>
            <FlexibleTableWithDropdown status='Approved' data={historicalProgramParticipation} columns={columns} columnToFieldMapping={columnToFieldMapping}/>
          </Box>
        }
        <Box p={5}>
          <Flex justifyContent='space-between'>
            <HStack>
              <Text>ALL DATA</Text>
              <InfoIcon popupData='The list of nominated and approved employees for this cycle given the current filters.'/>
            </HStack>
            <IconButton 
              colorScheme='blue'
              variant='ghost' 
              aria-label='Download data' 
              icon={<DownloadIcon />} 
              onClick={() => {generateExcelFile(allData)}}
            />
          </Flex>
          <FlexibleTable data={allData} columns={columns} columnToFieldMapping={columnToFieldMapping} />
        </Box>
    </Stack>
  );
};

export default ReportingPage;