import { createSlice } from '@reduxjs/toolkit';

const RESULTS_LIMIT = 50;

const initialState = {
  searchKeyword: '',
  filters: {
    PAGE_NUMBER: 1,
    LIMIT: RESULTS_LIMIT,
  },
  employeeData: [],
  currentEmployee: null,
  statusExists: false
};

const employeeSearchSlice = createSlice({
  name: 'employeeSearch',
  initialState,
  reducers: {
    setSearchKeyword(state, action) {
      state.searchKeyword = action.payload
    },
    setFilters(state, action) {
      state.filters = action.payload
    },
    resetFilters(state) {
      state.filters = {
        PAGE_NUMBER: 1,
        LIMIT: RESULTS_LIMIT
      };
      state.searchKeyword = '';
    },
    setEmployeeData(state, action) {
      state.employeeData = action.payload
    },
    setCurrentEmployee(state, action) {
      state.currentEmployee = action.payload
    },
    setStatusExists(state, action) {
      state.statusExists = action.payload
    },
  },
});

export const { setSearchKeyword, setFilters, resetFilters, setEmployeeData, setCurrentEmployee, setStatusExists } = employeeSearchSlice.actions;

export default employeeSearchSlice.reducer;
