import { IconButton, useToast } from "@chakra-ui/react";
import { useAxiosInterceptor } from "hooks/useAxiosInterceptor";
import { useState } from "react";
import { RepeatIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setAllocationGroupData } from "stores/admin/adminSlice";

/**
 * AllocationGroupRefresh component renders a button to refresh an Allocation Group
 * It utilizes Chakra UI components for styling.
 * The button will go into a loading state when clicked and will return to normal after the API call returns
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.data - The data of the associated allocation group.
 * @returns {JSX.Element} - AllocationGroupRefresh component JSX.
 */
const AllocationGroupRefresh = ({ data }) => {
  // State variables for managing loading state and data
  const [loadingRefresh, setLoadingRefresh] = useState(false);

  // Redux hooks for accessing allocationGroupData and dispatching actions
  const allocationGroupData = useSelector((state) => state.admin.allocationGroupData);
  const dispatch = useDispatch();

  // Axios instance from useAxiosInterceptor custom hook
  const { axios } = useAxiosInterceptor();

  // Hook used to display toast messages
  const toast = useToast();

  const refreshAllocationGroup = async (allocationGroup) => {  
    setLoadingRefresh(true);

    const response = await axios.put(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup`, { data: allocationGroup });
  
    setLoadingRefresh(false);
    if (response.status === 200) {
      const updatedAllocationGroup = response.data;
      const currentAllocationGroups = allocationGroupData.map(group => ({ ...group }));

      await currentAllocationGroups.forEach((group) => {
        if(group._id === updatedAllocationGroup._id) {
          group.EMPLOYEE_IDS = updatedAllocationGroup.EMPLOYEE_IDS;
        }
      });

      dispatch(setAllocationGroupData(currentAllocationGroups));

      toast({
        title: 'Allocation Group Updated',
        description: `Allocation Group: ${allocationGroup.GROUP_NM} has been updated.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'An error occurred',
        description: `An unknown error occurred.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <IconButton
      isLoading={loadingRefresh}
      colorScheme='blue'
      variant='ghost'
      aria-label='Refresh allocation group'
      icon={<RepeatIcon />}
      onClick={() => refreshAllocationGroup(data)}
    />
  );
};

export default AllocationGroupRefresh;
