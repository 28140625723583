import { 
  Box, ListItem, Text, UnorderedList,
} from '@chakra-ui/react';

const MainDrawerContentBox = ({ title, subtitle, list }) => {
  return (
    <Box borderWidth='1px' borderRadius='md' borderColor='gray.200' p={4} mt={5}>
      <Box borderBottomWidth='1px' borderColor='gray.200' pb={2}>
        <Text as='b'>{title}</Text>
      </Box>      
      <Box pt={2} maxHeight='calc(100% - 40px)' overflowY='auto'>
        <Box>
          <Text as='i'>{subtitle}</Text>
        </Box>
        <UnorderedList marginInlineStart='1.1em'>
          {
            list?.map((item, idx) => (
              <ListItem key={idx}>{item}</ListItem>
            ))
          } 
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default MainDrawerContentBox;
