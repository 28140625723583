const columnToFieldMapping = {
  "Employee ID": "PERSON_ID",
  "Employee Name": "PRI_DISPLAY_NM", 
  "Program Name": "ACTIVITY_NAME",
  "Status": "STATUS",
  "Sub Status": "SUB_STATUS",
  "Hire Date": "FIRST_HIRE_DT", 
  "Job Title": "JOB_TITLE", 
  "Career Level Code": "CF_CAREER_LEVEL_CD",
  "Gender": "GENDER_CD", 
  "Ethnicity": "ETHNICITY", 
  "Manager Name": "SUPERVISOR_PRI_DISPLAY_NM", 
  "Solution Group": "DEPARTMENT_GROUP_NM", 
  "Department Function": "DEPARTMENT_FUNCTION_NM",
  "Brand": "DEPARTMENT_BRAND_NM", 
  "Level 03 Manager": "LVL03_PRI_DISPLAY_NM", 
  "Level 04 Manager": "LVL04_PRI_DISPLAY_NM", 
  "Level 05 Manager": "LVL05_PRI_DISPLAY_NM",
  "Level 06 Manager": "LVL06_PRI_DISPLAY_NM", 
  "Level 07 Manager": "LVL07_PRI_DISPLAY_NM", 
  "Level 08 Manager": "LVL08_PRI_DISPLAY_NM",
  "HRBP 1": "HRBP1_NAME",
  "HRBP 2": "HRBP2_NAME",
};

/**
 * @description Utility function to generate a CSV that the user can open in Excel to do their own reporting. 
 */
export const generateExcelFile = (data) => {
  const csvHeaders = Object.keys(columnToFieldMapping);

    // Create a CSV string with the headers
    let csvContent = csvHeaders.join(',') + '\n';

    // Process each object in the input data
    data.forEach((obj) => {
        const activities = obj.ACTIVITIES;
        if (!activities || activities.length === 0) {
          // Export all data
          const rowValues = csvHeaders.map((header) => {
            const fieldValue = obj[columnToFieldMapping[header]];
            return (fieldValue ? fieldValue : '').toString().replace(/,/g, ''); // Remove commas from other fields
          });

          csvContent += rowValues.join(',') + '\n';
        } else {
          activities.forEach((activity) => {
            // Create a row for each activity
            const rowValues = csvHeaders.map((header) => {
                if (header === 'Program Name') {
                  return (activity.ACTIVITY_NAME || '').replace(/,/g, ''); // Remove commas from ACTIVITY_NAME
                }
                if (header === 'Sub Status') {
                  return (activity.subStatus || '').replace(/,/g, ''); // Remove commas
                }
                if (header === 'Status') {
                  return (activity.status || obj.STATUS || '').replace(/,/g, ''); // Remove commas
                }
                const fieldValue = obj[columnToFieldMapping[header]];
                return (fieldValue !== undefined ? fieldValue : '').toString().replace(/,/g, ''); // Remove commas from other fields
            });
            csvContent += rowValues.join(',') + '\n';
        });
        }
    });

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a URL for the Blob and create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'output.csv';

    a.click();

    window.URL.revokeObjectURL(url);
}