import utils from "./utils";
/**
 * This file contains utility functions used for the ReportingPage component. These functions are used 
 * to fetch data from the database as well as combine this data in order for it to be rendered in the 
 * Reporting Dashboard. 
 */

/**
 * Calculates the number of unfilled seats based on allocations and approvals.
 * 
 * @param {Array} allocations - An array of allocation objects containing seat allocation data.
 * @param {Array} approvals - An array of approved seats.
 * @param {Function} setUnfilledSeats - A function to update the state with the number of unfilled seats.
 * @returns {Promise<void>} Promise indicating completion of the calculation.
 */
export const getUnfilledSeats = async (allocations, approvals, setUnfilledSeats) => {
  // Calculate total capacity from allocations
  let capacity = 0;
  allocations?.forEach((allocation) => {
    capacity += allocation.SEAT_ALLOCATION;
  });

  // Calculate the number of unfilled seats
  let unfilledSeats = capacity - approvals.length;

  // Update the state with the number of unfilled seats
  setUnfilledSeats(unfilledSeats);
}


/**
 * Group nominations by employee and filter for multiple nominations.
 * 
 * @param {Array} nominations - An array of nomination objects.
 * @param {Array} activityData - An array of activity data objects.
 * @param {Function} setMultipleNominations - A function to update the state with multiple nominations.
 */
export const getMultipleNominations = (nominations, activityData, setMultipleNominations) => {
  // Group nominations by employee
  const groupedData = nominations.reduce((result, item) => {
    const { PERSON_ID, ACTIVITY_ID, EMPLOYEE } = item;

    // Check if there is an existing group for the current ID
    const existingGroup = result.find((group) => group.PERSON_ID === PERSON_ID);

    // If there is an existing group, add the activity to its "ACTIVITIES" array
    if (existingGroup) {
      existingGroup.ACTIVITIES.push(ACTIVITY_ID);
    } else {
      // If there is no existing group, create a new group
      result.push({ PERSON_ID, ACTIVITIES: [ACTIVITY_ID], EMPLOYEE });
    }

    return result;
  }, []);

  // Filter for groups with multiple nominations
  const filteredGroups = groupedData.filter((group) => group.ACTIVITIES.length > 1);

  const output = [];

  // Create output data for multiple nominations
  filteredGroups?.forEach((item) => {
    const result = item.ACTIVITIES.map((activityId) => {
      // Find matching activity data for each activity ID
      const matchingActivity = activityData.find((act) => act._id === activityId);
      return matchingActivity;
    });

    // Push formatted data for multiple nominations to output array
    output.push({
      STATUS: 'Nominated',
      ACTIVITIES: result,
      CF_CAREER_LEVEL_CD: item.EMPLOYEE.CF_CAREER_LEVEL_CD,
      PRI_DISPLAY_NM: item.EMPLOYEE.PRI_DISPLAY_NM,
      PERSON_ID: item.EMPLOYEE.PERSON_ID,
      FIRST_HIRE_DT: item.EMPLOYEE.FIRST_HIRE_DT,
      JOB_TITLE: item.EMPLOYEE.JOB_TITLE,
      GENDER_CD: item.EMPLOYEE.GENDER_CD,
      ETHNICITY: item.EMPLOYEE.ETHNICITY,
      SUPERVISOR_PRI_DISPLAY_NM: item.EMPLOYEE.SUPERVISOR_PRI_DISPLAY_NM,
      DEPARTMENT_GROUP_NM: item.EMPLOYEE.DEPARTMENT_GROUP_NM,
      DEPARTMENT_FUNCTION_NM: item.EMPLOYEE.DEPARTMENT_FUNCTION_NM,
      DEPARTMENT_BRAND_NM: item.EMPLOYEE.DEPARTMENT_BRAND_NM,
      LVL03_PRI_DISPLAY_NM: item.EMPLOYEE.LVL03_PRI_DISPLAY_NM,
      LVL04_PRI_DISPLAY_NM: item.EMPLOYEE.LVL04_PRI_DISPLAY_NM,
      LVL05_PRI_DISPLAY_NM: item.EMPLOYEE.LVL05_PRI_DISPLAY_NM,
      LVL06_PRI_DISPLAY_NM: item.EMPLOYEE.LVL06_PRI_DISPLAY_NM,
      LVL07_PRI_DISPLAY_NM: item.EMPLOYEE.LVL07_PRI_DISPLAY_NM,
      LVL08_PRI_DISPLAY_NM: item.EMPLOYEE.LVL08_PRI_DISPLAY_NM,
      HRBP1_NAME: item.EMPLOYEE.HRBP1_NAME,
      HRBP2_NAME: item.EMPLOYEE.HRBP2_NAME,
    });
  });

  // Update the state with multiple nominations
  setMultipleNominations(output);
}

/**
 * Generate a breakdown of seat allocations and enrollments by program.
 * 
 * @param {Array} allocations - An array of allocation objects.
 * @param {Array} approvals - An array of approval objects.
 * @param {Array} activityData - An array of activity data objects.
 * @param {Function} setProgramData - A function to update the state with the breakdown data.
 */
export const getBreakdownByProgram = (allocations, approvals, activityData, setProgramData) => {
  const groupedActivities = [];

  // Group allocations by program and calculate capacity
  allocations?.forEach((allocation) => {
    const existingGroup = groupedActivities.find((group) => group.ACTIVITY_ID === allocation.ACTIVITY_ID);
    const activity = activityData.find((activity) => activity._id === allocation.ACTIVITY_ID);

    if (existingGroup) {
      existingGroup.CAPACITY += allocation.SEAT_ALLOCATION;
    } else {
      groupedActivities.push({ ACTIVITY_ID: allocation.ACTIVITY_ID, ACTIVITY_NAME: activity?.ACTIVITY_NAME, CAPACITY: allocation.SEAT_ALLOCATION, ENROLLMENT: 0 });
    }
  });

  // Map over approvals to increment enrollment
  approvals?.forEach((approval) => {
    const allocation = groupedActivities.find((group) => group.ACTIVITY_ID === approval.ACTIVITY_ID);

    if (allocation) {
      allocation.ENROLLMENT += 1;
    }
  });

  // Update the state with breakdown data
  setProgramData(groupedActivities);
}

/**
 * Generate a breakdown of seat allocations and enrollments by allocation group.
 * 
 * @param {Array} allocations - An array of allocation objects.
 * @param {Array} approvals - An array of approval objects.
 * @param {Array} allocationGroups - An array of allocation group data objects.
 * @param {Function} setGroupData - A function to update the state with the breakdown data.
 */
export const getBreakdownByGroup = (allocations, approvals, allocationGroups, setGroupData) => {
  const groupedAllocationGroups = [];

  // Group allocations by allocation group and calculate capacity
  allocations?.forEach((allocation) => {
    const existingGroup = groupedAllocationGroups.find((group) => group.ALLOCATION_GROUP_ID === allocation.ALLOCATION_GROUP_ID);
    const allocationGroup = allocationGroups.find((allocationGroup) => allocationGroup._id === allocation.ALLOCATION_GROUP_ID);

    if (existingGroup) {
      existingGroup.CAPACITY += allocation.SEAT_ALLOCATION;
    } else {
      groupedAllocationGroups.push({ ALLOCATION_GROUP_ID: allocation.ALLOCATION_GROUP_ID, ALLOCATION_GROUP_NAME: allocationGroup?.GROUP_NM, CAPACITY: allocation.SEAT_ALLOCATION, ENROLLMENT: 0 });
    }
  });

  // Map over approvals to increment enrollment
  approvals?.forEach((approval) => {
    const allocationGroup = groupedAllocationGroups.find((allocationGroup) => allocationGroup.ALLOCATION_GROUP_ID === approval.ALLOCATION.ALLOCATION_GROUP_ID);

    if (allocationGroup) {
      allocationGroup.ENROLLMENT += 1;
    }
  });

  // Update the state with breakdown data
  setGroupData(groupedAllocationGroups);
}

/**
 * Calculate the enrollment percentage based on the number of approvals and unfilled seats.
 * 
 * @param {number} approvals - The number of approvals.
 * @param {number} unfilledSeats - The number of unfilled seats.
 * @param {Function} setEnrollmentPercentage - A function to update the state with the enrollment percentage.
 */
export const getEnrollmentPercentage = (approvals, unfilledSeats, setEnrollmentPercentage) => {
  setEnrollmentPercentage(Math.round(approvals / (approvals + unfilledSeats) * 100));
}


/**
 * Transform historical participation data to group employees by their participation in multiple activities.
 * Calculate the historical program participation percentage based on the number of unique participants and approvals.
 * 
 * @param {Object[]} historicalParticipationData - The historical participation data to transform.
 * @param {Object[]} approvals - The list of approvals.
 * @param {Function} setHistoricalProgramParticipation - A function to update the state with the transformed historical program participation data.
 * @param {Function} setHistoricalProgramParticipationPercentage - A function to update the state with the historical program participation percentage.
 */
export const transformHistoricalParticipation = async (historicalParticipationData, approvals, setHistoricalProgramParticipation, setHistoricalProgramParticipationPercentage) => {
  const groupedEmployees = [];

  historicalParticipationData?.data?.forEach((item) => {
    const existingEmployee = groupedEmployees.find((employee) => { return employee._id === item.PERSON_ID });
    const statusObj = utils.captureStatusAndSubStatus(item);

    if(existingEmployee) {
      existingEmployee.ACTIVITIES.push({...item.ACTIVITY, ...statusObj});
    } else {
      groupedEmployees.push({
        _id: item.PERSON_ID,
        ACTIVITIES: [{...item.ACTIVITY, ...statusObj}],
        CF_CAREER_LEVEL_CD: item.EMPLOYEE.CF_CAREER_LEVEL_CD,
        PRI_DISPLAY_NM: item.EMPLOYEE.PRI_DISPLAY_NM,
        PERSON_ID: item.EMPLOYEE.PERSON_ID,
        FIRST_HIRE_DT: item.EMPLOYEE.FIRST_HIRE_DT,
        JOB_TITLE: item.EMPLOYEE.JOB_TITLE,
        GENDER_CD: item.EMPLOYEE.GENDER_CD,
        ETHNICITY: item.EMPLOYEE.ETHNICITY,
        SUPERVISOR_PRI_DISPLAY_NM: item.EMPLOYEE.SUPERVISOR_PRI_DISPLAY_NM,
        DEPARTMENT_GROUP_NM: item.EMPLOYEE.DEPARTMENT_GROUP_NM,
        DEPARTMENT_FUNCTION_NM: item.EMPLOYEE.DEPARTMENT_FUNCTION_NM,
        DEPARTMENT_BRAND_NM: item.EMPLOYEE.DEPARTMENT_BRAND_NM,
        LVL03_PRI_DISPLAY_NM: item.EMPLOYEE.LVL03_PRI_DISPLAY_NM,
        LVL04_PRI_DISPLAY_NM: item.EMPLOYEE.LVL04_PRI_DISPLAY_NM,
        LVL05_PRI_DISPLAY_NM: item.EMPLOYEE.LVL05_PRI_DISPLAY_NM,
        LVL06_PRI_DISPLAY_NM: item.EMPLOYEE.LVL06_PRI_DISPLAY_NM,
        LVL07_PRI_DISPLAY_NM: item.EMPLOYEE.LVL07_PRI_DISPLAY_NM,
        LVL08_PRI_DISPLAY_NM: item.EMPLOYEE.LVL08_PRI_DISPLAY_NM,
        HRBP1_NAME: item.EMPLOYEE.HRBP1_NAME,
        HRBP2_NAME: item.EMPLOYEE.HRBP2_NAME
      });
    }

    const filteredGroupedEmployees = groupedEmployees.filter((employee) => { return employee.ACTIVITIES.length > 1 });

    setHistoricalProgramParticipation(filteredGroupedEmployees);

    const uniqueEmployees = new Set();

    filteredGroupedEmployees?.forEach((status) => {
      uniqueEmployees.add(status.PERSON_ID);
    });

    const historicalProgramParticipationPercentage = Math.round(uniqueEmployees.size / approvals.length * 100);

    setHistoricalProgramParticipationPercentage(historicalProgramParticipationPercentage);
  });
};


/**
 * Retrieve all data from approvals and nominations and transform them into a unified format.
 * 
 * @param {Object[]} approvals - The list of approved items.
 * @param {Object[]} nominations - The list of nominated items.
 * @param {Function} setAllData - A function to update the state with the transformed data.
 */
export const getAllData = (approvals, nominations, setAllData) => {
  const result = [];

  // Transform approved items
  approvals?.forEach((approval) => {
    result.push({
      STATUS: 'Approved',
      SUB_STATUS: utils.captureSubStatus(approval),
      ACTIVITY_NAME: approval.ACTIVITY.ACTIVITY_NAME,
      CF_CAREER_LEVEL_CD: approval.EMPLOYEE.CF_CAREER_LEVEL_CD,
      PRI_DISPLAY_NM: approval.EMPLOYEE.PRI_DISPLAY_NM,
      PERSON_ID: approval.EMPLOYEE.PERSON_ID,
      FIRST_HIRE_DT: approval.EMPLOYEE.FIRST_HIRE_DT,
      JOB_TITLE: approval.EMPLOYEE.JOB_TITLE,
      GENDER_CD: approval.EMPLOYEE.GENDER_CD,
      ETHNICITY: approval.EMPLOYEE.ETHNICITY,
      SUPERVISOR_PRI_DISPLAY_NM: approval.EMPLOYEE.SUPERVISOR_PRI_DISPLAY_NM,
      DEPARTMENT_GROUP_NM: approval.EMPLOYEE.DEPARTMENT_GROUP_NM,
      DEPARTMENT_FUNCTION_NM: approval.EMPLOYEE.DEPARTMENT_FUNCTION_NM,
      DEPARTMENT_BRAND_NM: approval.EMPLOYEE.DEPARTMENT_BRAND_NM,
      LVL03_PRI_DISPLAY_NM: approval.EMPLOYEE.LVL03_PRI_DISPLAY_NM,
      LVL04_PRI_DISPLAY_NM: approval.EMPLOYEE.LVL04_PRI_DISPLAY_NM,
      LVL05_PRI_DISPLAY_NM: approval.EMPLOYEE.LVL05_PRI_DISPLAY_NM,
      LVL06_PRI_DISPLAY_NM: approval.EMPLOYEE.LVL06_PRI_DISPLAY_NM,
      LVL07_PRI_DISPLAY_NM: approval.EMPLOYEE.LVL07_PRI_DISPLAY_NM,
      LVL08_PRI_DISPLAY_NM: approval.EMPLOYEE.LVL08_PRI_DISPLAY_NM,
      HRBP1_NAME: approval.EMPLOYEE.HRBP1_NAME,
      HRBP2_NAME: approval.EMPLOYEE.HRBP2_NAME
    });
  });

  // Transform nominated items
  nominations?.forEach((nomination) => {
    result.push({
      STATUS: 'Nominated',
      ACTIVITY_NAME: nomination.ACTIVITY.ACTIVITY_NAME,
      CF_CAREER_LEVEL_CD: nomination.EMPLOYEE.CF_CAREER_LEVEL_CD,
      PRI_DISPLAY_NM: nomination.EMPLOYEE.PRI_DISPLAY_NM,
      PERSON_ID: nomination.EMPLOYEE.PERSON_ID,
      FIRST_HIRE_DT: nomination.EMPLOYEE.FIRST_HIRE_DT,
      JOB_TITLE: nomination.EMPLOYEE.JOB_TITLE,
      GENDER_CD: nomination.EMPLOYEE.GENDER_CD,
      ETHNICITY: nomination.EMPLOYEE.ETHNICITY,
      SUPERVISOR_PRI_DISPLAY_NM: nomination.EMPLOYEE.SUPERVISOR_PRI_DISPLAY_NM,
      DEPARTMENT_GROUP_NM: nomination.EMPLOYEE.DEPARTMENT_GROUP_NM,
      DEPARTMENT_FUNCTION_NM: nomination.EMPLOYEE.DEPARTMENT_FUNCTION_NM,
      DEPARTMENT_BRAND_NM: nomination.EMPLOYEE.DEPARTMENT_BRAND_NM,
      LVL03_PRI_DISPLAY_NM: nomination.EMPLOYEE.LVL03_PRI_DISPLAY_NM,
      LVL04_PRI_DISPLAY_NM: nomination.EMPLOYEE.LVL04_PRI_DISPLAY_NM,
      LVL05_PRI_DISPLAY_NM: nomination.EMPLOYEE.LVL05_PRI_DISPLAY_NM,
      LVL06_PRI_DISPLAY_NM: nomination.EMPLOYEE.LVL06_PRI_DISPLAY_NM,
      LVL07_PRI_DISPLAY_NM: nomination.EMPLOYEE.LVL07_PRI_DISPLAY_NM,
      LVL08_PRI_DISPLAY_NM: nomination.EMPLOYEE.LVL08_PRI_DISPLAY_NM,
      HRBP1_NAME: nomination.EMPLOYEE.HRBP1_NAME,
      HRBP2_NAME: nomination.EMPLOYEE.HRBP2_NAME
    });
  });

  // Sort the result by primary display name
  result.sort((a, b) => a.PRI_DISPLAY_NM.localeCompare(b.PRI_DISPLAY_NM));

  // Update the state with the transformed data
  setAllData(result);
};