const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENTID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = window.location.origin + '/implicit/callback';

const oidc = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  }
};

export default oidc;