import {
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { axios } from "hooks/useAxiosInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { setSubmissionTrigger } from "stores/admin/adminSlice";
import utils from "utils/utils";

/**
 * Handles the creation and update of nomination cycles
 * @param {Object} props - Component props
 * @param {Object} props.initialState - Initial state for the form
 * @param {Function} props.onClose - Function to close the form
 * @returns {JSX.Element} NominationCycleForm component
 */
const NominationCycleForm = ({ initialState, onClose }) => {  
  const nominationCycleData = useSelector((state) => state.admin.nominationCycleData);
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();
  const formFlag = useSelector((state) => state.admin.formFlag);

  /**
   * Calculates the next available cycle number
   * @returns {number} Next available cycle number
   */
  const getNextUpCycleNumber = () => {
    if(nominationCycleData.length < 1) {
      return 1;
    }

    const cycleNumbers = nominationCycleData.map(obj => obj.CYCLE_NUMBER);
    const maxCycleNumber = Math.max(...cycleNumbers);
    
    return maxCycleNumber + 1;
  }

  /**
   * Handles form submission
   * @param {Object} values - Form values
   * @param {Object} actions - Formik actions
   */
  const handleSubmit = async (values, actions) => {
    try {
      if (formFlag === 'new') {
        await handleNewSubmission(values, actions);
      } else if (formFlag === 'update') {
        await handleUpdateSubmission(values, actions);
      }
    } catch (error) {
      console.error(error);
      handleSubmissionError();
    }
  };
  
  /**
   * Handles new submission
   * @param {Object} values - Form values
   * @param {Object} actions - Formik actions
   */
  const handleNewSubmission = async (values, actions) => {
    values.CYCLE_NUMBER = getNextUpCycleNumber();
    values.CREATED_BY = user.email;
    values.UPDATED_BY = user.email;
  
    const response = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/nominationCycle`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  /**
   * Handles update submission
   * @param {Object} values - Form values
   * @param {Object} actions - Formik actions
   */
  const handleUpdateSubmission = async (values, actions) => {
    values._id = initialState._id;
    values.UPDATED_BY = user.email;
  
    const response = await axios.put(`${process.env.REACT_APP_INVOKE_URL}/nominationCycle`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  /**
   * Handles submission success
   * @param {Object} values - Form values
   */
  const handleSubmissionSuccess = (values) => {
    toast({
      title: formFlag === 'new' ? 'Nomination Cycle Submitted' : 'Nomination Cycle Updated',
      description: formFlag === 'new' ? 'Nomination Cycle has been created.' : 'Nomination Cycle has been updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    onClose();
    dispatch(setSubmissionTrigger(values));
  };
  
  /**
   * Handles submission error
   */
  const handleSubmissionError = () => {
    toast({
      title: 'Internal Server Error',
      description: 'An error occurred while creating or updating this asset.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <Formik
      initialValues = {
        { 
          CYCLE_START_DATE: utils.formatDate(initialState?.CYCLE_START_DATE),
          CYCLE_LABEL: initialState?.CYCLE_LABEL || ''
        }
      }
      onSubmit={async (values, actions) => await handleSubmit(values, actions)}
    >
      {(props) => (
        <Form>
          <Field name='CYCLE_START_DATE'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Nomination Cycle Start Date</FormLabel>
                <Input type='date' {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='CYCLE_LABEL'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Nomination Cycle Label</FormLabel>
                <Input placeholder='e.g. Q1 2025' {...field}/>
              </FormControl>
            )}
          </Field>
          <Button
            mt={4}
            colorScheme='blue'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
};

export default NominationCycleForm;