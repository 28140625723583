import { Box, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <Box textAlign="center" pt={20}>
      <Heading as="h1" fontSize="2xl" mb={4}>Oops!</Heading>
      <Text fontSize="lg" color="gray.600" mb={4}>Sorry, an unexpected error has occurred.</Text>
      <Text fontSize="md" color="gray.600">
        <i>Looks like you don't have permissions to view this page. Try going back <Link to='/' style={{ textDecoration: 'underline'}}>home</Link>.</i>
      </Text>
    </Box>
  );
}
