import Option from "./Option";
import MultiValue from "./MultiValue";
import Select from "./Select";
import { Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";

/**
 * A component representing a single drop down menu for selecting filter options
 * @param {String} title - The title of the filter section
 * @param {String} type - The type of the filter section
 * @param {String} value - The value of the filter section
 * @param {String} handleChange - The handler that is called when the filter changes
 * @returns {JSX.Element} - Employee search filters component.
 */
const FilterSection = ({title, type, value, handleChange}) => {
  const [filterValue, setFilterValue] = useState([]);

  useEffect(() => {
    const filteredValues = 
      value?.filter((item) => item !== undefined)
      .map((item) => {
        return {
          value: item,
          label: item
        }
      });

      setFilterValue(filteredValues);
  }, [value]);

  return (
    <Flex pb={3} flexDir='column'>
      <Heading pb={1} size='xs'>{title}</Heading>
      <Select
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={true}
        components={{ Option, MultiValue }}
        onChange={(selected) => handleChange(selected, type)}
        allowSelectAll={false}
        value={filterValue}
        type={type}
      />
    </Flex>
  )
};

export default FilterSection;