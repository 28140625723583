import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Select,
  Textarea,
  Flex,
  Box,
  useToast,
} from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { Field, Form, Formik } from "formik";
import { axios } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { targetManagerLevelOptions } from "utils/managerLevelOptions";
import { setSubmissionTrigger } from "stores/admin/adminSlice";
import utils from "utils/utils";
import MultiSelect from "features/Admin/Select/MultiSelect";
import GroupSeatAllocation from "features/Admin/Forms/Activity/GroupSeatAllocation";

/**
 * @description This component renders the Activity Form for creating and updating activities.
 * @function ActivityForm
 * @returns {React.Component} The component that renders a the form for activity creation.
 */

const ActivityForm = ({ initialState, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [activityTypes, setActivityTypes] = useState([]);
  const [nominationCycles, setNominationCycles] = useState([]);
  const [allocationGroups, setAllocationGroups] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [fulfilledByOptions, setFulfilledByOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const activityData = useSelector((state) => state.admin.activityData);
  const formData = useSelector((state) => state.admin.formData);
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();
  const formFlag = useSelector((state) => state.admin.formFlag);

  useEffect(() => {
    if (!Array.isArray(activityData)) {
      throw new Error('Input data must be an array.');
    }

    const foundSelectedValues = [];
  
    // Transform the activity data to be in a form that can be used in the multi select
    const output = activityData
      .filter((item) => item._id !== formData?._id)
      .map((item) => {
        return {
          value: item._id,
          label: item.ACTIVITY_NAME,
        };
      });

    // Populate the FULFILLED_BY options with the available activities
    initialState?.FULFILLED_BY?.forEach((item) =>{
      const foundItem = output.find((activity) => {
        return activity.value === item;
      });

      foundSelectedValues.push(foundItem);
    });

    setSelectedValues(foundSelectedValues);
    setFulfilledByOptions(output);
  }, [activityData, formData, initialState]);

  useEffect(() => {
    const fetchActivityTypeData = async () => {
      try{
        setLoading(true);

        // Fetch the activity types for the select options
        const activityTypes = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/activityType/activityTypes`);
        setLoading(false);
        setActivityTypes(activityTypes?.data);
      } catch(error) {
        console.log(error);
      }
    }

    fetchActivityTypeData();
  }, []);

  useEffect(() => {
    const fetchAllocationGroupData = async () => {
      try{
        setLoading(true);

        // Fetch the allocation groups for the allocation select options.
        const allocationGroups = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup/allocationGroups`);
        setLoading(false);
        setAllocationGroups(allocationGroups?.data);
        
      } catch(error) {
        console.log(error);
      }
    }

    fetchAllocationGroupData();
  }, []);

  useEffect(() => {
    const fetchNominationCycleData = async () => {
      try{
        setLoading(true);

        // Fetch the nomination cycles for the nomination cycle select options
        const nominationCycles = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/nominationCycle/nominationCycles`);
        setNominationCycles(nominationCycles?.data);
      } catch(error) {
        console.log(error);
      }
    }

    fetchNominationCycleData();
  }, []);

  useEffect(() => {
    const output = [];

    // update the allocations to include the GROUP_NM from the allocation group
    initialState?.ALLOCATIONS?.forEach((allocation) => {
      const foundGroup = allocationGroups.find((group) => { return group._id === allocation.ALLOCATION_GROUP_ID });
      output.push({ ...allocation, GROUP_NM: foundGroup?.GROUP_NM });
    });

    setAllocations(output);
  }, [allocationGroups, initialState]);

  const handleUpdateAllocations = (allocations) => {
    setAllocations(allocations);
  }

  const handleSubmit = async (values, actions) => {
    try {
      // Format the textarea values into arrays of strings to be stored and retrieved in the DB
      values.ACTIVITY_KEY_LEARNINGS = utils.convertStringToArray(values.ACTIVITY_KEY_LEARNINGS);
      values.ACTIVITY_DETAILS = utils.convertStringToArray(values.ACTIVITY_DETAILS);
      values.ACTIVITY_TARGET_AUDIENCE = utils.convertStringToArray(values.ACTIVITY_TARGET_AUDIENCE);
      const allocationArr = allocations;

      // Check if action should update an existing activity or create a new one.
      if (formFlag === 'new') {
        console.log(allocationArr);
        await handleNewSubmission(values, allocationArr, actions);
      } else if (formFlag === 'update') {
        console.log(allocationArr);
        await handleUpdateSubmission(values, allocationArr, actions);
      }
    } catch (error) {
      console.error(error);
      handleSubmissionError();
    }
  };
  
  const handleNewSubmission = async (values, allocationArr, actions) => {
    values.CREATED_BY = user.email;
    values.UPDATED_BY = user.email;

    // Call the API to create a new activity
    const activityResponse = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/activity`, { data: values });

    // If the activity is created, create the allocations defined by the user
    if (activityResponse?.data) {
      const allocationPromises = allocationArr.map(async (allocation) => {
        return axios.post(`${process.env.REACT_APP_INVOKE_URL}/allocation`, { data: {...allocation, ACTIVITY_ID: activityResponse.data._id} });
      });

      // Wait for all of them to return before movin on
      await Promise.all(allocationPromises);
    }

    if (activityResponse.status === 200) {
        actions.setSubmitting(false);
        handleSubmissionSuccess(values);
    }
  };

  const handleUpdateSubmission = async (values, allocationArr, actions) => {
    values._id = initialState._id;
    values.UPDATED_BY = user.email;
  
    // Call the API to update an existing activity
    const response = await axios.put(`${process.env.REACT_APP_INVOKE_URL}/activity`, { data: values });

    // Update the allocations for that activity appropriately
    const allocationPromises = allocationArr.map(async (allocation) => {
      return axios.post(`${process.env.REACT_APP_INVOKE_URL}/allocation`, { data: {...allocation, ACTIVITY_ID: values._id} });
    });

    await Promise.all(allocationPromises);
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  // On success, show the success toast
  const handleSubmissionSuccess = (values) => {
    toast({
      title: formFlag === 'new' ? 'Program Submitted' : 'Program Updated',
      description: formFlag === 'new' ? 'Program has been created.' : 'Program has been updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    onClose();
    dispatch(setSubmissionTrigger(values));
  };
  
  // On error, show the error toast
  const handleSubmissionError = () => {
    toast({
      title: 'Internal Server Error',
      description: 'An error occurred while creating or updating this asset.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    onClose();
  };

  if(loading) {
    return <Loading/>
  }

  return (
    // Using Formik to handle the form generation and submission
    <Formik
      // Get the initial values if this is an existing activity 
      initialValues = {
        { 
          ACTIVITY_NAME: initialState?.ACTIVITY_NAME || '',
          ACTIVITY_NOMINATION_CYCLE: initialState?.ACTIVITY_NOMINATION_CYCLE || '',
          ACTIVITY_TYPE: initialState?.ACTIVITY_TYPE || '',
          ACTIVITY_DESC: initialState?.ACTIVITY_DESC || '',
          ACTIVITY_DURATION_DESC: initialState?.ACTIVITY_DURATION_DESC || '',
          ACTIVITY_KEY_LEARNINGS: initialState?.ACTIVITY_KEY_LEARNINGS?.join('\n\n') || [],
          ACTIVITY_DETAILS: initialState?.ACTIVITY_DETAILS?.join('\n\n') || [],
          ACTIVITY_TARGET_AUDIENCE: initialState?.ACTIVITY_TARGET_AUDIENCE?.join('\n\n') || [],
          ACTIVITY_TARGET_DEMO: initialState?.ACTIVITY_TARGET_DEMO || [],
          ACTIVITY_TARGET_GENDER: initialState?.ACTIVITY_TARGET_GENDER || [],
          ACTIVITY_TARGET_MANAGER_LEVEL: initialState?.ACTIVITY_TARGET_MANAGER_LEVEL || [],
          FULFILLED_BY: initialState?.FULFILLED_BY || [],
          ACTIVITY_ST_ID: initialState?.ACTIVITY_ST_ID || '',
          ACTIVITY_START_DT: utils.formatDate(initialState?.ACTIVITY_START_DT) || '',
          ACTIVITY_END_DT: utils.formatDate(initialState?.ACTIVITY_END_DT) || '',
          ACTIVE: initialState?.ACTIVE || false,
        }
      }
      
      onSubmit={async (values, actions) => await handleSubmit(values, actions)}
    >
      {(props) => (
        <Form>
          <Field name='ACTIVITY_NAME'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Program Name</FormLabel>
                <Input {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_NOMINATION_CYCLE'>
            {({ field }) => (
              <FormControl mb={5} isRequired>
                <FormLabel>Nomination Cycle</FormLabel>
                <Select {...field}>
                  <option value=''>-</option>
                  {
                    nominationCycles?.map((cycle) => (
                      <option key={cycle._id} value={cycle.CYCLE_NUMBER}>{cycle.CYCLE_NUMBER} - {cycle.CYCLE_LABEL}</option>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_TYPE'>
            {({ field }) => (
              <FormControl mb={5} isRequired>
                <FormLabel>Program Type</FormLabel>
                <Select {...field}>
                  <option value=''>-</option>
                  {
                    activityTypes?.map((type) => (
                      <option key={type._id} value={type._id}>{type.ACTIVITY_TYPE_NAME}</option>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_DESC'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Description</FormLabel>
                <Textarea h={150} {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_DURATION_DESC'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Duration Description</FormLabel>
                <Input {...field} placeholder="e.g. '5 month learning journey'"/>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_KEY_LEARNINGS'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel mb={0}>Key Learnings</FormLabel>
                <FormHelperText mt={0}>One detail per line</FormHelperText>
                <Box>
                  <Textarea h={150}
                    {...field}                   
                  />
                </Box>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_DETAILS'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel mb={0}>At a Glance</FormLabel>
                <FormHelperText mt={0}>One detail per line</FormHelperText>
                <Box>
                  <Textarea h={150}
                    {...field}                   
                  />
                </Box>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_TARGET_AUDIENCE'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel mb={0}>Target Audience</FormLabel>
                <FormHelperText mt={0}>One detail per line</FormHelperText>
                <Box>
                  <Textarea h={150}
                    {...field}                   
                  />
                </Box>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_TARGET_DEMO'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Target Demographic</FormLabel>
                <Flex direction='column'>
                  <Checkbox
                    {...field} 
                    value='POC'
                    isChecked={field.value.includes('POC')}
                  >
                    POC
                  </Checkbox>
                </Flex>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_TARGET_GENDER'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Target Gender</FormLabel>
                <Flex direction='column'>
                  <Checkbox
                    {...field} 
                    value='M'
                    isChecked={field.value.includes('M')}
                  >
                    Male
                  </Checkbox>
                  <Checkbox
                    {...field} 
                    value='F'
                    isChecked={field.value.includes('F')}
                  >
                    Female
                  </Checkbox>
                </Flex>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_TARGET_MANAGER_LEVEL'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Target Manager Level</FormLabel>
                <Flex direction='column'>
                  {
                    targetManagerLevelOptions.map((record) => (
                      <Checkbox
                       {...field} 
                       key={record.value} 
                       value={record.value}
                       isChecked={field.value.includes(record.value)}
                      >
                        {record.label}
                      </Checkbox>
                    ))
                  }
                </Flex>
              </FormControl>
            )}
          </Field>
          {
            utils.hasPermission(user, 'manage-allocations') 
            &&
            <FormControl mb={5}>
              <FormLabel>Allocations</FormLabel>
              <GroupSeatAllocation 
                allocationGroups={allocationGroups}
                allocations={allocations}
                onUpdateAllocations={handleUpdateAllocations}
              />
            </FormControl>
          }
          <Field name='FULFILLED_BY'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Fulfilled By</FormLabel>
                <MultiSelect
                  selectedValue={selectedValues}
                  onValueChange={(newValue) => {
                    setSelectedValues(newValue);
                    field.onChange({ target: { name: field.name, value: newValue.map(option => option.value) } });
                  }}
                  options={fulfilledByOptions}
                />
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_ST_ID'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel mb={0}>LMS Activity ID</FormLabel>
                <FormHelperText mt={0}>For LMS Integrations</FormHelperText>
                <Input {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_START_DT'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Start Date</FormLabel>
                <Input type='date' {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVITY_END_DT'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>End Date</FormLabel>
                <Input type='date' {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='ACTIVE'>
            {({ field }) => (
              <FormControl mb={5}>
                <Checkbox defaultChecked={field.value} {...field}>Active</Checkbox>
              </FormControl>
            )}
          </Field>
          <Button
            mt={4}
            colorScheme='blue'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
};

export default ActivityForm;