import { Checkbox } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { addSelectedAsset, removeSelectedAsset } from 'stores/admin/adminSlice';

const AdminTableCheckbox = ({ value }) => {
  const dispatch = useDispatch();

  const handleCheckBoxChange = (e) => {
    if(e.target.checked) {
      dispatch(addSelectedAsset(e.target.value));
    } else {
      dispatch(removeSelectedAsset(e.target.value));
    }
  }

  return (
    <Checkbox onChange={handleCheckBoxChange} value={JSON.stringify(value)}/>
  );
};

export default AdminTableCheckbox;