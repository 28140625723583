import { 
  Text,
  Card, 
  CardBody, 
  CardFooter,
  Divider,
  Button,
  ButtonGroup,
  Stack,
  CardHeader,
  Flex,
} from '@chakra-ui/react';
import EmployeeTags from 'features/Employee/EmployeeTags';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentEmployee } from 'stores/employeeSearch/employeeSearchSlice';

/**
 * Component representing an employee card. Clicking on the card or on the "More Info" button will open the EmployeeDrawer.
 * Users can nominate employees by clicking on the Nominate button on the card.
 * @param {Object} data - Employee data.
 * @param {Function} onNominate - Function to handle nomination.
 * @returns {JSX.Element} - Employee card component.
 */
const EmployeeCard = ({ data, onNominate }) => {
  const [loading, setLoading] = useState(false);
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const dispatch = useDispatch();
  const [statusExists, setStatusExists] = useState(false);

  useEffect(() => {
    // Check if employee has status for current activity
    setStatusExists(false);
    data?.STATUSES?.forEach((status) => {
      if(status.ACTIVITY_ID === currentActivity?._id) {
        setStatusExists(true);
      }
    })
  }, [data, currentActivity]);

  /**
   * Handles click event to show more information about the employee.
   * @param {Object} employee - Employee data.
   */
  const handleInfoClick = (employee) => {
    dispatch(setCurrentEmployee(employee));
  };

  /**
   * Handles click event to nominate the employee.
   * @param {Object} data - Employee data.
   */
  const handleNominateClick = (data) => {
    setLoading(true);
    onNominate(data);
  }

  // Render employee card
  return (
    <Card minW='250px' w='30%' ml={2} mr={2} mt={2} mb={3} boxShadow='md' border='1px solid' borderColor='gray.100'>
      <CardHeader onClick={() => handleInfoClick(data)}>
        <Flex flexDir='column'>
          {/* Display employee tags and name */}
          <EmployeeTags employeeData={data} activityData={currentActivity}/>
          <Text fontWeight={600} textAlign='center' color='blue.500' fontSize='md'>{data.PRI_DISPLAY_NM}</Text>
        </Flex>
      </CardHeader>
      <CardBody pt={0} onClick={() => handleInfoClick(data)}>
        <Stack>
          {/* Display department group name and job title */}
          <Text textAlign='center' fontSize='sm'>
            {data.DEPARTMENT_GROUP_NM?.toUpperCase()}
          </Text>
          <Text fontSize='sm' textAlign='center' color='blue.600'>
            {data.JOB_TITLE}
          </Text>
        </Stack>
      </CardBody>
      <Divider color='gray.100' />
      <CardFooter pt={2} pb={2}>
        <ButtonGroup w='100%' justifyContent='space-between'>
          {/* Render nominate button if status doesn't exist */}
          {
            !statusExists &&
            <Button 
              onClick={() => handleNominateClick(data)} 
              size='sm' 
              variant='solid' 
              colorScheme='blue'
              isLoading={loading}
              loadingText='Submitting'
            >
                Nominate
            </Button>
          }
          {/* Render more info button */}
          <Button size='sm' variant='ghost' colorScheme='blue' onClick={() => handleInfoClick(data)}>
            More Info
          </Button>
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};

export default EmployeeCard;
