/**
 * UserForm component renders a form for creating or updating a user.
 * It utilizes Formik for form management and Chakra UI components for styling.
 * The form includes fields for person ID, email, role, and allocation group owner.
 * Allocation group owner field displays a MultiSelect component.
 * Form submission is handled asynchronously using axios for API requests.
 * Upon successful submission, a toast notification is displayed.
 * It also provides an info icon to display additional information in a modal.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.initialState - Initial state for the form fields.
 * @param {Function} props.onClose - Callback function to close the form.
 * @returns {JSX.Element} - UserForm component JSX.
 */

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast
} from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { Field, Form, Formik } from "formik";
import { axios } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalIsOpen, setSubmissionTrigger } from "stores/admin/adminSlice";
import utils from "utils/utils";
import MultiSelect from "../Select/MultiSelect";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import ModalWrapper from "components/common/ModalWrapper";

const UserForm = ({ initialState, onClose }) => {  
  // State variables for managing loading state and selected values
  const [loading, setLoading] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [availableOwners, setAvailableOwners] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  
  // Redux hooks for accessing and updating user and form data
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const formFlag = useSelector((state) => state.admin.formFlag);
  const formData = useSelector((state) => state.admin.formData);
  const modalIsOpen = useSelector((state) => state.admin.modalIsOpen);

  // Additional hooks
  const toast = useToast();

  // useEffect to set selected values based on available owners and form data
  useEffect(() => {
    const foundSelectedValues = [];
  
    availableOwners?.forEach((item) => { 
      const foundOwner = formData?.ALLOCATION_GROUP_OWNER?.find((owner) => { return owner === item.value});

      if(foundOwner) {
        foundSelectedValues.push(item);
      }
    });

    setSelectedValues(foundSelectedValues);
  }, [availableOwners, formData]);

  // useEffect to fetch role data and available owners when component mounts
  useEffect(() => {
    const fetchRoleData = async () => {
      try{
        setLoading(true);
        const availableRoles = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/role/roles`);
        setLoading(false);
        setRoleData(availableRoles?.data);
      } catch(error) {
        console.log(error);
      }
    }

    fetchRoleData();
  }, []);

  useEffect(() => {
    const fetchAvailableOwners = async () => {
      try {
        setLoading(true);
        const availableOwners = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/role/availableOwners`);
        setLoading(false);

        const transformedOwnerData = utils.transformDataForMultiSelect(availableOwners?.data, 'EMAIL', 'EMAIL');
        setAvailableOwners(transformedOwnerData);
      } catch(error) {
        console.log(error);
      }
    }

    fetchAvailableOwners();
  }, []);

  const handleSubmit = async (values, actions) => {
    values.UPDATED_BY = user.email;

    try {
      if (formFlag === 'new') {
        await handleNewSubmission(values, actions);
      } else if (formFlag === 'update') {
        await handleUpdateSubmission(values, actions);
      }
    } catch (error) {
      console.error(error);
      handleSubmissionError();
    }
  };
  
  const handleNewSubmission = async (values, actions) => {
    values.CREATED_BY = user.email;
    const response = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/user`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  const handleUpdateSubmission = async (values, actions) => {
    values._id = initialState._id;
  
    const response = await axios.put(`${process.env.REACT_APP_INVOKE_URL}/user`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  const handleSubmissionSuccess = (values) => {
    toast({
      title: formFlag === 'new' ? 'User Submitted' : 'User Updated',
      description: formFlag === 'new' ? 'User has been created.' : 'User has been updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    onClose();
    dispatch(setSubmissionTrigger(values));
  };
  
  const handleSubmissionError = () => {
    toast({
      title: 'Internal Server Error',
      description: 'An error occurred while creating or updating this asset.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    onClose();
  };

  const handleOpenModal = () => {
    dispatch(setModalIsOpen(true));
  };
  
  const handleCloseModal = () => {
    dispatch(setModalIsOpen(false));
  };

  if(loading) {
    return <Loading/>
  }

  return (
    <Formik
      initialValues = {
        {
          PERSON_ID: initialState?.PERSON_ID || '',
          EMAIL: initialState?.EMAIL || '',
          ROLE_ID: initialState?.ROLE_ID || '',
          ALLOCATION_GROUP_OWNER: initialState?.ALLOCATION_GROUP_OWNER || []
        }
      }
      onSubmit={async (values, actions) => await handleSubmit(values, actions)}
    >
      {(props) => (
        <Form>
          <Field name='PERSON_ID'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Person ID (Employee ID)</FormLabel>
                <Input placeholder='e.g. 00123456' {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='EMAIL'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Email</FormLabel>
                <Input type="email" {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='ALLOCATION_GROUP_OWNER'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>
                  <Flex align='center'>
                    <Text>Allocation Group Owner </Text>
                    <InfoOutlineIcon cursor='pointer' ml={3} onClick={handleOpenModal} />
                  </Flex>
                </FormLabel>
                <MultiSelect
                  selectedValue={selectedValues}
                  onValueChange={(newValue) => {
                    setSelectedValues(newValue);
                    field.onChange({ target: { name: field.name, value: newValue.map(option => option.value) } });
                  }}
                  options={availableOwners}
                />
              </FormControl>
            )}
          </Field>
          <Field name='ROLE_ID'>
            {({ field }) => (
              <FormControl mb={5} isRequired>
                <FormLabel>Role</FormLabel>
                <Select {...field}>
                  <option value=''>-</option>
                  {
                    roleData?.map((role) => (
                      <option key={role._id} value={role._id}>{role.TITLE}</option>
                    ))
                  }
                </Select>
              </FormControl>
            )}
          </Field>
          <Button
            mt={4}
            colorScheme='blue'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Submit
          </Button>
          <ModalWrapper
            isOpen={modalIsOpen}
            header="Allocation Group Owner"
            body="Users with the 'View Allocation Employees' permission will see all employees in the allocation groups where this Allocation Group Owner is listed."
            onClose={handleCloseModal}
          />
        </Form>
      )}
    </Formik>
  )
};

export default UserForm;