import { 
  Flex, 
  Box, 
  Text
} from '@chakra-ui/react';
import ProfileMenu from 'components/common/ProfileMenu';
import { Link } from 'react-router-dom';

const Nav = ({ title }) => {
  return (
    <Flex 
      sx={{ position: 'sticky', top: '0', }} 
      zIndex={1} 
      alignItems="center" 
      justifyContent="space-between" 
      p={4} 
      bgColor='gray.100'
    >
      <Box>
        <Link to='/'>
          <Text fontSize="xl" fontWeight="bold">{title}</Text>
        </Link>
      </Box>
      <ProfileMenu/>
    </Flex>
  );
};

export default Nav;