import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'stores/user/userSlice';
import Loading from 'components/common/Loading';
import { Outlet } from 'react-router-dom';
import storage from 'utils/storage';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';

/**
 * Higher-order component for enforcing authentication before rendering protected routes.
 * Manages user authentication using Okta authentication library.
 * Retrieves user data from the backend API upon successful authentication.
 * Redirects to the login page if the user is not authenticated.
 * Renders the nested routes once authenticated.
 * 
 * @returns {JSX.Element} JSX element
 */
export const RequiredAuth = () => {
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const { axios } = useAxiosInterceptor();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    /**
     * Fetch user data upon successful authentication and store it in local storage.
     * If user data is already available, no action is taken.
     */
    const fetchData = async () => {
      try {
        if (authState && authState.isAuthenticated) {
          storage.setToken(authState.accessToken.accessToken);
          if (!storage.getEmail() || storage.getEmail() === 'undefined' || !user.email) {
            const userEmail = authState?.idToken?.claims?.email?.toLowerCase();
            const userData = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/user/${userEmail}`);
                        
            if (userData?.data?.length) {
              // Set data in local storage
              storage.setEmail(userData.data[0].EMAIL);
              storage.setManagerLevelOptions();
              dispatch(setUser(userData.data[0]));
            } else {
              throw Error('Invalid authorization type.');
            }
          }
        }
      } catch (error) {
        console.error('Cannot retreive user data.', error);
        storage.clearToken();
      }
    };

    fetchData();
  });

  useEffect(() => {
    /**
     * Redirect to the login page if the user is not authenticated.
     * Sets the original URI for redirection after successful authentication.
     */
    if (authState && !authState.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState])

  // Display loading spinner while authentication status is being determined
  if (!authState || !authState?.isAuthenticated) {
    return (<Loading />);
  }

  // Render the nested routes once authenticated
  return (<Outlet />);
}
