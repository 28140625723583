import { useState } from 'react';
import './css/flexibleTable.css';

const handleCellClick = (row, setChildComponentVisibility, setChildComponentData) => {
  if(setChildComponentVisibility) {
    setChildComponentVisibility(true)
  }
  if(setChildComponentData) {
    setChildComponentData(row)
  }
}

const renderCellContent = (column, row, columnToFieldMapping, setChildComponentVisibility, setChildComponentData) => {
  const field = columnToFieldMapping[column];

  const isStatusColumn = column === 'Status' || column === 'Sub Status' || column === 'Review Status' || column === 'Nomination Status';

  const isNameColumn = column === 'Employee Name';

  const statusClassMapping = {
    'Approved': 'approved-cell',
    'Nominated': 'nominated-cell',
    'Withdrawn': 'withdrawn-cell',
    'Removed': 'removed-cell',
    'Declined': 'declined-cell',
    'Completed': 'completed-cell',
    'Ready for Review': 'ready-cell',
    'Review In Progress': 'in-progress-cell',
    'Reviewed': 'reviewed-cell'
  };

  const statusClass = statusClassMapping[row[field]];

  return (
    <span onClick={() => handleCellClick(row, setChildComponentVisibility, setChildComponentData)} className={isStatusColumn ? `status-cell ${statusClass}` : isNameColumn ? `name-cell` : 'flexible-table-cell'}>
      {row[field]}
    </span>
  );
};

const TableRow = ({ enableCheckBoxes, row, columns, columnToFieldMapping, onSelectRow, setChildComponentVisibility, setChildComponentData }) => {
  // console.log(row)
  const handleCheckboxChange = () => {
    onSelectRow(row);
  };

  return (
    <tr>
      {enableCheckBoxes && (
        <td className='checkbox-cell'>
          <input type="checkbox" onChange={handleCheckboxChange} />
        </td>
      )}
      {columns.map((column, colIndex) => (
        <td key={colIndex}>
          {renderCellContent(column, row, columnToFieldMapping, setChildComponentVisibility, setChildComponentData)}
        </td>
      ))}
    </tr>
  );
};

const FlexibleTable = ({ selectedRows, setSelectedRows, enableCheckBoxes, tableRowsPerPage, data, columns, columnToFieldMapping, setChildComponentVisibility, setChildComponentData }) => {
  const rowsPerPage = tableRowsPerPage || 20;
  const [visibleRowCount, setVisibleRowCount] = useState(rowsPerPage);

  if (!data || data.length === 0 || !columns || columns.length === 0) {
    return <div>No data to display.</div>;
  }

  const handleShowMore = () => {
    setVisibleRowCount(visibleRowCount + rowsPerPage);
  };

  const handleSelectRow = (row) => {
    // Check if the row is already selected
    const isRowSelected = selectedRows.some((selectedRow) => selectedRow === row);

    // Update selected rows based on the current selection
    if (isRowSelected) {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  return (
    <>
      <div className="flexible-table-container">
        <div className="flexible-table-scroll-container">
          <table className="flexible-table">
            <thead>
              <tr>
                {enableCheckBoxes && <th></th>}
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                data.length
                &&
                data.slice(0, visibleRowCount).map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    enableCheckBoxes={enableCheckBoxes}
                    row={row}
                    columns={columns}
                    columnToFieldMapping={columnToFieldMapping}
                    onSelectRow={handleSelectRow}
                    setChildComponentVisibility={setChildComponentVisibility}
                    setChildComponentData={setChildComponentData}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      {visibleRowCount < data.length && (
        <button className="show-more-btn" onClick={handleShowMore}>
          Show More
        </button>
      )}
    </>
  );
};

export default FlexibleTable;
