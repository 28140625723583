import { 
  Button,
  DrawerFooter
} from '@chakra-ui/react';

const MainDrawerFooter = ({ onClose }) => {
  return (
    <DrawerFooter maxH={55} bgColor='gray.100'>
      <Button variant='outline' mr={3} colorScheme='blue' onClick={onClose}>
        Close
      </Button>
    </DrawerFooter>
  );
};

export default MainDrawerFooter;
