import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { axios } from "hooks/useAxiosInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { setSubmissionTrigger } from "stores/admin/adminSlice";
import utils from "utils/utils";
import MultiSelect from "../Select/MultiSelect";
import { useEffect, useState } from "react";
import Loading from "components/common/Loading";

/**
 * AllocationGroupForm Component
 * @param {Object} props - Component props
 * @param {Object} props.initialState - Initial state for the form
 * @param {Function} props.onClose - Function to close the form
 * @returns {JSX.Element} AllocationGroupForm component
 */
const AllocationGroupForm = ({ initialState, onClose }) => {  
  const [loading, setLoading] = useState(true);
  const [selectedLvl03Values, setSelectedLvl03Values] = useState([]);
  const [lvl03LeaderOptions, setLvl03LeaderOptions] = useState([]);
  const formData = useSelector((state) => state.admin.formData);
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();
  const formFlag = useSelector((state) => state.admin.formFlag);

  // Fetch form data for multi-select options
  useEffect(() => {
    setLoading(true);
    const fetchAllocationGroupFormData = async () => {
      try {
        const allocationGroupFormData = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup/allocationGroupFormData`);
        const lvl03 = allocationGroupFormData.data[0].LVL03.filter((field) => field.VALUE);
  
        setLvl03LeaderOptions(utils.transformDataForMultiSelect(lvl03, 'VALUE', 'VALUE'));
        setLoading(false);
      } catch(err) {
        console.error(err);
      }
    }
  
    fetchAllocationGroupFormData();
  }, [])

  // Set selected values for multi-select based on form data
  useEffect(() => {
    const foundSelectedValues = [];
  
    formData?.RULE_LVL03_NM.forEach((item) => { 
      foundSelectedValues.push({ value: item, label: item});
    });

    setSelectedLvl03Values(foundSelectedValues);
  }, [lvl03LeaderOptions, formData]);

  // Handle form submission
  const handleSubmit = async (values, actions) => {
    try {
      if(values.OWNERS?.length) {
        values.OWNERS = utils.convertStringToArrayWithLowerCaseValues(values.OWNERS);
      }

      if (formFlag === 'new') {
        await handleNewSubmission(values, actions);
      } else if (formFlag === 'update') {
        await handleUpdateSubmission(values, actions);
      }
    } catch (error) {
      console.error(error);
      handleSubmissionError();
    }
  };
  
  // Handle new submission
  const handleNewSubmission = async (values, actions) => {
    values.CREATED_BY = user.email;
    values.UPDATED_BY = user.email;
  
    const response = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  // Handle update submission
  const handleUpdateSubmission = async (values, actions) => {
    values._id = initialState._id;
    values.UPDATED_BY = user.email;
  
    const response = await axios.put(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  // Handle submission success
  const handleSubmissionSuccess = (values) => {
    toast({
      title: formFlag === 'new' ? 'Allocation Group Submitted' : 'Allocation Group Updated',
      description: formFlag === 'new' ? 'Allocation Group has been created.' : 'Allocation Group has been updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    onClose();
    dispatch(setSubmissionTrigger(values));
  };
  
  // Handle submission error
  const handleSubmissionError = () => {
    toast({
      title: 'Internal Server Error',
      description: 'An error occurred while creating or updating this asset.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    onClose();
  };

  // Show loading spinner while fetching data
  if(loading) {
    return <Loading/>
  }

  // Render the form
  return (
    <Formik
      initialValues = {
        {
          GROUP_NM: initialState?.GROUP_NM || '',
          GROUP_DESC: initialState?.GROUP_DESC || '',
          RULE_GROUP_NM: initialState?.RULE_GROUP_NM || [],
          RULE_FUCT_NM: initialState?.RULE_FUCT_NM || [],
          RULE_LVL03_NM: initialState?.RULE_LVL03_NM || [],
          EMPLOYEE_IDS: initialState?.EMPLOYEE_IDS || [],
          OWNERS: initialState?.OWNERS?.join('\n\n') || [],
          ACTIVE: initialState?.ACTIVE || false,
        }
      }
      onSubmit={async (values, actions) => await handleSubmit(values, actions)}
    >
      {(props) => (
        <Form>
          {/* Allocation Group Name */}
          <Field name='GROUP_NM'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Allocation Group Name</FormLabel>
                <Input type='text' {...field}/>
              </FormControl>
            )}
          </Field>

          {/* Description */}
          <Field name='GROUP_DESC'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Description</FormLabel>
                <Textarea h={150} {...field}/>
              </FormControl>
            )}
          </Field>

          {/* Owners */}
          <Field name='OWNERS'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel mb={0}>Owners</FormLabel>
                <FormHelperText mt={0}>One email address per line</FormHelperText>
                <Box>
                  <Textarea h={150} {...field} />
                </Box>
              </FormControl>
            )}
          </Field>

          {/* Level 03 Leader */}
          <Field name='RULE_LVL03_NM'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Level 03 Leader is...</FormLabel>
                <MultiSelect
                  selectedValue={selectedLvl03Values}
                  onValueChange={(newValue) => {
                    setSelectedLvl03Values(newValue);
                    field.onChange({ target: { name: field.name, value: newValue.map(option => option.value) } });
                  }}
                  options={lvl03LeaderOptions}
                />
              </FormControl>
            )}
          </Field>

          {/* Active Checkbox */}
          <Field name='ACTIVE'>
            {({ field }) => (
              <FormControl mb={5}>
                <Checkbox defaultChecked={field.value} {...field}>Active</Checkbox>
              </FormControl>
            )}
          </Field>

          {/* Submit Button */}
          <Button
            mt={4}
            colorScheme='blue'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
};

export default AllocationGroupForm;