import { Select } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils/utils';

// Mapping of status colors
const statusColorMap = {
  STATUS_NOMINATED: {bg: 'yellow.50', border: 'yellow.100', text: 'yellow.600', hover: 'yellow.100'},
  SUB_STATUS_COMPLETED: {bg: 'blue.50', border: 'blue.100', text: 'blue.500', hover: 'blue.100'},
  SUB_STATUS_DECLINED: {bg: 'purple.50', border: 'purple.100', text: 'purple.500', hover: 'purple.100'},
  SUB_STATUS_REMOVED: {bg: 'red.50', border: 'red.100', text: 'red.500', hover: 'red.100'},
  SUB_STATUS_WITHDRAWN: {bg: 'orange.50', border: 'orange.100', text: 'orange.500', hover: 'orange.100'},
  STATUS_APPROVED: {bg: 'green.50', border: 'green.100', text: 'green.500', hover: 'green.100'},
};

// Available status options
const statusOptions = [
  { value: 'SUB_STATUS_COMPLETED', label: 'Completed', statusKey: 'SUB_STATUS_COMPLETED' },
  { value: 'SUB_STATUS_DECLINED', label: 'Declined', statusKey: 'SUB_STATUS_DECLINED' },
  { value: 'STATUS_NOMINATED', label: 'Nominated', statusKey: 'STATUS_NOMINATED' },
  { value: 'SUB_STATUS_REMOVED', label: 'Removed', statusKey: 'SUB_STATUS_REMOVED' },
  { value: 'SUB_STATUS_WITHDRAWN', label: 'Withdrawn', statusKey: 'SUB_STATUS_WITHDRAWN' },
  { value: 'STATUS_APPROVED', label: 'Approved', statusKey: 'STATUS_APPROVED' },
];

/**
 * Gets the value of the status based on the data object.
 * @param {object} data - Data object containing status information.
 * @returns {string} - Value of the status.
 */
const getStatusValue = (data) => {
  const trueStatusKeys = Object.keys(data).filter((key) => data[key] === true);
  const placeholderOption = statusOptions.find((option) => trueStatusKeys.includes(option.statusKey));
  return placeholderOption ? placeholderOption.value : 'NO_STATUS';
};

/**
 * Renders a select input for choosing status options with dynamic color and styling based on the selected status.
 * @param {object} data - Data object containing status information.
 * @param {function} onSelectChange - Function to handle the selection change event.
 * @returns {JSX.Element} - Status select component.
 */
const StatusSelect = ({ data, onSelectChange }) => {
  const [selectColor, setSelectColor] = useState('');
  const [selectBorderColor, setSelectBorderColor] = useState('');
  const [selectTextColor, setSelectTextColor] = useState('');
  const [selectHoverColor, setSelectHoverColor] = useState('');
  const [statusSelectOptions, setStatusSelectOptions] = useState(statusOptions);
  const [selectedValue, setSelectedValue] = useState(getStatusValue(data));
  const enrollment = useSelector((state) => state.roster.enrollment);
  const currentAllocation = useSelector((state) => state.roster.currentAllocation);
  const user = useSelector((state) => state.auth.user);

  /**
   * Effect to change the select options based on the current value and allocation state.
   * If there are no seats available, the Approved and Completed options will not be available
   * If the user does not have the 'approve-employees' permission, the Approved and Completed options will not be available
   */
  useEffect(() => {
    const statusValue = getStatusValue(data);
    setSelectedValue(statusValue);
  
    let availableOptions = [];
  
    switch (statusValue) {
      case 'STATUS_NOMINATED':
        availableOptions = ['STATUS_NOMINATED'];
        if (utils.hasPermission(user, 'approve-employee') && enrollment < currentAllocation.SEAT_ALLOCATION) {
          availableOptions.push('STATUS_APPROVED');
          availableOptions.push('SUB_STATUS_COMPLETED');
        }
        if (utils.hasPermission(user, 'update-employee-substatus')) {
          availableOptions = availableOptions.concat(['SUB_STATUS_REMOVED', 'SUB_STATUS_DECLINED', 'SUB_STATUS_WITHDRAWN']);
        }
        break;
      case 'STATUS_APPROVED':
        availableOptions = [statusValue];
        if (utils.hasPermission(user, 'approve-employee')) {
          if(enrollment < currentAllocation.SEAT_ALLOCATION) {
            availableOptions.push('STATUS_APPROVED');
          }

          availableOptions.push('STATUS_NOMINATED');
        }
        if (utils.hasPermission(user, 'update-employee-substatus')) {
          availableOptions = availableOptions.concat(
            ['SUB_STATUS_REMOVED', 'SUB_STATUS_COMPLETED', 'SUB_STATUS_WITHDRAWN', 'SUB_STATUS_DECLINED']
          );
        }
        break;
      case 'SUB_STATUS_COMPLETED':
      case 'SUB_STATUS_REMOVED':
      case 'SUB_STATUS_WITHDRAWN':
      case 'SUB_STATUS_DECLINED':
        availableOptions = [statusValue];
        if (utils.hasPermission(user, 'approve-employee')) {
          if(enrollment < currentAllocation.SEAT_ALLOCATION) {
            availableOptions.push('STATUS_APPROVED');
          }
          
          availableOptions.push('STATUS_NOMINATED');
        }
        if (utils.hasPermission(user, 'update-employee-substatus')) {
          availableOptions = availableOptions.concat(
            enrollment < currentAllocation.SEAT_ALLOCATION ?
            ['SUB_STATUS_REMOVED', 'SUB_STATUS_COMPLETED', 'SUB_STATUS_WITHDRAWN', 'SUB_STATUS_DECLINED'] :
            ['SUB_STATUS_REMOVED', 'SUB_STATUS_DECLINED', 'SUB_STATUS_WITHDRAWN']
          );
        }
        break;
      default:
        availableOptions = statusOptions.map(option => option.value);
        break;
    }    
  
    setStatusSelectOptions(statusOptions.filter(option => availableOptions.includes(option.value)));
  }, [data, enrollment, currentAllocation]);
  
  // Effect to update select styles based on data changes
  useEffect(() => {
    setSelectColor(statusColorMap[getStatusValue(data)]?.bg);
    setSelectBorderColor(statusColorMap[getStatusValue(data)]?.border);
    setSelectTextColor(statusColorMap[getStatusValue(data)]?.text);
    setSelectHoverColor(statusColorMap[getStatusValue(data)]?.hover);
  }, [data]);

  // Handler for select change event
  const handleSelectChange = (e) => {
    setSelectColor(statusColorMap[e.target.value]?.bg);
    setSelectBorderColor(statusColorMap[e.target.value]?.border);
    setSelectTextColor(statusColorMap[e.target.value]?.text);
    setSelectHoverColor(statusColorMap[e.target.value]?.hover);

    onSelectChange(e.target.value);
  }

  return (
    <Select 
      width='120px' 
      variant='filled' 
      size='sm' 
      defaultValue={selectedValue} 
      color={selectTextColor} 
      borderColor={selectBorderColor} 
      borderRadius={100} 
      bgColor={selectColor} 
      onChange={handleSelectChange}
      _hover={{bgColor: selectHoverColor}}
    >
      {statusSelectOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default StatusSelect;
