import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast
} from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { Field, Form, Formik } from "formik";
import { axios } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSubmissionTrigger } from "stores/admin/adminSlice";
import utils from "utils/utils";
import MultiSelect from "../Select/MultiSelect";

/**
 * RoleForm component renders a form for creating or updating a role.
 * It utilizes Formik for form management and Chakra UI components for styling.
 * The form includes fields for role name, description, and permissions.
 * Permissions are displayed using a MultiSelect component.
 * Form submission is handled asynchronously using axios for API requests.
 * Upon successful submission, a toast notification is displayed.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.initialState - Initial state for the form fields.
 * @param {Function} props.onClose - Callback function to close the form.
 * @returns {JSX.Element} - RoleForm component JSX.
 */
const RoleForm = ({ initialState, onClose }) => {  
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();
  const formFlag = useSelector((state) => state.admin.formFlag);
  const formData = useSelector((state) => state.admin.formData);
  const [rolePermissionData, setRolePermissionData] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const foundSelectedValues = [];
  
    permissionOptions?.forEach((item) => { 
      const foundPermission = rolePermissionData.find((rolePermission) => { return rolePermission.PERMISSION_ID === item.value});
      if(foundPermission) {
        foundSelectedValues.push(item);
      }
    });

    setSelectedValues(foundSelectedValues);
  }, [rolePermissionData, formData, permissionOptions]);

  useEffect(() => {
    const fetchRolePermissionData = async () => {
      try{
        setLoading(true);
        const activityTypes = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/role/rolePermissions/${formData._id}`);
        setLoading(false);
        setRolePermissionData(activityTypes?.data);
      } catch(error) {
        console.log(error);
      }
    }
    
    if(formData) {
      fetchRolePermissionData();
    }
  }, [formData]);

  useEffect(() => {
    const fetchPermissionData = async () => {
      try{
        setLoading(true);
        const permissions = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/role/permissions`);
        setLoading(false);
        const transformedPermissions = utils.transformDataForMultiSelect(permissions?.data, '_id', 'TITLE');
        setPermissionOptions(transformedPermissions);
      } catch(error) {
        console.log(error);
      }
    }
  
    fetchPermissionData();
  }, []);

  const handleSubmit = async (values, actions) => {
    values.UPDATED_BY = user.email;
    values.PERMISSIONS = selectedValues.map((value) => {
      return value.value;
    });

    try {
      if (formFlag === 'new') {
        await handleNewSubmission(values, actions);
      } else if (formFlag === 'update') {
        await handleUpdateSubmission(values, actions);
      }
    } catch (error) {
      console.error(error);
      handleSubmissionError();
    }
  };
  
  const handleNewSubmission = async (values, actions) => {
    values.CREATED_BY = user.email;
    const response = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/role`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  const handleUpdateSubmission = async (values, actions) => {
    values._id = initialState._id;
  
    const response = await axios.put(`${process.env.REACT_APP_INVOKE_URL}/role`, { data: values });
  
    if (response.status === 200) {
      actions.setSubmitting(false);
      handleSubmissionSuccess(values);
    }
  };
  
  const handleSubmissionSuccess = (values) => {
    toast({
      title: formFlag === 'new' ? 'Role Submitted' : 'Role Updated',
      description: formFlag === 'new' ? 'Role has been created.' : 'Role has been updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    onClose();
    dispatch(setSubmissionTrigger(values));
  };
  
  const handleSubmissionError = () => {
    toast({
      title: 'Internal Server Error',
      description: 'An error occurred while creating or updating this asset.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    onClose();
  };

  if(loading) {
    return <Loading/>
  }

  return (
    <Formik
      initialValues = {
        {
          TITLE: initialState?.TITLE || '',
          DESC: initialState?.DESC || '',
          PERMISSIONS: []
        }
      }
      onSubmit={async (values, actions) => await handleSubmit(values, actions)}
    >
      {(props) => (
        <Form>
          <Field name='TITLE'>
            {({ field }) => (
              <FormControl isRequired mb={5}>
                <FormLabel>Role Name</FormLabel>
                <Input placeholder='e.g. Reporting Administrator' {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='DESC'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Description</FormLabel>
                <Textarea h={150} {...field}/>
              </FormControl>
            )}
          </Field>
          <Field name='PERMISSIONS'>
            {({ field }) => (
              <FormControl mb={5}>
                <FormLabel>Permissions</FormLabel>
                <MultiSelect
                  selectedValue={selectedValues}
                  onValueChange={(newValue) => {
                    setSelectedValues(newValue);
                    field.onChange({ target: { name: field.name, value: newValue.map(option => option.value) } });
                  }}
                  options={permissionOptions}
                />
              </FormControl>
            )}
          </Field>
          <Button
            mt={4}
            colorScheme='blue'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
};

export default RoleForm;