import {
  Flex,
  Heading,
  VStack,
  useToast,
} from '@chakra-ui/react'
import Loading from 'components/common/Loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'hooks/useAxiosInterceptor';
import SeatAllocationPanel from './SeatAllocationPanel';
import utils from 'utils/utils';

/**
 * Displays the allocation manager panel with seat allocation information.
 * @returns {JSX.Element} - Allocation manager panel component.
 */
const AllocationManagerPanel = () => {
  const [loading, setLoading] = useState(true);
  const [allocations, setAllocations] = useState([]);
  const [filteredAllocations, setFilteredAllocations] = useState([]);
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    /**
     * Fetches allocation data from the server.
     */
    const fetchData = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/allocation/allocationManager/${currentActivity._id}`);
        setLoading(false);
        setAllocations(data?.data);
      } catch(error) {
        console.log(error);
        toast({
          title: 'Unable to fetch data',
          description: error.response?.data?.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }

    if(currentActivity) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    /**
     * Filters allocations based on user permissions.
     * Users with the 'view-allocation-employees' permission will only see the allocations where their "AllocationGroupOwner" is listed in the OWNERS array of the allocation group associated with that allocation
     */
    const filterAllocations = () => {
      if(utils.hasPermission(user, 'view-allocation-employees')) {
        setFilteredAllocations(utils.filterAllocationsByOwner(allocations, user));
      } else if(utils.hasPermission(user, 'view-all-employees')) {
        setFilteredAllocations(allocations);
      }
    }
    
    filterAllocations();
  }, [allocations, user]);

  // Render loading component if data is still loading
  if(loading) {
    return (
      <Loading/>
    )
  }

  // Render allocation manager panel
  return (
    <Flex>
      <Flex flex={1} flexDir='column'>
        {
          filteredAllocations.map((allocation) => (
            <SeatAllocationPanel key={allocation._id} allocation={allocation}/>
          ))
        }
      </Flex>
      <VStack flex={1} mt={10}>
        <Heading size='xl'>{currentActivity?.SPARE_SEATS}</Heading>
        <Heading size='sm'>SPARE SEATS</Heading>
      </VStack>
    </Flex>
  );
};

export default AllocationManagerPanel;
