import { 
  Drawer, 
  DrawerOverlay, 
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

const InvalidForm = () => {
  return (
    <div>Invalid form</div>
  )
}

/**
 * AdminDrawer component renders a drawer for displaying forms.
 * It utilizes Chakra UI components for styling.
 * The drawer can display a custom form component based on the 'component' prop.
 * If 'component' is not provided or invalid, it renders an InvalidForm component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isOpen - Flag indicating whether the drawer is open.
 * @param {Function} props.onClose - Function to handle closing the drawer.
 * @param {JSX.Element} props.component - The form component to be rendered inside the drawer.
 * @param {Object} props.initialState - The initial state to be passed to the form component.
 * @returns {JSX.Element} - AdminDrawer component JSX.
 */
const AdminDrawer = ({ isOpen, onClose, component, initialState }) => {
  // Destructuring 'component' prop and assigning it to Form
  const Form = component;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>
          {/* Rendering the provided form component or InvalidForm component */}
          {component ? <Form onClose={onClose} initialState={initialState} /> : <InvalidForm />}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default AdminDrawer;

