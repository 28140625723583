import { Box, Tag } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

/**
 * Renders tags based on employee data and activity data.
 * @param {Object} employeeData - Data of the employee.
 * @param {Object} activityData - Data of the activity.
 * @returns {JSX.Element} - Employee tags component.
 */
const EmployeeTags = ({ employeeData, activityData }) => {
  // State variables to manage status, gender, and ethnicity tags
  const [statusTags, setStatusTags] = useState([]);
  const [genderTag, setGenderTag] = useState(null);
  const [ethnicityTag, setEthnicityTag] = useState(null);

  // Effect to update tags based on employee and activity data
  useEffect(() => {
    // Function to get status tags
    const getStatusTags = () => {
      if (employeeData?.STATUSES && activityData) {
        const tags = [];
        employeeData.STATUSES.forEach((status) => {
          if (status.ACTIVITY_ID === activityData._id) {
            if (status.STATUS_NOMINATED) {
              tags.push({ label: 'Nominated', colorScheme: 'orange' });
            }
            if (status.STATUS_APPROVED) {
              tags.push({ label: 'Approved', colorScheme: 'green' });
            }
          }
        });
        setStatusTags(tags);
      }
    };

    // Function to get gender tag
    const getGenderTag = () => {
      if (employeeData?.GENDER_CD) {
        const colorScheme = employeeData.GENDER_CD === 'M' ? 'red' : employeeData.GENDER_CD === 'F' ? 'cyan' : 'gray';
        setGenderTag({ label: employeeData.GENDER_CD === 'M' ? 'Male' : employeeData.GENDER_CD === 'F' ? 'Female' : employeeData.GENDER_CD, colorScheme });
      }
    };

    // Function to get ethnicity tag
    const getEthnicityTag = () => {
      if (employeeData?.ETHNICITY === 'POC') {
        setEthnicityTag({ label: employeeData.ETHNICITY, colorScheme: 'purple' });
      }
    };

    // Call functions to update tags
    getStatusTags();
    getGenderTag();
    getEthnicityTag();
  }, [employeeData, activityData]);

  // Render employee tags
  return (
    <Box mb={2}>
      {statusTags.map((tag, index) => (
        <Tag key={`statusTag_${index}`} mr={1} size="sm" colorScheme={tag.colorScheme}>
          {tag.label}
        </Tag>
      ))}
      {genderTag && (
        <Tag mr={1} size="sm" colorScheme={genderTag.colorScheme}>
          {genderTag.label}
        </Tag>
      )}
      {ethnicityTag && (
        <Tag size="sm" colorScheme={ethnicityTag.colorScheme}>
          {ethnicityTag.label}
        </Tag>
      )}
    </Box>
  );
};

export default EmployeeTags;

