import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import utils from 'utils/utils';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import storage from 'utils/storage';

/**
 * WarningMessage component displays warning messages based on various conditions.
 * The four conditions that are checked are MANAGER_LEVEL_CD, GENDER, ETHNICITY, and previous training history.
 * If the employee in the row does not match the recommended criteria for the activity, or if the employee has already completed a similar training in the past, the warning icon will populate and the warning message will be opened when clicked. 
 * @param {Object} props - The component props.
 * @param {boolean} props.showTargetDemoWarning - Whether to show the target demographic warning.
 * @param {boolean} props.showTargetGenderWarning - Whether to show the target gender warning.
 * @param {boolean} props.showTargetManagerLevelWarning - Whether to show the target manager level warning.
 * @param {boolean} props.showEquivalencyWarning - Whether to show the equivalency warning.
 * @param {Object} props.employee - The employee data.
 * @param {Object} props.activity - The activity data.
 * @returns {JSX.Element} WarningMessage component.
 */
const WarningMessage = ({ showTargetDemoWarning, showTargetGenderWarning, showTargetManagerLevelWarning, showEquivalencyWarning, employee, activity}) => {
  const activityData = useSelector((state) => state.activity.activityData); 
  const [fulfillments, setFulfillments] = useState([]);

  // Update the fulfillment data on when the activity data changes
  useEffect(() => {
    setFulfillments(utils.getFulfillmentsList(activityData, activity));
  }, [activityData, activity]);

  return (
    <>
    {
      showTargetManagerLevelWarning 
      &&
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Target Manager Levels</Th>
              <Th>Employee Manager Level</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td w='50%' fontSize='sm'> 
                {
                  activity?.ACTIVITY_TARGET_MANAGER_LEVEL.map((level) => (
                    <Flex key={level}>
                      <Text>{storage.getManagerLevelOption(level)}</Text>
                    </Flex>
                  ))
                } 
              </Td>
              <Td w='50%' fontSize='sm'>{storage.getManagerLevelOption(employee.MANAGER_LEVEL_CD)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    }
    {
      showTargetDemoWarning
      &&
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Target Demographic</Th>
              <Th>Employee Demographic</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td w='50%' fontSize='sm'> 
                {activity?.ACTIVITY_TARGET_DEMO} 
              </Td>
              <Td w='50%' fontSize='sm'>{employee?.ETHNICITY}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    }
    {
      showTargetGenderWarning
      &&
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Target Gender</Th>
              <Th>Employee Gender</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td w='50%' fontSize='sm'> 
                {activity?.ACTIVITY_TARGET_GENDER} 
              </Td>
              <Td w='50%' fontSize='sm'>{employee?.GENDER_CD}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    }
    {
      showEquivalencyWarning
      &&
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Equivalency</Th>
              <Th>Completion Date</Th>
            </Tr>
          </Thead>
          <Tbody>
          {
            fulfillments.map((fulfillment) => {
                return employee.STATUSES?.map((status) => {
                  if (status.SUB_STATUS_COMPLETED && fulfillment.ACTIVITY_ID === status.ACTIVITY_ID) {
                    return (
                      <Tr fontSize='sm' key={`${fulfillment.ACTIVITY_ID}-${status.ACTIVITY_ID}`}>
                        <Td w='50%'>{fulfillment.ACTIVITY_NAME}</Td>
                        <Td w='50%'>{utils.formatDate(status.updatedAt)}</Td>
                      </Tr>
                    );
                  }
                  // If the condition isn't met, return null or an empty fragment
                  return null;
                });
              })
            }           
          </Tbody>
        </Table>
      </TableContainer>
    }
    </>
  );
};

export default WarningMessage;
