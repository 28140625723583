/**
 * @description A mapping array to map the manager level codes to the labels. This data is stored in the brower session on login to be accessed later. 
 */
export const targetManagerLevelOptions = 
  [
    {
      value: '00',
      label: '00 - CEO/Executive Leadership Team'
    },
    {
      value: '10',
      label: '10 - EVP/SVP'
    },
    {
      value: '20',
      label: '20 - Vice President'
    },
    {
      value: '25',
      label: '25 - Assistant Vice President (AVP)'
    },
    {
      value: '30',
      label: '30 - Sr. Director'
    },
    {
      value: '40',
      label: '40 - Director'
    },
    {
      value: '50',
      label: '50 - Sr. Manager People Leader'
    },
    {
      value: '60',
      label: '60 - Manager People Leader'
    },
    {
      value: '65',
      label: '65 - Sr. Manager Non People Leader'
    },
    {
      value: '70',
      label: '70 - Manager Non People Leader'
    },
    {
      value: '80',
      label: '80 - Supervisor'
    },
    {
      value: '90',
      label: '90 - Individual Contributor'
    },
    {
      value: '99',
      label: '99 - Other'
    },
  ]