import { 
  DrawerBody, Flex, useToast
} from '@chakra-ui/react';
import Loading from 'components/common/Loading';
import { axios } from 'hooks/useAxiosInterceptor';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeCard from 'features/Tabs/EmployeeSearch/EmployeeCard';
import { setCurrentEmployee, setEmployeeData } from 'stores/employeeSearch/employeeSearchSlice';
import EmployeeDrawer from 'features/Employee/EmployeeDrawer';

/**
 * Component representing the Employee Search tab.
 * This is the parent component for all of the filters and employee card list.
 * Users with the 'view-allocation-employees' permission will see only the employees that are in the allocation groups that have the user's ALLOCATION_GROUP_OWNER listed in the OWNERS field. 
 * @returns {JSX.Element} - Employee search tab component.
 */
const EmployeeSearchTab = () => {
  const [loading, setLoading] = useState(true);
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const employeeData = useSelector((state) => state.employeeSearch.employeeData);
  const currentEmployee = useSelector((state) => state.employeeSearch.currentEmployee);
  const activeFilters = useSelector((state) => state.employeeSearch.filters);
  const searchKeyword = useSelector((state) => state.employeeSearch.searchKeyword);
  const user = useSelector((state) => state.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();

  /**
   * Fetches employee data based on active filters and search keyword.
   */
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true);
        const data = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/employee/employeeSearch`, { data: { filters: {...activeFilters, searchKeyword }, user }});
        setLoading(false);
        dispatch(setEmployeeData(data?.data));
      } catch(error) {
        toast({
          title: 'Unable to fetch employee data',
          description: error.response?.data?.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchEmployeeData();
  }, [activeFilters, toast, dispatch]);

  /**
   * Closes the employee drawer.
   */
  const handleCloseDrawer = () => {
    dispatch(setCurrentEmployee(null));
  };

  /**
   * Handles the nominate click event for an employee.
   * @param {Object} employee - Employee to be nominated.
   */
  const handleNominateClick = async (employee) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/status/nominateEmployees`, { employee, ACTIVITY_ID: currentActivity?._id, data: { user }});
      const upserted = response?.data?.upsertedCount;
      const statusObj = {};

      if (upserted) {
        statusObj.ACTIVITY_ID = currentActivity?._id;
        statusObj.STATUS_NOMINATED = true;
    
        // Create a deep copy of the employeeData array
        const employeeDataCopy = JSON.parse(JSON.stringify(employeeData));
    
        const employeeIndex = employeeDataCopy.findIndex(emp => emp._id === employee._id);
    
        if (employeeIndex !== -1) {
            employeeDataCopy[employeeIndex].STATUSES.push({
                ACTIVITY_ID: currentActivity?._id,
                STATUS_NOMINATED: true
            });
        }
    
        dispatch(setEmployeeData(employeeDataCopy));
      }

      handleCloseDrawer();

      toast({
        title: 'Nomination processed',
        description: `${employee?.PRI_DISPLAY_NM} has been nominated for ${currentActivity.ACTIVITY_NAME}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch(error) {
      console.error(error);
      toast({
        title: 'Unable to nominate this employee',
        description: error.response?.data?.error || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Render loading spinner while data is being fetched
  if(loading) {
    return (
      <Loading/>
    )
  }

  // Render employee search tab
  return (
    <DrawerBody w='100%' p={0}>
      <Flex flexWrap='wrap' justify='space-around'>
        {/* Render employee cards */}
        {
          employeeData?.map((employee) => (
            <EmployeeCard 
              onNominate={handleNominateClick} 
              key={employee._id} 
              data={employee}
            />
          ))
        }
      </Flex>
      {/* Render employee drawer */}
      <EmployeeDrawer 
        onNominate={handleNominateClick} 
        isOpen={currentEmployee !== null} 
        onClose={handleCloseDrawer}
      />
    </DrawerBody>
  );
};

export default EmployeeSearchTab;
