import React, { useState, useEffect, useCallback } from 'react';
import Async from 'react-select/async';

/**
 * A wrapper component for the react-select Async component. 
 * This Select will render a portion of a list of available options and allow the user to type to search for more available options
 * @param {Object} props - The props passed to this component
 * @param {String} props.type - The type of the filter. These types are determined by the employee data field that we are filtering on. 
 * @returns {JSX.Element} - Employee search filters component.
 */
const Select = (props) => {
  const [optionsArray, setOptionsArray] = useState([]);

  // Get the filter data from the session storage on render
  useEffect(() => {
    const getFilterData = async () => {
      try {
        // Check if filterData is already in session storage
        const cachedData = window.sessionStorage.getItem('filterData');

        if (cachedData && cachedData.length > 5) {
          const parsedData = JSON.parse(cachedData);
          setup(parsedData[0]);
        } else {
          throw new Error('Unable to find filter data');
        }
      } catch (err) {
        console.log(err);
      }
    };

    getFilterData();
  }, []);

  // Set up the select options to match the format needed for react-select
  const setup = useCallback((data) => {
    let optionsValues = data[props.type] || [];

    if (props.type === 'GENDER_CD') {
      optionsValues = ['M', 'F'];
    }

    const optionsValuesSet = [...new Set(optionsValues)].sort();
    const newOptionsArray = optionsValuesSet.map((item) => ({
      value: item,
      label: item,
      type: props.type,
    }));
    setOptionsArray(newOptionsArray);
  }, [props.type]);

  // Load the first 50 options into the Select
  const loadOptions = useCallback((inputValue, callback) => {
    const filteredOptions = optionsArray
      .filter((x) => x.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 50);
    callback(filteredOptions);
  }, [optionsArray]);

  // Use the Async component to load items asynchronously when the user types in the box. 
  return <Async 
    styles={{
      control: (baseStyles) => ({
        ...baseStyles,
        fontSize: '12px',
      })
    }} 
    loadOptions={loadOptions} 
    defaultOptions={optionsArray.slice(0, 100)} 
    {...props}
  />;
};

export default Select;