import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { useAxiosInterceptor } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState, setFormComponent, setFormData, setFormFlag, setUserData } from "stores/admin/adminSlice";
import AdminTableCheckbox from "./AdminTableCheckbox";

/**
 * AdminUserList component renders a table of users.
 * It utilizes Chakra UI components for styling.
 * The table includes columns for ID, Email, Person ID, and Role of each user.
 * It also provides a checkbox for each row and allows opening a drawer for editing a user.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.searchTerm - Search term for filtering users by email or person ID.
 * @param {boolean} props.hideInactive - Flag to hide inactive users.
 * @returns {JSX.Element} - AdminUserList component JSX.
 */
const AdminUserList = ({ searchTerm, hideInactive }) => {
  // State variables for managing loading state, user data, and filtered data
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { axios } = useAxiosInterceptor();

  // Redux hooks for accessing submission trigger and dispatching actions
  const submissionTrigger = useSelector((state) => state.admin.submissionTrigger);
  const dispatch = useDispatch();

  // useEffect to fetch user data and set form component
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/user/users`);
        setLoading(false);
        setData(data?.data);
        setFilteredData(data?.data);
        dispatch(setUserData(data?.data));
        dispatch(setFormComponent("UserForm"));
      } catch (error) {
        console.log(error);
        return <span>Error</span>;
      }
    };

    fetchData();
  }, [axios, submissionTrigger, dispatch]);

  // useEffect to filter data based on search term
  useEffect(() => {
    const filtered = data?.filter(record => {
      return (
        record?.EMAIL?.toLowerCase().includes(searchTerm) ||
        record?.PERSON_ID?.toLowerCase().includes(searchTerm)
      );
    });

    setFilteredData(filtered);
  }, [searchTerm, hideInactive, data]);

  // Function to handle opening drawer for editing a user
  const handleDrawerOpen = (e) => {
    dispatch(setDrawerState(true));
    dispatch(setFormFlag('update'));
    const _id = e.target.dataset?.value;

    if (_id) {
      const targetRecord = data.find((record) => {
        return record._id === _id;
      });
      dispatch(setFormData(targetRecord));
    }
  };

  // Render loading spinner if data is loading
  if (loading) {
    return <Loading />;
  }

  return (
    <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
      <Table variant='striped' size='sm'>
        <Thead bgColor='white'>
          <Tr>
            <Th></Th>
            <Th>ID</Th>
            <Th>Email</Th>
            <Th>Person ID</Th>
            <Th>Role</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapping through filtered data to render table rows */}
          {
            filteredData.map((row) => (
              <Tr key={row._id}>
                <Td>
                  {/* Checkbox component for selecting the row */}
                  <AdminTableCheckbox value={row} />
                </Td>
                {/* Cell for displaying ID */}
                <Td data-value={row._id} cursor='pointer' onClick={handleDrawerOpen} style={{textDecoration: 'underline'}} _hover={{color: 'blue.500'}}>{row._id}</Td>
                {/* Cell for displaying Email */}
                <Td>{row.EMAIL}</Td>
                {/* Cell for displaying Person ID */}
                <Td>{row.PERSON_ID}</Td>
                {/* Cell for displaying Role */}
                <Td>{row.ROLE}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AdminUserList;
