import { useState, useEffect } from 'react';
import { Stack, Flex, Heading } from '@chakra-ui/react';
import ActivitiesList from 'features/Activities/ActivitiesList';
import Nav from 'components/common/Nav';
import Loading from 'components/common/Loading';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';
import Search from 'components/common/Search';
import { useDispatch, useSelector } from 'react-redux';
import utils from 'utils/utils';
import { setActivityData } from 'stores/activity/activitySlice';

/**
 * ActivityPage component displays the list of activities and allows searching through them.
 *
 * @returns {JSX.Element} - ActivityPage component JSX.
 */
const ActivityPage = () => {
  const [filteredActivities, setFilteredActivities] = useState([]); // filtered down list of activities based on search criteria
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term in the search bar
  const currentActivity = useSelector((state) => state.activity.currentActivity); // The currently selected activity
  const activityData = useSelector((state) => state.activity.activityData); // All of the activity data captured from the API call
  const user = useSelector((state) => state.auth.user); // The user information
  const { axios } = useAxiosInterceptor(); // Axios handler
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * @description Fetches activity data and updates the state.
     * @async
     * @function fetchData
     */
    const fetchData = async () => {
      try{
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/activity/activities`);
        setLoading(false);
        dispatch(setActivityData(data.data));
        filterResults(searchTerm);
      } catch(error) {
        console.log(error);
        return <span>Error</span>
      }
    }

    if(currentActivity === null && utils.hasPermission(user, 'view-programs')) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [axios, currentActivity, user]);

  useEffect(() => {
    filterResults(searchTerm);
  }, [activityData]);

  /**
   * @description Handles search input changes and filters the results.
   * @function handleSearchChange
   * @param {Event} e - The input event.
   */
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterResults(e.target.value);
  }

  /**
   * @description Filters activity data based on the search term.
   * @function filterResults
   * @param {string} term - The search term.
   */
  const filterResults = (term) => {
    // Filter activity data based on search term
    const filtered = activityData?.filter(activity => {

      // Check if any field contains the search term
      return (
        activity.ACTIVE &&
        (
          activity.ACTIVITY_NAME?.toLowerCase().includes(term) ||
          activity.ACTIVITY_DESCRIPTION?.toLowerCase().includes(term) ||
          activity.ACTIVITY_DETAILS?.some(detail => detail.toLowerCase().includes(term)) ||
          activity.ACTIVITY_KEY_LEARNINGS?.some(learning => learning.toLowerCase().includes(term))
        )
      );
    });

    setFilteredActivities(filtered);
  }

  if(loading) {
    return (
      <Loading/>
    )
  }

  return (
    <Stack display='flex' justify='center'>
      <Nav title='NominateNow'/>
      <Flex w='100%' justify='space-between' align='center' p='0 30px' wrap='wrap'>
        <Heading size='md' m='5px'>Programs</Heading>
        <Search 
          onChange={handleSearchChange} 
          placeholder='Search for a program...'/>
      </Flex>
      <ActivitiesList activityData={filteredActivities}/>
    </Stack>
  );
};

export default ActivityPage;