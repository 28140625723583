import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentActivity: null,
  activityData: []
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setCurrentActivity(state, action) {
      state.currentActivity = action.payload
    },
    setActivityData(state, action) {
      state.activityData = action.payload
    }
  },
});

export const { setCurrentActivity, setActivityData } = activitySlice.actions;

export default activitySlice.reducer;
