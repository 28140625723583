import { 
  Box,
  HStack,
  Heading,
  Select,
  Stack,
  Text,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react';
import Loading from 'components/common/Loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'hooks/useAxiosInterceptor';
import { setAllocations, setCurrentAllocation } from 'stores/roster/rosterSlice';
import utils from 'utils/utils';

/**
 * AllocationInformation component displays information about allocations, nominations, enrollment, and capacity.
 * @returns {JSX.Element} AllocationInformation component.
 */
const AllocationInformation = () => {
  const [loading, setLoading] = useState(true);
  const [filteredAllocations, setFilteredAllocations] = useState(true);
  const stackDirection = useBreakpointValue({ base: "column", md: "row" });
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const allocations = useSelector((state) => state.roster.allocations);
  const currentAllocation = useSelector((state) => state.roster.currentAllocation);
  const nominations = useSelector((state) => state.roster.nominations);
  const enrollment = useSelector((state) => state.roster.enrollment);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // Fetch the allocation data for the current activity, if there is a current activity
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/allocation/allocations/${currentActivity._id}`);
        setLoading(false);
        const allocationData = data.data?.sort((a, b) => a.ALLOCATION_GROUP_NAME.localeCompare(b.ALLOCATION_GROUP_NAME));
  
        dispatch(setAllocations(allocationData));
      } catch(error) {
        console.log(error);
        return;
      }
    }
    
    if(currentActivity) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [currentActivity, dispatch]);

  // Depending on the user permissions, filter down the list of available allocations in the select
  useEffect(() => {
    if(utils.hasPermission(user, 'view-allocation-employees')) {
      const ownerAllocations = utils.filterAllocationsByOwner(allocations, user);

      setFilteredAllocations(ownerAllocations);
      dispatch(setCurrentAllocation(ownerAllocations[0] || {}));
    }

    if(utils.hasPermission(user, 'view-all-employees')) {
      setFilteredAllocations(allocations);
      dispatch(setCurrentAllocation(allocations[0] || {}));
    }
  }, [allocations]);

  // Set the current allocation to the option selected. This will only show the employees that are part of that allocation group.
  const handleAllocationGroupChange = (e) => {
    if(allocations) {
      const allocation = allocations.find((allocation) => (allocation._id === e.target.value));
      dispatch(setCurrentAllocation(allocation));
    }
  }

  if(loading) {
    return (
      <Box>
        <Loading/>
      </Box>
    )
  }

  // If there are no allocation available to select, show this text.
  if(!filteredAllocations?.length) {
    return (
      <Text fontSize='md'>No Allocations</Text>
    )
  }

  return (
    <Stack 
      spacing={5} 
      direction={stackDirection}
      justifyContent='flex-end' 
      alignItems='center'
    >
      {/* This component renders 3 text blocks for the three key fields: Nominations, Enrollment, and Capacity */}
      <HStack>
        <VStack spacing={1}>
          <Heading size='lg'>{nominations}</Heading>
          <Text textAlign='center' fontSize='xs'>NOMINATIONS</Text>
        </VStack>
        <VStack spacing={1}>
          <Heading size='lg'>{enrollment}</Heading>
          <Text textAlign='center' fontSize='xs'>ENROLLMENT</Text>
        </VStack>
        <VStack spacing={1}>
          <Heading size='lg'>{currentAllocation?.SEAT_ALLOCATION}</Heading>
          <Text textAlign='center' fontSize='xs'>CAPACITY</Text>
        </VStack>
      </HStack>
      {/* This is the select that users click on to choose the allocation group that they want to see. */}
      <Select minW='150px' onChange={handleAllocationGroupChange}>
        {filteredAllocations.map((allocation) => (
          <option key={allocation._id} value={allocation._id}>{allocation.ALLOCATION_GROUP_NAME}</option>
        ))}
      </Select>
    </Stack>
  );
};

export default AllocationInformation;
