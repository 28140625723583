import { Stack, Tabs, TabList, TabPanels, Tab } from '@chakra-ui/react';
import Nav from 'components/common/Nav';
import AdminPanel from 'features/Admin/AdminPanel';
import AdminActivityList from 'features/Admin/Tables/AdminActivityList';
import AdminAllocationGroupList from 'features/Admin/Tables/AllocationGroups/AdminAllocationGroupList';
import AdminNominationCycleList from 'features/Admin/Tables/AdminNominationCycleList';
import AdminRoleList from 'features/Admin/Tables/AdminRoleList';
import AdminUserList from 'features/Admin/Tables/AdminUserList';
import AdminDrawer from 'features/Admin/AdminDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerState, setFormData } from 'stores/admin/adminSlice';
import ActivityForm from 'features/Admin/Forms/Activity/ActivityForm';
import AllocationGroupForm from 'features/Admin/Forms/AllocationGroupForm';
import NominationCycleForm from 'features/Admin/Forms/NominationCycleForm';
import RoleForm from 'features/Admin/Forms/RoleForm';
import UserForm from 'features/Admin/Forms/UserForm';
import { useEffect, useState } from 'react';
import utils from 'utils/utils';
import ErrorPage from 'components/common/ErrorPage';
import Loading from 'components/common/Loading';

/**
 * AdminPage component is responsible for rendering the admin dashboard page.
 * It includes navigation tabs for different admin functionalities and a drawer for displaying forms.
 * The component fetches user data to determine permissions and manages loading state.
 *
 * @returns {JSX.Element} - AdminPage component JSX.
 */
const AdminPage = () => {  
  // State variables for loading state, drawer state, form component, form data, current tab index, and user data
  const [loading, setLoading] = useState(true);
  const drawerState = useSelector((state) => state.admin.drawerOpen);
  const formComponent = useSelector((state) => state.admin.formComponent);
  const formData = useSelector((state) => state.admin.formData);
  const user = useSelector((state) => state.auth.user);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  // Effect hook to manage loading state based on user data
  useEffect(() => {
    if (!user.roleId) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [user]);

  // Function to handle closing the drawer
  const handleCloseDrawer = () => {
    dispatch(setDrawerState(false));
    dispatch(setFormData(null));
  };

  // Mapping of form components based on form component name
  const componentMap = {
    ActivityForm: ActivityForm,
    AllocationGroupForm: AllocationGroupForm,
    NominationCycleForm: NominationCycleForm,
    RoleForm: RoleForm,
    UserForm: UserForm,
  };

  // Component to render based on the form component name
  const ComponentToRender = componentMap[formComponent];

  // Render loading spinner if loading
  if (loading) {
    return <Loading />;
  }

  // Render error page if user does not have permission to view admin portal
  if (!utils.hasPermission(user, 'view-admin-portal')) {
    return <ErrorPage />;
  }

  return (
    <Stack display='flex' justify='start'>
      {/* Navigation component */}
      <Nav title='NominateNow' />
      {/* Tabs for different admin functionalities */}
      <Tabs
        onChange={(index) => setTabIndex(index)}
        display='flex'
        flexDir='column'
        height='100%'
        variant='enclosed'
        colorScheme='blue'
        isLazy
        isManual
      >
        <TabList>
          {utils.hasPermission(user, 'manage-programs') && <Tab>Programs</Tab>}
          {utils.hasPermission(user, 'manage-allocation-groups') && <Tab>Allocation Groups</Tab>}
          {utils.hasPermission(user, 'manage-nomination-cycles') && <Tab>Nomination Cycles</Tab>}
          {utils.hasPermission(user, 'manage-roles') && <Tab>Roles</Tab>}
          {utils.hasPermission(user, 'manage-users') && <Tab>Users</Tab>}
        </TabList>
        {/* Panels for each admin functionality */}
        <TabPanels>
          <AdminPanel tabIndex={tabIndex} component={AdminActivityList} />
          <AdminPanel tabIndex={tabIndex} component={AdminAllocationGroupList} />
          <AdminPanel tabIndex={tabIndex} component={AdminNominationCycleList} />
          <AdminPanel tabIndex={tabIndex} component={AdminRoleList} />
          <AdminPanel tabIndex={tabIndex} component={AdminUserList} />
        </TabPanels>
      </Tabs>
      {/* Drawer for displaying forms */}
      <AdminDrawer isOpen={drawerState} onClose={handleCloseDrawer} component={ComponentToRender} initialState={formData} />
    </Stack>
  );
};

export default AdminPage;
