import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'stores/user/userSlice';
import activityReducer from 'stores/activity/activitySlice';
import adminReducer from 'stores/admin/adminSlice';
import employeeSearchReducer from 'stores/employeeSearch/employeeSearchSlice';
import rosterReducer from 'stores/roster/rosterSlice';
import tabReducer from 'stores/tabs/tabSlice';
import reportingReducer from 'stores/reporting/reportingSlice';

const store = configureStore({
  reducer: {
    auth: userReducer,
    activity: activityReducer,
    admin: adminReducer,
    employeeSearch: employeeSearchReducer,
    roster: rosterReducer,
    tab: tabReducer,
    reporting: reportingReducer
  },
});

export default store;
