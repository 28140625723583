import { DrawerBody, Text } from '@chakra-ui/react';
import MainDrawerContentBox from 'features/MainDrawer/MainDrawerContentBox';
import { useSelector } from 'react-redux';

/**
 * ProgramInformationTab component displays program information in a drawer tab.
 * @returns {JSX.Element} ProgramInformationTab component.
 */
const ProgramInformationTab = () => {
  const currentActivity = useSelector((state) => state.activity.currentActivity);

  return (
    <DrawerBody p={0}>
      {/* Display activity description */}
      <Text>{currentActivity?.ACTIVITY_DESC}</Text>
      {/* Display AT A GLANCE section if details exist */}
      {currentActivity?.ACTIVITY_DETAILS.length > 0 && (
        <MainDrawerContentBox title='AT A GLANCE' subtitle={currentActivity.ACTIVITY_DURATION_DESC} list={currentActivity?.ACTIVITY_DETAILS}/>
      )}
      {/* Display KEY LEARNINGS section if key learnings exist */}
      {currentActivity?.ACTIVITY_KEY_LEARNINGS.length > 0 && (
        <MainDrawerContentBox title='KEY LEARNINGS' list={currentActivity.ACTIVITY_KEY_LEARNINGS}/>
      )}
      {/* Display TARGET AUDIENCE section if target audience details exist */}
      {currentActivity?.ACTIVITY_TARGET_AUDIENCE.length > 0 && (
        <MainDrawerContentBox title='TARGET AUDIENCE' list={currentActivity.ACTIVITY_TARGET_AUDIENCE}/>
      )}
    </DrawerBody>
  );
};

export default ProgramInformationTab;
