import { targetManagerLevelOptions } from 'utils/managerLevelOptions';
const storagePrefix = 'nominate_now_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getEmail: () => {
    return window.localStorage.getItem(`${storagePrefix}email`);
  },
  setEmail: (email) => {
    window.localStorage.setItem(`${storagePrefix}email`, JSON.stringify(email));
  },
  clearEmail: () => {
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
  getManagerLevelOption: (value) => {
    return window.localStorage.getItem(`manager_level_${value}`);
  },
  setManagerLevelOptions: () => {
    targetManagerLevelOptions.forEach((item) => {
      window.localStorage.setItem(`manager_level_${item.value}`, item.label);
    });
  },
  clearManagerLevelOptions: () => {
    targetManagerLevelOptions.forEach((item) => {
      window.localStorage.removeItem(`manager_level_${item.value}`);
    });
  }
};

export default storage;