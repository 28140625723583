import { useOktaAuth } from '@okta/okta-react';
import storage from 'utils/storage';

const useOktaLogout = () => {
  const { oktaAuth } = useOktaAuth();

  // Clear the session information and logout the user.
  const logoutUser = async () => {
    try {
      await oktaAuth.signOut();
      storage.clearToken();
      storage.clearEmail()
      storage.clearManagerLevelOptions();
    } catch(error) {
      console.error('There was an issue during log out.', error);
    }
  };

  return logoutUser;
};

export default useOktaLogout;
