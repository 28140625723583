import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons';

const InfoIcon = ({ popupData }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton 
          variant='ghost' 
          aria-label='Get Info' 
          icon={<InfoOutlineIcon />} 
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{popupData}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default InfoIcon;
