import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  useToast,
} from '@chakra-ui/react'
import Loading from 'components/common/Loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'hooks/useAxiosInterceptor';
import { setRosterData } from 'stores/roster/rosterSlice';
import ModalWrapper from 'components/common/ModalWrapper';
import EmployeeRow from './EmployeeRow';
import utils from 'utils/utils';

/**
 * RosterTable component displays the roster data in a table format with various columns and functionalities.
 * @param {Object[]} filteredRosterData - Filtered roster data to be displayed in the table.
 * @returns {JSX.Element} RosterTable component.
 */
const RosterTable = ({ filteredRosterData }) => {
  const [loading, setLoading] = useState(true);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const dispatch = useDispatch();
  const toast = useToast();
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const rosterData = useSelector((state) => state.roster.rosterData);

  // Fetch the status for the current activity. Sort them by the name found in the object.
  useEffect(() => {
    const fetchData = async () => {
      try{
        const data = await axios.post(`${process.env.REACT_APP_INVOKE_URL}/status/fetchStatuses`,  { ACTIVITY_ID: currentActivity._id });
        setLoading(false);
        const sortedResults = data.data?.sort((a, b) => a?.PRI_DISPLAY_NM.localeCompare(b?.PRI_DISPLAY_NM));
        const transformedResponse = utils.transformStatusRecords(sortedResults, currentActivity);
        dispatch(setRosterData(transformedResponse));
      } catch(error) {
        console.error(error);
        return;
      }
    }
    
    if(currentActivity) {
      fetchData();
    }
  }, [currentActivity, dispatch])

  /**
   * Handles the deletion of a status record.
   * @param {Object} data - The row data to be deleted.
   */
  const handleStatusDelete = async (data) => {
    try {
      handleCloseDeleteModal();
      await axios.delete(`${process.env.REACT_APP_INVOKE_URL}/status`, { data: {_id: data?.ACTIVE_STATUS._id} });

      // Update the roster by filtering out the deleted status.
      const updatedRoster = rosterData.filter((record) => (record?.ACTIVE_STATUS.PERSON_ID !== data._id));

      dispatch(setRosterData(updatedRoster));
      
      toast({
        title: `Status for ${data?.PRI_DISPLAY_NM || 'employee'} has been removed`,
        description: 'Your deletion request was processed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch(error) {
      console.error(error);
      toast({
        title: `Unable to delete status`,
        description: error?.message || 'An unknown error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Opens the delete modal and sets the selected row.
   * @param {Object} row - The row data.
   */
  const handleOpenDeleteModal = (row) => {
    setSelectedRow(row);
    setDeleteModalIsOpen(true);
  };
  
  /**
   * Closes the delete modal.
   */
  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  if(loading) {
    return (
      <Loading/>
    )
  }

  return (
    <>
      <ModalWrapper
        isConfirm
        isOpen={deleteModalIsOpen}
        header="Delete this status record?"
        body="Deleting this status record will remove this employee's status from this program."
        onClose={handleCloseDeleteModal}
        onConfirm={() => handleStatusDelete(selectedRow)}
      />
      <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} h='100%' overflowY='auto'>
        <Table variant='striped' size='sm'>
          <Thead bgColor='white'>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th>Status</Th>
              <Th>Employee ID</Th>
              <Th>Name</Th>
              <Th>Job Title</Th>
              <Th>Gender</Th>
              <Th>Ethnicity</Th>
              <Th>Function</Th>
              <Th>Sol. Group</Th>
              <Th>LVL 03 Leader</Th>
              <Th>Career Level Code</Th>
              <Th>Manager Level Code</Th>
            </Tr>
          </Thead>
          {/* Map over the filtered roster data to generate a row for each status record */}
          <Tbody>
            {
              filteredRosterData.map((row) => (
                <EmployeeRow key={row._id} row={row} onRowDelete={handleOpenDeleteModal}/>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RosterTable;
