import { GridItem, useToast } from '@chakra-ui/react';
import Filters from './Filters';
import { useEffect } from 'react';
import { axios } from 'hooks/useAxiosInterceptor';

/**
 * Retrieves filter data from session storage.
 * @returns {Object|null} - Filter data retrieved from session storage, or null if not found.
 */
const fetchDataFromSessionStorage = () => {
  const filterDataFromStorage = sessionStorage.getItem('filterData');
  if (filterDataFromStorage) {
    return JSON.parse(filterDataFromStorage);
  }
  return null;
};

/**
 * Stores filter data in session storage.
 * @param {Object} data - Filter data to be stored.
 */
const storeDataInSessionStorage = (data) => {
  sessionStorage.setItem('filterData', JSON.stringify(data));
};

/**
 * Component for displaying employee search filters.
 * The filter data is captured from the session storage and includes most of the data points found on the employee records. 
 * @returns {JSX.Element} - Employee search filters component.
 */
const EmployeeSearchFilters = () => {
  const toast = useToast();

  useEffect(() => {
    /**
     * Fetches filter data from the server.
     */
    const fetchFilterData = async () => {
      try {
        // Check sessionStorage for existing data
        const cachedFilterData = fetchDataFromSessionStorage();
        if (cachedFilterData) {
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/employee/filterData`);
        const filterData = response.data;

        storeDataInSessionStorage(filterData);
      } catch (error) {
        toast({
          title: 'Unable to fetch filter data',
          description: error.response?.data?.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
    
    fetchFilterData();
  }, [toast]);

  // Render filters grid item
  return (
    <GridItem h='100%' pl={2} bg='white' area={'filters'} overflowY='auto' minW={230}>
      <Filters/>
    </GridItem>
  );
};

export default EmployeeSearchFilters;
