import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabIndex: 0
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setTabIndex(state, action) {
      state.tabIndex = action.payload
    }
  },
});

export const { setTabIndex } = tabSlice.actions;

export default tabSlice.reducer;
