import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { useAxiosInterceptor } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState, setFormComponent, setFormData, setFormFlag, setAllocationGroupData } from "stores/admin/adminSlice";
import AdminTableCheckbox from "../AdminTableCheckbox";
import AllocationGroupRefresh from "./AllocationGroupRefresh";

/**
 * AdminAllocationGroupList component renders a table of allocation groups.
 * It utilizes Chakra UI components for styling.
 * The table includes columns for ID, Active status, Name, Description, and Employee Count.
 * It also provides a checkbox for each row and allows opening a drawer for editing an allocation group.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.searchTerm - Search term for filtering allocation groups by name.
 * @param {boolean} props.hideInactive - Flag to hide inactive allocation groups.
 * @returns {JSX.Element} - AdminAllocationGroupList component JSX.
 */
const AdminAllocationGroupList = ({ searchTerm, hideInactive }) => {
  // State variables for managing loading state and data
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Redux hooks for accessing submission trigger and dispatching actions
  const submissionTrigger = useSelector((state) => state.admin.submissionTrigger);
  const allocationGroupData = useSelector((state) => state.admin.allocationGroupData);
  const dispatch = useDispatch();

  // Axios instance from useAxiosInterceptor custom hook
  const { axios } = useAxiosInterceptor();

  // useEffect to fetch allocation group data and set form component
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup/allocationGroups`);
        setLoading(false);

        const sortedData = data?.data.sort((a, b) => a.GROUP_NM.localeCompare(b.GROUP_NM));

        setData(sortedData);
        setFilteredData(sortedData);
        dispatch(setAllocationGroupData(sortedData));
        dispatch(setFormComponent("AllocationGroupForm"));
      } catch (error) {
        console.log(error);
        return <span>Error</span>;
      }
    };

    fetchData();
  }, [axios, submissionTrigger, dispatch]);

  // useEffect to filter data based on search term and active status
  useEffect(() => {
    const filtered = allocationGroupData?.filter(record => {
      return (
        (record?.ACTIVE || record?.ACTIVE === hideInactive) &&
        (record?.GROUP_NM?.toLowerCase().includes(searchTerm))
      );
    });

    setFilteredData(filtered);
  }, [searchTerm, hideInactive, allocationGroupData]);

  // Function to handle opening drawer for editing an allocation group
  const handleDrawerOpen = (e) => {
    dispatch(setDrawerState(true));
    dispatch(setFormFlag('update'));
    const _id = e.target.dataset?.value;

    if (_id) {
      const targetRecord = data.find((record) => {
        return record._id === _id;
      });
      dispatch(setFormData(targetRecord));
    }
  };

  // Render loading spinner if data is loading
  if (loading) {
    return <Loading />;
  }

  return (
    <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
      <Table variant='striped' size='sm'>
        <Thead bgColor='white'>
          <Tr>
            <Th></Th>
            <Th>ID</Th>
            <Th>Active</Th>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Employee Count</Th>
            <Th>Refresh</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapping through filtered data to render table rows */}
          {
            filteredData.map((row) => (
              <Tr key={row._id}>
                <Td>
                  {/* Checkbox component for selecting the row */}
                  <AdminTableCheckbox value={row} />
                </Td>
                {/* Cell for displaying ID */}
                <Td data-value={row._id} cursor='pointer' onClick={handleDrawerOpen} style={{textDecoration: 'underline'}} _hover={{color: 'blue.500'}}>{row._id}</Td>
                {/* Cell for displaying Active status */}
                <Td>{row.ACTIVE ? 'Active' : 'Inactive'}</Td>
                {/* Cell for displaying Name */}
                <Td>{row.GROUP_NM}</Td>
                {/* Cell for displaying Description */}
                <Td>{row.GROUP_DESC}</Td>
                {/* Cell for displaying Employee Count */}
                <Td>{row.EMPLOYEE_IDS.length}</Td>
                {/* Cell for displaying the refresh button */}
                <Td textAlign='center'>
                  <AllocationGroupRefresh data={row}/>
                </Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AdminAllocationGroupList;
