import { 
  Drawer, 
  DrawerOverlay, 
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  DrawerFooter,
  Button,
  Flex,
  Grid,
  GridItem,
  Box,
  VStack,
  Td,
  Tr,
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  useToast,
  Tag,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import EmployeeTags from './EmployeeTags';
import utils from 'utils/utils';
import { useEffect, useState } from 'react';
import { axios } from 'hooks/useAxiosInterceptor';
import Loading from 'components/common/Loading';

/**
 * EmployeeDrawer component renders a drawer to show additional employee information when an employee's name is clicked.
 * It utilizes Chakra UI components for styling.
 * The drawer includes information about the employee's role and manager hierarchy as well as their previous nominations and approvals and traning history.
 * It also provides a button at the bottom to nominate the employee directly from here.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.isOpen - Flag to determine if the drawer should be open or closed
 * @param {boolean} props.onClose - Handler to run when the cancel button is clicked within the drawer
 *  * @param {boolean} props.onNominate - Handler to run when the Nominate button is clicked within the drawer
 * @returns {JSX.Element} - EmployeeDrawer component JSX.
 */

const EmployeeDrawer = ({ isOpen, onClose, onNominate }) => {
  // State variables to manage loading status and data
  const [loadingStatuses, setLoadingStatuses] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);

  // Redux selectors to access current employee and activity data
  const currentEmployee = useSelector((state) => state.employeeSearch.currentEmployee);
  const currentActivity = useSelector((state) => state.activity.currentActivity);

  // State variables to manage status existence, statuses, and training history
  const [statusExists, setStatusExists] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [trainingHistory, setTrainingHistory] = useState([]);

  // Toast for displaying error messages
  const toast = useToast();

  // Effect to check if the current employee has statuses related to the current activity
  useEffect(() => {
    setStatusExists(false);
    currentEmployee?.STATUSES?.forEach((status) => {
      if(status.ACTIVITY_ID === currentActivity._id) {
        setStatusExists(true);
      }
    })
  }, [currentEmployee, currentActivity]);

  // Effect to fetch employee statuses
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        setLoadingStatuses(true);
        const employeeStatuses = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/status/employeeStatuses/${currentEmployee?._id}`);
        setStatuses(employeeStatuses?.data);
        setLoadingStatuses(false);
      } catch(error) {
        // Display toast with error message if fetching statuses fails
        toast({
          title: 'Unable to fetch status data',
          description: error.response?.data?.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
    
    if(currentEmployee) {
      fetchStatuses();
    }
  }, [currentEmployee, toast]);

  // Effect to fetch employee training history
  useEffect(() => {
    const fetchEmployeeTrainingHistory = async () => {
      try {
        setLoadingHistory(true); 
        const employeeTrainingHistory = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/status/transcript/${currentEmployee?.PERSON_ID}`);
        setTrainingHistory(employeeTrainingHistory?.data);
        setLoadingHistory(false);
      } catch(error) {
        // Display toast with error message if fetching training history fails
        toast({
          title: 'Unable to fetch transcript data',
          description: error.response?.data?.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
    if(currentEmployee) {
      fetchEmployeeTrainingHistory();
    }
  }, [currentEmployee, toast]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        {/* Drawer header with employee name, title, and function */}
        <DrawerHeader bgColor='gray.50'>
          <Flex flexDir='column'>
            <EmployeeTags employeeData={currentEmployee} activityData={currentActivity}/>
            <Text color='blue.500'>{currentEmployee?.PRI_DISPLAY_NM}</Text>
            <Text fontWeight={300} fontSize='sm'>{currentEmployee?.JOB_TITLE} | {currentEmployee?.DEPARTMENT_FUNCTION_NM}</Text>
          </Flex>
        </DrawerHeader>
          <DrawerBody>
            {/* Grid to display employee information like manager hierarchy and start date */}
            <Grid templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' }}>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">DIRECT MANAGER</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.SUPERVISOR_PRI_DISPLAY_NM}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">LEVEL 04 LEADER</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.LVL04_PRI_DISPLAY_NM}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">LEVEL 05 LEADER</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.LVL05_PRI_DISPLAY_NM}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">SOLUTION GROUP</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.DEPARTMENT_GROUP_NM}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">FIRST HIRE DATE</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.FIRST_HIRE_DT}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">JOB ENTRY DATE</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.JOB_ENTRY_DT}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">MANAGER LEVEL</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.MANAGER_LEVEL_DESC}</Text>
                </VStack>
              </GridItem>
              <GridItem p={4} borderRadius="md">
                <VStack>
                  <Text textAlign='center' fontSize="sm" fontWeight="bold">CF CAREER LEVEL</Text>
                  <Text textAlign='center' fontSize="sm" >{currentEmployee?.CF_CAREER_LEVEL_CD}</Text>
                </VStack>
              </GridItem>
            </Grid>
            {/* Current nominations and approvals */}
            <Box mb={5} border='1px solid' p={4} borderRadius={8} borderColor='gray.100'>
              <Text fontSize='sm' fontWeight={400} mb={2}>NOMINATIONS & APPROVALS</Text>
              {
                (!loadingStatuses &&
                <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
                <Table variant='striped' size='sm'>
                  <Thead bgColor='white'>
                    <Tr>
                      <Th>Program Name</Th>
                      <Th>Status</Th>
                      <Th>Sub Status</Th>
                      <Th>Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      statuses.map((status) => (
                        <Tr key={status._id}>
                          <Td>{status?.ACTIVITY[0]?.ACTIVITY_NAME}</Td>
                          <Td>
                            <Tag size='sm' colorScheme={utils.captureStatusAndSubStatus(status).statusColor}>
                              {utils.captureStatusAndSubStatus(status).status}
                            </Tag>
                          </Td>
                          <Td>
                            {
                              utils.captureStatusAndSubStatus(status)?.subStatus
                              &&
                              <Tag size='sm' colorScheme={utils.captureStatusAndSubStatus(status).subStatusColor}>
                                {utils.captureStatusAndSubStatus(status).subStatus}
                              </Tag>
                            }
                          </Td>
                          <Td>{utils.formatDate(status.updatedAt)}</Td>
                        </Tr>
                      ))
                    }
                  </Tbody>
                </Table>
                </TableContainer>)
                ||
                <Loading/>
              }
            </Box>
            {/* Traning history from SumTotal */}
            <Box border='1px solid' p={4} borderRadius={8} borderColor='gray.100'>
              <Text fontSize='sm' fontWeight={400} mb={2}>TRAINING HISTORY</Text>
              {
                (!loadingHistory &&
                <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
                  <Table variant='striped' size='sm'>
                    <Thead bgColor='white'>
                      <Tr>
                        <Th>Program Name</Th>
                        <Th>Completion Date</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        trainingHistory?.map((status) => (
                          <Tr key={status.activityID}>
                            <Td>{status.activityName}</Td>
                            <Td>{utils.formatDate(status.completionDate)}</Td>
                          </Tr>
                        ))
                      }
                    </Tbody>
                  </Table>
                </TableContainer>)
                ||
                <Loading/>
              }
            </Box>
          </DrawerBody>
        {/* Drawer Footer with buttons to close and nominate the employee */}
        <DrawerFooter bgColor='gray.50'>
          {
            !statusExists && onNominate &&
            <Button mr={3} colorScheme='blue' onClick={() => onNominate(currentEmployee)}>
              Nominate
            </Button>
          }
          <Button variant='outline' colorScheme='blue' onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default EmployeeDrawer;
