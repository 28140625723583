import React, { useEffect, useState } from 'react';
import { Tr, Td, IconButton, useToast } from '@chakra-ui/react';
import { DeleteIcon, WarningTwoIcon } from '@chakra-ui/icons';
import StatusSelect from 'features/Select/StatusSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentEmployee } from 'stores/employeeSearch/employeeSearchSlice';
import { axios } from 'hooks/useAxiosInterceptor';
import { setRosterData } from 'stores/roster/rosterSlice';
import utils from 'utils/utils';
import ModalWrapper from 'components/common/ModalWrapper';
import WarningMessage from './WarningMessage';

/**
 * EmployeeRow component for rendering a single row of the roster.
 * The EmployeeRow renders a warning message as well as a delete icon to delete the status.
 * If the user has the 'delete-status' permission, they will have access to delete statuses from the roster. 
 * @param {Object} props - Component props.
 * @param {number} props.row - All of the data related an employee
 * @param {boolean} props.onRowDelete - Function to handle deleting the row from the table
 * @returns {JSX.Element} EmployeeRow component.
 */
const EmployeeRow = ({ row, onRowDelete }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const rosterData = useSelector((state) => state.roster.rosterData);
  const currentAllocation = useSelector((state) => state.roster.currentAllocation);
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const activityData = useSelector((state) => state.activity.activityData);
  const user = useSelector((state) => state.auth.user);
  const [warningFlag, setWarningFlag] = useState(false);
  const [warningsModalIsOpen, setWarningsModalIsOpen] = useState(false);
  const [showTargetManagerLevelWarning, setShowTargetManagerLevelWarning] = useState(false);
  const [showTargetDemoWarning, setShowTargetDemoWarning] = useState(false);
  const [showTargetGenderWarning, setShowTargetGenderWarning] = useState(false);
  const [showEquivalencyWarning, setShowEquivalencyWarning] = useState(false);
  const [fulfillments, setFulfillments] = useState([]);
  
  // Fetch and set the fulfillments for the current activity. This is used for warning message generation.
  useEffect(() => {
    setFulfillments(utils.getFulfillmentsList(activityData, currentActivity));
  }, [activityData, currentActivity]);

  // Fetch the target Gender, Manager Level, and Ethnicity for the current activity. This is used for warning message generation.
  useEffect(() => {
    if(currentActivity?.ACTIVITY_TARGET_GENDER?.length && !currentActivity?.ACTIVITY_TARGET_GENDER.includes(row.GENDER_CD)) {
      setWarningFlag(true);
      setShowTargetGenderWarning(true);
    }

    if(currentActivity?.ACTIVITY_TARGET_MANAGER_LEVEL?.length && !currentActivity?.ACTIVITY_TARGET_MANAGER_LEVEL.includes(row.MANAGER_LEVEL_CD)) {
      setWarningFlag(true);
      setShowTargetManagerLevelWarning(true);
    }

    if(currentActivity?.ACTIVITY_TARGET_DEMO?.length && !currentActivity?.ACTIVITY_TARGET_DEMO.includes(row.ETHNICITY)) {
      setWarningFlag(true);
      setShowTargetDemoWarning(true);
    }

    fulfillments?.forEach((fulfillment) => {
      return row?.STATUSES?.forEach((status) => {
        if (status.SUB_STATUS_COMPLETED && fulfillment.ACTIVITY_ID === status.ACTIVITY_ID) {
          setShowEquivalencyWarning(true);
        }
      });
    }); 
  }, [row, fulfillments]);

  const handleOpenWarningsModal = () => {
    setWarningsModalIsOpen(true);
  };
  
  const handleCloseWarningsModal = () => {
    setWarningsModalIsOpen(false);
  };

  const handleDeleteClick = () => {
    onRowDelete(row);
  };

  const handleDrawerOpen = (e) => {
    const _id = e.target.dataset?.value;

    if(_id) {
      const targetRecord = rosterData.find((record) => {
        return record._id === _id;
      });

      dispatch(setCurrentEmployee(targetRecord));
    } 
  };

  // Whenever the dropdown in the row changes, call this function to determine the correct status and send a call to the server. 
  const handleStatusUpdate = async (value) => {
    try {
      let statusObj = {...row.ACTIVE_STATUS};

      statusObj.STATUS_NOMINATED = false;
      statusObj.STATUS_APPROVED = false;
      statusObj.SUB_STATUS_DECLINED = false;
      statusObj.SUB_STATUS_WITHDRAWN= false;
      statusObj.SUB_STATUS_COMPLETED = false;
      statusObj.SUB_STATUS_REMOVED = false;

      if(value !== 'STATUS_NOMINATED' && value !== 'STATUS_APPROVED') {
        statusObj.STATUS_APPROVED = true;
      } 

      if(value === 'STATUS_APPROVED') {
        statusObj.ALLOCATION_ID = currentAllocation._id;
      }
      
      statusObj[value] = true;
      statusObj.UPDATED_BY = user.email;

      let updatedRoster; 

      await axios.put(`${process.env.REACT_APP_INVOKE_URL}/status`, { data: statusObj });
      updatedRoster = rosterData.map((record) => {
        return record.ACTIVE_STATUS?._id === statusObj._id ? { ...record, ACTIVE_STATUS: statusObj } : record
      });
      
      dispatch(setRosterData(updatedRoster));
      
      toast({
          title: `Status updated`,
          description: `Updated status for ${row?.PRI_DISPLAY_NM || 'employee'}.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
      });
    } catch(error) {
      console.log(error);
      toast({
        title: `Unable to update status`,
        description: error?.message || 'An unknown error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Tr></Tr>
      <Tr h={0} visibility='collapse'>
        <Td>
          <ModalWrapper
            isOpen={warningsModalIsOpen}
            header={`Approval warnings for ${row?.PRI_DISPLAY_NM}`}
            body={
              <WarningMessage 
                showTargetDemoWarning={showTargetDemoWarning} 
                showTargetManagerLevelWarning={showTargetManagerLevelWarning} 
                showTargetGenderWarning={showTargetGenderWarning} 
                showEquivalencyWarning={showEquivalencyWarning} 
                employee={row}
                activity={currentActivity}
              />
            }
            onClose={handleCloseWarningsModal}
          />
        </Td>
      </Tr>
      <Tr key={row._id}>
        <Td p={1}>
          {
            warningFlag && 
            <IconButton 
              variant='ghost' 
              size='md' 
              colorScheme='yellow' 
              aria-label='Status warning' 
              icon={<WarningTwoIcon />} 
              onClick={handleOpenWarningsModal}
            />
          }
        </Td>
        {/* If the user has the 'delete-status' permission, render the delete button */}
        {
          utils.hasPermission(user, 'delete-status') 
          ?
          <Td p={1}>
            <IconButton 
              variant='ghost' 
              size='md' 
              colorScheme='red' 
              aria-label='Delete status' 
              icon={<DeleteIcon />} 
              onClick={handleDeleteClick}
            />
          </Td>
          :
          <Td></Td>
        }
      <Td>
        <StatusSelect data={row.ACTIVE_STATUS} onSelectChange={handleStatusUpdate}/>
      </Td>
      <Td>{row?.PERSON_ID}</Td>
      <Td 
        data-value={row._id} 
        cursor='pointer' 
        onClick={handleDrawerOpen} 
        style={{textDecoration: 'underline'}} 
        _hover={{color: 'blue.500'}}
      >
        {row?.PRI_DISPLAY_NM}
      </Td>
      <Td>{row?.JOB_TITLE}</Td>
      <Td>{row?.GENDER_CD}</Td>
      <Td>{row?.ETHNICITY}</Td>
      <Td>{row?.DEPARTMENT_FUNCTION_NM}</Td>
      <Td>{row?.DEPARTMENT_GROUP_NM}</Td>
      <Td>{row?.LVL03_PRI_DISPLAY_NM}</Td>
      <Td>{row?.CF_CAREER_LEVEL_CD}</Td>
      <Td>{row?.MANAGER_LEVEL_CD}</Td>
    </Tr>
    </>
  );
};

export default EmployeeRow;
