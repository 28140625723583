import { useDispatch, useSelector } from 'react-redux';
import ActivityCard from 'features/Activities/ActivityCard';
import MainDrawer from 'features/MainDrawer/MainDrawer';
import { Grid } from '@chakra-ui/react';
import { setCurrentActivity } from 'stores/activity/activitySlice';
import { setAllocations, setSearchTerm } from 'stores/roster/rosterSlice';
import { setTabIndex } from 'stores/tabs/tabSlice';

/**
 * ActivitiesList component renders a grid of activity cards and a main drawer for displaying activity details.
 *
 * @param {Array} activityData - Array of activity data to render as activity cards.
 * @returns {JSX.Element} - ActivitiesList component JSX.
 */
const ActivitiesList = ({ activityData }) => {
  const dispatch = useDispatch();
  const currentActivity = useSelector((state) => state.activity.currentActivity);

  // Dispatch setCurrentActivity action when an activity card is clicked
  const handleCardClick = (activity) => {
    dispatch(setCurrentActivity(activity));
  };

  // Dispatch actions to reset state when the drawer is closed
  const handleCloseDrawer = () => {
    dispatch(setCurrentActivity(null));
    dispatch(setSearchTerm(''));
    dispatch(setTabIndex(0));
    dispatch(setAllocations([]));
  };

  return (
    <Grid templateColumns='repeat(auto-fill, minmax(300px, 1fr))' gap={6} p={6}> {/* Grid layout for activity cards */}
      {/* Render activity cards */}
      {activityData.map((activity) => (
        <ActivityCard key={activity._id} data={activity} onClick={() => handleCardClick(activity)} />
      ))}
      {/* Render main drawer */}
      <MainDrawer isOpen={currentActivity !== null} onClose={handleCloseDrawer} /> {/* Main drawer for displaying activity details */}
    </Grid>
  );
};

export default ActivitiesList;
