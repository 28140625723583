import { 
  Drawer, 
  DrawerOverlay, 
  DrawerContent,
} from '@chakra-ui/react';
import MainDrawerFooter from 'features/MainDrawer/MainDrawerFooter';
import MainDrawerHeader from 'features/MainDrawer/MainDrawerHeader';
import MainDrawerContent from 'features/MainDrawer/MainDrawerContent';

/**
 * Renders a main drawer component.
 * @param {boolean} isOpen - Flag indicating whether the drawer is open.
 * @param {Function} onClose - Function to handle drawer close event.
 * @returns {JSX.Element} - Main drawer component.
 */
const MainDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <MainDrawerHeader/>
        <MainDrawerContent/>
        <MainDrawerFooter onClose={onClose}/>
      </DrawerContent>
    </Drawer>
  );
};

export default MainDrawer;
