import { SearchIcon } from '@chakra-ui/icons';
import { Button, Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const SearchWithEmbedButton = ({ onChange, onSubmit }) => {
  const searchKeyword = useSelector((state) => state.employeeSearch.searchKeyword);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Stack minW='50%' direction="row" alignItems="center">
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.400" />} />
        <Input 
          borderRadius='full' 
          bgColor='white'
          onChange={onChange} 
          onKeyDown={handleKeyDown}
          borderTopRightRadius={0}
          borderBottomRightRadius={0}
          defaultValue={searchKeyword}
        />
        <Button 
          colorScheme='blue' 
          variant='outline'
          borderTopRightRadius='full' 
          borderBottomRightRadius='full'
          onClick={onSubmit}
        >
          Search
        </Button>
      </InputGroup>
    </Stack>
  );
};

export default SearchWithEmbedButton;