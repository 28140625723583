import FilterSection from 'features/Tabs/EmployeeSearch/Filters/FilterSection';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from 'stores/employeeSearch/employeeSearchSlice';
import { Flex } from '@chakra-ui/react';

/**
 * Component for displaying individual filter components.
 * @returns {JSX.Element} - Employee search filters component.
 */
const Filters = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.employeeSearch.filters);

  // // Define filter options
  const filterTypes = [
    { type: 'HRBP1_NAME', title: 'HRBP 1 NAME' },
    { type: 'HRBP2_NAME', title: 'HRBP 2 NAME' },
    { type: 'MANAGER_LEVEL_DESC', title: 'MANAGER LEVEL' },
    { type: 'SUPERVISOR_PRI_DISPLAY_NM', title: 'SUPERVISOR NAME' },
    { type: 'LVL03_PRI_DISPLAY_NM', title: 'LEVEL 03 MANAGER NAME' },
    { type: 'LVL04_PRI_DISPLAY_NM', title: 'LEVEL 04 MANAGER NAME' },
    { type: 'LVL05_PRI_DISPLAY_NM', title: 'LEVEL 05 MANAGER NAME' },
    { type: 'LVL06_PRI_DISPLAY_NM', title: 'LEVEL 06 MANAGER NAME' },
    { type: 'LVL07_PRI_DISPLAY_NM', title: 'LEVEL 07 MANAGER NAME' },
    { type: 'DEPARTMENT_GROUP_NM', title: 'SOLUTION GROUP' },
    { type: 'DEPARTMENT_FUNCTION_NM', title: 'DEPARTMENT FUNCTION' },
    { type: 'GENDER_CD', title: 'GENDER INFORMATION' },
    { type: 'ETHNICITY', title: 'ETHNICITY INFORMATION' },
  ];

  // Define a function to handle filter changes
  const handleChange = (selected, type) => {
    // Don't generate an update if we are clearing an already empty selection
    if(!(!filters[type]?.length && !selected.length)) {
      const selectedValues = selected.map((item) => item.value);  

      // Update filters
      // Go back to page 1 for a new result
      dispatch(setFilters({
        ...filters,
        PAGE_NUMBER: 1,
        [type]: selectedValues
      }));
    }
  };

  return (
    <Flex pt={3} pb={10} flexDir='column'>
      {filterTypes?.map((option, idx) => (
        <FilterSection
          key={idx}
          title={option.title}
          type={option.type}
          value={filters[option.type] || []}
          handleChange={(selected) => handleChange(selected, option.type)}
        />
      ))}
    </Flex>
  );
};

export default Filters;
