import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react';

const Search = ({ onChange, placeholder, defaultValue }) => {
  return (
    <Stack minW='50%' direction="row" alignItems="center">
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.400" />} />
        <Input 
          borderRadius='full' 
          bgColor='white'
          onChange={onChange} 
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      </InputGroup>
    </Stack>
  );
};

export default Search;