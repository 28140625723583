import { Box } from "@chakra-ui/react"

/**
 * ActivityCard component renders an activity card with Chakra UI Box component.
 *
 * @param {Object} data - Data object containing activity details.
 * @param {Function} onClick - Function to handle click event on the activity card.
 * @returns {JSX.Element} - ActivityCard component JSX.
 */
const ActivityCard = ({ data, onClick }) => {
  // Renders an activity card with Chakra UI Box component
  return (
    <Box 
      bgColor='white' 
      cursor='pointer' 
      maxW='sm' 
      borderWidth='1px' 
      borderRadius='lg' 
      borderColor='gray.200' 
      overflow='hidden' 
      onClick={onClick}
      _hover={{ boxShadow: 'md' }} // Applies box shadow on hover
    >
      {/* Container for activity details */}
      <Box p='6'>
        {/* Activity name */}
        <Box
          mt='1'
          fontWeight='semibold'
          as='h4'
          lineHeight='tight'
          noOfLines={3}
        >
          {data.ACTIVITY_NAME}
        </Box>
        {/* Additional information section */}
        <Box display='flex' alignItems='baseline'>
          {/* Placeholder for additional information */}
          <Box
            color='gray.500'
            fontWeight='semibold'
            letterSpacing='wide'
            fontSize='xs'
            noOfLines={2}
          >
          </Box>
        </Box>
        {/* Target audience section */}
        <Box display='flex' alignItems='baseline'>
          {/* Maps through activity target audience */}
          <Box
            color='gray.900'
            fontWeight='semibold'
            letterSpacing='wide'
            fontSize='xs'
            noOfLines={3}
            mt='2'
          >
            {
              data.ACTIVITY_TARGET_AUDIENCE.map((target) => (
                <Box key={target} 
                  color='gray.500'
                  fontWeight='semibold'
                  letterSpacing='wide'
                  fontSize='xs'
                  noOfLines={1}
                  mt='1'
                >
                  {target}
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ActivityCard;
