import { 
  Badge,
  DrawerBody, HStack, Text, 
} from '@chakra-ui/react';
import { 
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel 
} from '@chakra-ui/react'
import AllocationManagerPanel from 'features/Tabs/AllocationManager/AllocationManagerPanel';
import EmployeeSearchPanel from 'features/Tabs/EmployeeSearch/EmployeeSearchPanel';
import ProgramInformationTab from 'features/Tabs/Program/ProgramInformationTab';
import RosterPanel from 'features/Tabs/Roster/RosterPanel';
import { useDispatch, useSelector } from 'react-redux';
import { setTabIndex } from 'stores/tabs/tabSlice';
import utils from 'utils/utils';

/**
 * Renders the main content of the drawer, including tabs for different features.
 * @returns {JSX.Element} - Main drawer content component.
 */
const MainDrawerContent = () => {
  // Redux state selectors
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // Function to handle tab change
  const handleTabChange = (index) => {
    dispatch(setTabIndex(index));
  }

  // Render main drawer content
  return (
    <DrawerBody p={0} backgroundColor='gray.50'>
      {/* Tabs for different features */}
      <Tabs 
        display='flex' 
        flexDir='column' 
        height='100%' 
        variant='enclosed' 
        colorScheme='blue'
        isLazy 
        isManual
        onChange={(index) => handleTabChange(index)} 
      >
        {/* Tab list */}
        <TabList position="sticky" pt={1} zIndex="sticky" bgColor='white'>
          <Tab borderColor='gray.100' borderBottomColor='gray.200' bgColor='gray.50'>Program Information</Tab>
          {/* Employee Search tab */}
          <Tab 
            isDisabled={!utils.hasPermission(user, 'view-employee-search')} 
            borderColor='gray.100' 
            borderBottomColor='gray.200' 
            bgColor='gray.50'
          >
            Employee Search
          </Tab>
          {/* Roster tab */}
          <Tab 
            isDisabled={!utils.hasPermission(user, 'view-roster')} 
            borderColor='gray.100' 
            borderBottomColor='gray.200' 
            bgColor='gray.50'
          >
            Roster
          </Tab>
          {/* Allocation Manager tab with badge for spare seats */}
          <Tab 
            isDisabled={!utils.hasPermission(user, 'view-allocation-manager')} 
            borderColor='gray.100' 
            borderBottomColor='gray.200' 
            bgColor='gray.50'
          >
            <HStack>
              <Text>Allocation Manager</Text>
              <Badge borderRadius="full" px={2} colorScheme="blue">
                {currentActivity?.SPARE_SEATS}
              </Badge>
            </HStack>
          </Tab>
        </TabList>
        {/* Tab panels */}
        <TabPanels overflowY='hidden' h='100%'>
          {/* Program Information tab */}
          <TabPanel overflowY='auto' h='100%'>
            <ProgramInformationTab/>
          </TabPanel>
          {/* Employee Search panel */}
          <EmployeeSearchPanel/>
          {/* Roster panel */}
          <RosterPanel/>
          {/* Allocation Manager panel */}
          <TabPanel overflowY='auto' h="100%" p={4}>
            <AllocationManagerPanel/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </DrawerBody>
  );
};

export default MainDrawerContent;
