import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Loading from "components/common/Loading";
import { useAxiosInterceptor } from "hooks/useAxiosInterceptor";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerState, setFormComponent, setFormData, setFormFlag, setNominationCycleData } from "stores/admin/adminSlice";
import utils from "utils/utils";
import AdminTableCheckbox from "./AdminTableCheckbox";

/**
 * AdminNominationCycleList component renders a table of nomination cycles.
 * It utilizes Chakra UI components for styling.
 * The table includes columns for ID, Nomination Cycle Number, Nomination Cycle Start Date, and Nomination Cycle Label.
 * It also provides a checkbox for each row and allows opening a drawer for editing a nomination cycle.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.searchTerm - Search term for filtering nomination cycles by label.
 * @param {boolean} props.hideInactive - Flag to hide inactive nomination cycles.
 * @returns {JSX.Element} - AdminNominationCycleList component JSX.
 */
const AdminNominationCycleList = ({ searchTerm, hideInactive }) => {
  // State variables for managing loading state, filtered data, and axios instance
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { axios } = useAxiosInterceptor();

  // Redux hooks for accessing nomination cycle data, submission trigger, and dispatching actions
  const data = useSelector((state) => state.admin.nominationCycleData);
  const submissionTrigger = useSelector((state) => state.admin.submissionTrigger);
  const dispatch = useDispatch();

  // useEffect to fetch nomination cycle data and set form component
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await axios.get(`${process.env.REACT_APP_INVOKE_URL}/nominationCycle/nominationCycles`);
        setLoading(false);
        setFilteredData(data?.data);
        dispatch(setNominationCycleData(data?.data));
        dispatch(setFormComponent("NominationCycleForm"));
      } catch (error) {
        console.log(error);
        return <span>Error</span>;
      }
    };

    fetchData();
  }, [axios, submissionTrigger, dispatch]);

  // useEffect to filter data based on search term
  useEffect(() => {
    const filtered = data?.filter(record => {
      return (
        (record?.CYCLE_LABEL?.toLowerCase().includes(searchTerm))
      );
    });

    setFilteredData(filtered);
  }, [searchTerm, hideInactive, data]);

  // Function to handle opening drawer for editing a nomination cycle
  const handleDrawerOpen = (e) => {
    dispatch(setDrawerState(true));
    dispatch(setFormFlag('update'));
    const _id = e.target.dataset?.value;

    if (_id) {
      const targetRecord = data.find((record) => {
        return record._id === _id;
      });
      dispatch(setFormData(targetRecord));
    }
  };

  // Render loading spinner if data is loading
  if (loading) {
    return <Loading />;
  }

  return (
    <TableContainer border='1px' borderStyle='solid' borderColor='gray.100' borderRadius={8} overflowY='auto'>
      <Table variant='striped' size='sm'>
        <Thead bgColor='white'>
          <Tr>
            <Th></Th>
            <Th>ID</Th>
            <Th>Nomination Cycle Number</Th>
            <Th>Nomination Cycle Start Date</Th>
            <Th>Nomination Cycle Label</Th>            
          </Tr>
        </Thead>
        <Tbody>
          {/* Mapping through filtered data to render table rows */}
          {
            filteredData.map((row) => (
              <Tr key={row._id}>
                <Td>
                  {/* Checkbox component for selecting the row */}
                  <AdminTableCheckbox value={row} />
                </Td>
                {/* Cell for displaying ID */}
                <Td data-value={row._id} cursor='pointer' onClick={handleDrawerOpen} style={{textDecoration: 'underline'}} _hover={{color: 'blue.500'}}>{row._id}</Td>
                {/* Cell for displaying Nomination Cycle Number */}
                <Td>{row.CYCLE_NUMBER}</Td>
                {/* Cell for displaying Nomination Cycle Start Date */}
                <Td>{utils.formatDate(row.CYCLE_START_DATE)}</Td>
                {/* Cell for displaying Nomination Cycle Label */}
                <Td>{row.CYCLE_LABEL}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AdminNominationCycleList;
