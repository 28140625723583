import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerOpen: false,
  formComponent: null,
  formData: null,
  activityData: null,
  nominationCycleData: [],
  allocationGroupData: [],
  roleData: [],
  userData: [],
  submissionTrigger: null,
  selectedAssets: [],
  formFlag: null,
  modalIsOpen: false
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setDrawerState(state, action) {
      state.drawerOpen = action.payload;
    },
    setActivityData(state, action) {
      state.activityData = action.payload;
    },
    setNominationCycleData(state, action) {
      state.nominationCycleData = action.payload;
    },
    setAllocationGroupData(state, action) {
      state.allocationGroupData = action.payload;
    },
    setRoleData(state, action) {
      state.roleData = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setFormComponent(state, action) {
      state.formComponent = action.payload;
    },
    setFormData(state, action) {
      state.formData = action.payload;
    },
    setSubmissionTrigger(state, action) {
      state.submissionTrigger = action.payload;
    },
    setSelectedAssets(state, action) {
      state.selectedAssets = action.payload
    },
    addSelectedAsset(state, action) {
      state.selectedAssets = [...state.selectedAssets, action.payload];
    },
    removeSelectedAsset(state, action) {
      state.selectedAssets = state.selectedAssets.filter((record) => {
        return record !== action.payload;
      });
    },
    setFormFlag(state, action) {
      state.formFlag = action.payload
    },
    setModalIsOpen(state, action) {
      state.modalIsOpen = action.payload
    }
  },
});

export const { 
  setDrawerState, 
  setActivityData, 
  setNominationCycleData, 
  setAllocationGroupData, 
  setRoleData, 
  setUserData, 
  setFormComponent, 
  setFormData, 
  setSubmissionTrigger,
  setSelectedAssets,
  addSelectedAsset,
  removeSelectedAsset,
  setFormFlag,
  setModalIsOpen
} = adminSlice.actions;

export default adminSlice.reducer;
