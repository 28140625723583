import { 
  FormControl,
  FormLabel,
  Switch
} from '@chakra-ui/react';

const ActiveSwitch = ({ loading, onChange }) => {
  return (
    <FormControl display='flex' alignItems='center' justifyContent='flex-end'>
      <FormLabel htmlFor='hide-inactive' mb='0'>
        Hide Inactive
      </FormLabel>
      <Switch disabled={loading} id='hide-inactive' defaultChecked onChange={onChange}/>
    </FormControl>
  );
};

export default ActiveSwitch;
