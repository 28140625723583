import { 
  DrawerHeader, Tag, Text, VStack
} from '@chakra-ui/react';
import AllocationInformation from 'features/Tabs/Roster/AllocationInformation';
import { useSelector } from 'react-redux';

const MainDrawerHeader = () => {
  const currentActivity = useSelector((state) => state.activity.currentActivity);
  const tabIndex = useSelector((state) => state.tab.tabIndex);

  return (
    <DrawerHeader pb={0} display='flex' bgColor='white' justifyContent='space-between'>
      <VStack align='flex-start'>
        <Text noOfLines={4}>{currentActivity?.ACTIVITY_NAME}</Text>
        {currentActivity?.ACTIVITY_TARGET_AUDIENCE.slice(0, 2).map((item) => (
          <Tag 
            noOfLines={1} 
            key={item} 
            size='sm' 
            variant='solid' 
            colorScheme='blue' 
            pt={1}
          >
            {item}
          </Tag>
        ))}
      </VStack>
      {/* Only show the AllocationInformation if the user is on the Roster tab */}
      { tabIndex === 2 && <AllocationInformation/> }
    </DrawerHeader>
  );
};

export default MainDrawerHeader;
