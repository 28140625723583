import { 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuGroup,
  MenuItem, 
  IconButton,
  Text,
  MenuDivider
} from '@chakra-ui/react';
import useOktaLogout from 'hooks/useOktaLogout';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import utils from 'utils/utils';

const ProfileMenu = () => {
  const user = useSelector((state) => state.auth.user);
  const logoutUser = useOktaLogout();

  return (
    <Menu>
      <MenuButton isRound as={IconButton} colorScheme='blue' icon={<Text>{utils.getInitials()}</Text>}/>
      <MenuList>
        <MenuGroup title={user.email}>
          {
            utils.hasPermission(user, 'view-admin-portal')
            &&
            <MenuItem>
              <Link style={{width: '100%', height: '100%'}} to='/admin'>Admin Dashboard</Link>
            </MenuItem>
          }
          {
            utils.hasPermission(user, 'view-programs')
            &&
            <MenuItem>
              <Link style={{width: '100%', height: '100%'}} to='/'>Program List</Link>
            </MenuItem>
          }
          {
            utils.hasPermission(user, 'view-reporting-dashboard')
            &&
            <MenuItem>
              <Link style={{width: '100%', height: '100%'}} to='/reporting'>Reporting Dashboard</Link>
            </MenuItem>
          }
          {
            utils.hasPermission(user, 'bulk-upload')
            &&
            <MenuItem>
              <Link style={{width: '100%', height: '100%'}} to='/upload'>Bulk Upload</Link>
            </MenuItem>
          }
        </MenuGroup>
        <MenuDivider/>
        <MenuItem onClick={logoutUser}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
