import { CircularProgress, Stack } from "@chakra-ui/react";

const Loading = () => {
  return (
    <Stack w='100%' display='flex' justify='center' align='center'>
      <CircularProgress isIndeterminate color='blue.300'/>
    </Stack>
  );
};

export default Loading;