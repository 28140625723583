import { Box, Button, Stack, TabPanel, useBreakpointValue, useToast } from '@chakra-ui/react';
import Search from 'components/common/Search';
import { useEffect, useState } from 'react';
import ActiveSwitch from 'features/Admin/ActiveSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerState, setFormFlag, setSelectedAssets, setSubmissionTrigger } from 'stores/admin/adminSlice';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { useAxiosInterceptor } from 'hooks/useAxiosInterceptor';

/**
 * AdminPanel component represents a panel for each admin functionality tab.
 * It includes functionalities for searching, filtering inactive items, adding new items, and deleting selected items.
 *
 * @param {number} tabIndex - Index of the current tab.
 * @param {function} component - Component to render inside the panel.
 * @returns {JSX.Element} - AdminPanel component JSX.
 */
const AdminPanel = ({ tabIndex, component }) => {
  // State variables for search term, inactive item visibility, delete button status, selected assets, and toast
  const [searchTerm, setSearchTerm] = useState('');
  const [hideInactive, setHideInactive] = useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const selectedAssets = useSelector((state) => state.admin.selectedAssets);
  const dispatch = useDispatch();
  const toast = useToast();
  const { axios } = useAxiosInterceptor();
  const stackDirection = useBreakpointValue({ base: "column", md: "row" });

  // Reset search term and inactive item visibility when tab changes
  useEffect(() => {
    dispatch(setSelectedAssets([]));
    setDeleteButtonDisabled(true);
    setSearchTerm('');
    setHideInactive(true);
  }, [tabIndex, dispatch]);

  // Enable delete button if assets are selected
  useEffect(() => {
    if (selectedAssets.length) {
      setDeleteButtonDisabled(false);
    } else {
      setDeleteButtonDisabled(true);
    }
  }, [selectedAssets]);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Handle toggle switch for inactive item visibility
  const handleSwitchChange = (e) => {
    const checked = e.target.checked;
    setHideInactive(checked);
  };

  // Handle click on "Add New" button
  const handleNewClick = () => {
    dispatch(setDrawerState(true));
    dispatch(setFormFlag('new'));
  };

  // Handle click on "Delete" button
  const handleDeleteClick = async () => {
    // Array of promises for deletion requests
    const deletionPromises = selectedAssets.map(async (asset) => {
      try {
        const parseAsset = JSON.parse(asset);
        let deletionPromise;

        // Determine deletion endpoint based on the tab index
        switch (tabIndex) {
          case 0: // Activities
            deletionPromise = axios.delete(`${process.env.REACT_APP_INVOKE_URL}/activity`, { data: { _id: parseAsset._id } });
            break;
          case 1: // Allocation Groups
            deletionPromise = axios.delete(`${process.env.REACT_APP_INVOKE_URL}/allocationGroup`, { data: { _id: parseAsset._id } });
            break;
          case 2: // Nomination Cycles
            deletionPromise = axios.delete(`${process.env.REACT_APP_INVOKE_URL}/nominationCycle`, { data: { _id: parseAsset._id, CYCLE_NUMBER: parseAsset.CYCLE_NUMBER } });
            break;
          case 3: // Roles
            deletionPromise = axios.delete(`${process.env.REACT_APP_INVOKE_URL}/role`, { data: { _id: parseAsset._id } });
            break;
          case 4: // Users
            deletionPromise = axios.delete(`${process.env.REACT_APP_INVOKE_URL}/user`, { data: { _id: parseAsset._id } });
            break;
          default:
            return Promise.resolve();
        }

        // Wait for the deletion request to complete
        await deletionPromise;

        // Display toast for successful deletion
        toast({
          title: `Asset ${parseAsset._id} deleted`,
          description: 'Your deletion request was processed successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        return deletionPromise;
      } catch (error) {
        const parseAsset = JSON.parse(asset);
        // Display toast for unsuccessful deletion with error message
        toast({
          title: `Asset ${parseAsset._id} Not Deleted`,
          description: error.response?.data?.error || 'An unknown error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // Return a resolved promise to ensure Promise.all continues to work correctly
        return Promise.resolve();
      }
    });

    // Wait for all deletion promises to complete
    await Promise.all(deletionPromises);

    // Dispatch actions after all deletions are attempted
    dispatch(setSelectedAssets([]));
    dispatch(setSubmissionTrigger([]));
  };

  // Render the DataTable component with search term and inactive item visibility
  const DataTable = component;

  return (
    <TabPanel>
      {/* Stack for buttons and filters */}
      <Stack direction={stackDirection} p={1}>
        {/* Button to add new item */}
        <Box>
          <Button colorScheme='blue' size='sm' borderRadius='full' onClick={handleNewClick}>Add New +</Button>
        </Box>
        {/* Button to delete selected items */}
        <Box>
          <Button
            colorScheme='blue'
            variant='outline'
            isDisabled={deleteButtonDisabled}
            size='sm'
            borderRadius='full'
            onClick={handleDeleteClick}
            rightIcon={<SmallCloseIcon />}
          >
            Delete
          </Button>
        </Box>
        {/* Search input component */}
        <Search onChange={handleSearch} />
        {/* Switch to toggle inactive item visibility */}
        <ActiveSwitch onChange={handleSwitchChange} />
      </Stack>
      {/* Render the DataTable component with search term and inactive item visibility */}
      <DataTable searchTerm={searchTerm} hideInactive={hideInactive} />
    </TabPanel>
  );
};

export default AdminPanel;
