import { Flex, IconButton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

/**
 * Pagination component for navigating through pages.
 * @param {Object} props - Component props.
 * @param {number} props.currentPage - Current page number.
 * @param {boolean} props.disabledForward - Flag indicating if forward pagination is disabled.
 * @param {Function} props.onPageChange - Function to handle page change.
 * @returns {JSX.Element} Pagination component.
 */
const Pagination = ({ currentPage, disabledForward, onPageChange }) => {
  return (
    <Flex h='100%' alignItems="center">
      {/* Previous page button */}
      <IconButton
        icon={<ChevronLeftIcon />}
        aria-label="Previous Page"
        colorScheme='blue'
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        mr={2}
        size='sm'
        variant='ghost'
      />
      {/* Current page indicator */}
      <Text mr={2}>{currentPage}</Text>
      {/* Next page button */}
      <IconButton
        icon={<ChevronRightIcon />}
        aria-label="Next Page"
        colorScheme='blue'
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={disabledForward}
        size='sm'
        variant='ghost'
      />
    </Flex>
  );
};

export default Pagination;
