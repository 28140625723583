import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    allocationGroupOwner: null,
    email: null,
    permissions: [],
    roleId: null
  }
};

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {     
      state.user.allocationGroupOwner = action.payload.ALLOCATION_GROUP_OWNER
      state.user.email = action.payload.EMAIL;
      state.user.permissions = action.payload.PERMISSIONS;
      state.user.roleId = action.payload.ROLE_ID;
    }
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
